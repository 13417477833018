import { Button } from "../../Components/Button/Button"
import EmailSubscribeBlockContainer from "../../Components/EmailSubscribeBlock/EmailSubscribeBlockContainer"

import SendResumeBarContainer from "../../Components/SendResumeBar/SendResumeBarContainer"

import s from './VacancyInfo.module.css'
import React, {useEffect, useState} from "react";
import VacancyInfoBanner from "../../Components/VacancyInfoBanner/VacancyInfoBanner";
import VacancyInfoItems from "../../Components/VacancyInfoItems/VacancyInfoItems";
import SendApplicationBarContainer from "../../Components/SendApplicationBar/SendApplicationBarContainer";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";

export const VacancyInfo = ({isMobile, vacancyInfo, userSubscribe, onAddToFeatured, vacancyId, shareLink, authorized}) =>{

    const [showSendApplicationModal, toggleShowSendApplicationModal] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
    <div className={s.wrapper}>
        <VacancyInfoBanner
            shareLink = {shareLink}
            onAddToFeatured = {onAddToFeatured} vacancyId = {vacancyId}
            vacancyName={vacancyInfo.name_vacancy}
            startSalary = {vacancyInfo.salary_start}
            endSalary = {vacancyInfo.salary_end}
            featured = {vacancyInfo.user_featured}
            authorized = {authorized}
        />
        <div className={s.breadCrumbsBar}><BreadCrumbs steps = {[["Главная","/"],["Вакансии", "/vakansii"], [`${vacancyInfo?.name_vacancy}`]]}/></div>
        <VacancyInfoItems description={vacancyInfo.description}
                            requariments={vacancyInfo.requirements}
                            duties={vacancyInfo.responsibility}
                            conditions={vacancyInfo.condition}/>
        <div className={s.btnWrapp}>
            <Button variant={1}
                    backColor={"#00B856"}
                    textColor={"white"}
                    btnText={"Откликнуться на вакансию"}
                    padding={"30px"}
                    fontSize={"38px"}
                    onClick = {() => toggleShowSendApplicationModal(true)}
            />
        </div>

        <div className={s.sendResumeBar}><SendResumeBarContainer/></div>
        {!userSubscribe && authorized ? <EmailSubscribeBlockContainer isMobile={isMobile}/> : null}
        {showSendApplicationModal ? <SendApplicationBarContainer vacancyId = {vacancyId} onClose = {() => toggleShowSendApplicationModal(false)} isMobile={isMobile}/>: null}
    </div>)
}
