import * as React from "react";
import {connect} from "react-redux";
import {Menu} from "./Menu";
import {exitTH, signOutAC} from "../../Redux/Reducers/AppReducer";


const mapStateToProps = (state) => {
    return {
        authorized: state.app.authorized,
        userPhoto: state.app.userPhoto
    }
}

class MenuContainer extends React.Component {
    onSignOut = () => {
        return this.props.exitTH()
    }
    render() {
        return <Menu userPhoto = {this.props.userPhoto} onSignOut={this.onSignOut} authorized = {this.props.authorized}/>
    }
}

export default connect(mapStateToProps, {exitTH})(MenuContainer)