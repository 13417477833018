import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import s from "./WorkWithUsSlider.module.css"

import React, {Component} from "react";
import searchPeople from "../../../../Assets/pageImages/HomePageImages/serchPeople.png";
import resume from "../../../../Assets/pageImages/HomePageImages/resume.png";
import trainee from "../../../../Assets/pageImages/HomePageImages/trainee.png";
import next from "../../../../Assets/Icons/showNextSlide.svg";
import previous from "../../../../Assets/Icons/showPreviousSlide.svg";
import {connect} from "react-redux";
import {Button} from "../../../Button/Button";

function SampleNextArrow(props) {
    const {className, style, onClick, subOnClick} = props;
    return (
        <div style={{...style}} onClick={() => {
            onClick()
            subOnClick()
        }} className={`${className} ${s.nextArrow}`}>
            <img src={next} alt=""/>
        </div>
    );
}

function SamplePrevArrow(props) {
    const {className, style, onClick, subOnClick} = props;
    return (
        <div style={{...style}} onClick={() => {
            onClick()
            subOnClick()
        }} className={`${className} ${s.prevArrow}`}>
            <img src={previous} alt=""/>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {}
}

class WorkWithUsSlider extends Component {

    componentDidMount() {
        let windowSize = window.innerWidth

        if (windowSize <= 900) {
            this.setState({isMobile: true})
        }
        if (window.innerWidth < 1300) {
            this.setState({viewSlidesCount: 2})
        }
        if (window.innerWidth > 1300) {
            this.setState({viewSlidesCount: 3})
        }
        if (window.innerWidth <= 900) {
            this.setState({viewSlidesCount: 1})
        }
        if (window.innerWidth > 900 && window.innerWidth < 1300) {
            this.setState({viewSlidesCount: 2})
        }

        window.addEventListener("resize", this.resizeMethod)
    }

    resizeMethod = () => {
        this.setState({screenWidth: window.innerWidth - 17})
        if (window.innerWidth <= 900) {
            this.setState({isMobile: true})
        } else {
            this.setState({isMobile: false})
        }
        if (window.innerWidth < 1300) {
            this.setState({viewSlidesCount: 2})
        }
        if (window.innerWidth > 1300) {
            this.setState({viewSlidesCount: 3})
        }
        if (window.innerWidth <= 900) {
            this.setState({viewSlidesCount: 1})
        }
        if (window.innerWidth > 900 && window.innerWidth < 1300) {
            this.setState({viewSlidesCount: 2})
        }
    }


    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeMethod)
    }

    constructor(props) {
        super(props);
        this.state = {
            activeItem: window.innerWidth <= 900 ? 0 : 1,
            screenWidth: window.innerWidth - 17,
            viewSlidesCount: 3,
            isMobile: false
        }
    }

    onChangeSlide = (index) => {
        if (index === 3) {
            this.setState({activeItem: 0})
            this.props.onSetAgitModalNum(0)
        } else if (index === 4) {
            this.setState({activeItem: 1})
            this.props.onSetAgitModalNum(1)
        } else if (index === 5) {
            this.setState({activeItem: 2})
            this.props.onSetAgitModalNum(2)
        } else {
            this.setState({activeItem: index})
            this.props.onSetAgitModalNum(index)
        }

    }
    onGoPreviousSlide = () => {
        if (this.state.activeItem === 2) {
            
            this.props.onSetAgitModalNum(1)
        } else if (this.state.activeItem === 0) {
            
            this.props.onSetAgitModalNum(2)
        } else {
            this.props.onSetAgitModalNum(0)
        }
    }

    onGoNextSlide = () => {
        if (this.state.activeItem === 2) {
            this.props.onSetAgitModalNum(0)
        } else if (this.state.activeItem === 0) {
            this.props.onSetAgitModalNum(1)
        } else {
            this.props.onSetAgitModalNum(2)
        }
    }


    render() {
        const settings = {
            arrows: !this.state.isMobile,
            lazyLoad: true,
            infinite: true,
            speed: 500,
            slidesToShow: this.state.viewSlidesCount,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow subOnClick={this.onGoNextSlide}/>,
            prevArrow: <SamplePrevArrow subOnClick={this.onGoPreviousSlide}/>,
            afterChange: this.state.isMobile ? this.onChangeSlide : (index)=>{
                this.setState({activeItem: (index % 3) < 2 ? (index % 3) + 1 : 0})},
            initialSlide: 0,
            swipe: false,
            responsive: [
                {
                    breakpoint: 900,
                    settings: {
                        swipe: true,
                        swipeToSlide: true
                    }
                }
            ]

        };
        return (
            <div style={{maxWidth: `${this.state.screenWidth}px`, margin: "0 auto"}}>
                {this.state.isMobile ? <div style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    marginBottom: "40px"
                }}>
                    <div className={s.backPagination}
                         style={{display: "flex", backgroundColor: "rgba(0, 184, 86, 0.5)", padding: "6px"}}>
                        <div className={this.state.activeItem === 0 ? s.activePage : s.pageElement}/>
                        <div className={this.state.activeItem === 1 ? s.activePage : s.pageElement}/>
                        <div style={{margin: "0"}}
                             className={this.state.activeItem === 2 ? s.activePage : s.pageElement}/>
                    </div>
                </div> : null}
                <Slider {...settings}>
                    <div>
                        <div onClick={(e) => {
                            // this.props.onSetAgitModalNum(0)
                            e.stopPropagation()
                        }} style={this.state.isMobile ? {margin: "0 auto"} : null}
                             className={`${s.agitCard} ${this.state.activeItem === 0 || this.state.isMobile ? s.activeCard : s.agitCard}`}>
                            <p style={{color: "white", fontFamily: "var(--Bold)"}}>Отслеживай и откликайся на наши
                                вакансии!</p>
                            <img className={s.cardImg} src={searchPeople} alt=""/>
                            {(this.state.activeItem === 0 || this.state.isMobile) ? <div className={s.moreBtn}><Button onClick={() => this.props.setAgitIsOpen(!this.props.agitIsOpen)} variant={1} btnText={!this.props.agitIsOpen ? "Подробнее" : "Свернуть" } margin="0 auto"/></div>: null}
                        </div>
                    </div>
                    <div>
                        <div onClick={(e) => {
                            // this.props.onSetAgitModalNum(1)
                            e.stopPropagation()
                        }}
                            className={`${s.agitCard} ${this.state.activeItem === 1 || this.state.isMobile ? s.activeCard : s.agitCard}`}>
                            <p style={{color: "white", fontFamily: "var(--Bold)"}}>Вы специалист? Пришлите нам свое
                                резюме!</p>
                            <img className={s.cardImg} src={resume} alt=""/>
                            {(this.state.activeItem === 1 || this.state.isMobile) ? <div className={s.moreBtn}><Button onClick={() => this.props.setAgitIsOpen(!this.props.agitIsOpen)} variant={1} btnText={!this.props.agitIsOpen ? "Подробнее" : "Свернуть" } margin="0 auto"/></div>: null}
                        </div>
                    </div>
                    <div>
                        <div onClick={(e) => {
                            // this.props.onSetAgitModalNum(2)
                            e.stopPropagation()
                        }}
                            className={`${s.agitCard} ${this.state.activeItem === 2 || this.state.isMobile ? s.activeCard : s.agitCard}`}>
                            <p style={{color: "white", width: "90%", fontFamily: "var(--Bold)"}}>Пройди стажировку в
                                МегаФон!</p>
                            <img className={s.cardImg} src={trainee} alt=""/>
                            {(this.state.activeItem === 2 || this.state.isMobile) ? <div className={s.moreBtn}><Button onClick={() => this.props.setAgitIsOpen(!this.props.agitIsOpen)} variant={1} btnText={!this.props.agitIsOpen ? "Подробнее" : "Свернуть" } margin="0 auto"/></div>: null}
                        </div>
                    </div>
                    <div>
                        <div onClick={(e) => {
                            // this.props.onSetAgitModalNum(0)
                            e.stopPropagation()
                        }}
                            className={`${s.agitCard} ${this.state.activeItem === 0 || this.state.isMobile ? s.activeCard : s.agitCard}`}>
                            <p style={{color: "white", fontFamily: "var(--Bold)"}}>Отслеживай и откликайся на наши
                                вакансии!</p>
                            <img className={s.cardImg} src={searchPeople} alt=""/>
                            {(this.state.activeItem === 0 || this.state.isMobile) ? <div className={s.moreBtn}><Button onClick={() => this.props.setAgitIsOpen(!this.props.agitIsOpen)} variant={1} btnText={!this.props.agitIsOpen ? "Подробнее" : "Свернуть" } margin="0 auto"/></div>: null}
                        </div>
                    </div>
                    <div>
                        <div onClick={(e) => {
                            // this.props.onSetAgitModalNum(1)
                            e.stopPropagation()
                        }}
                            className={`${s.agitCard} ${this.state.activeItem === 1 || this.state.isMobile ? s.activeCard : s.agitCard}`}>
                            <p style={{color: "white", fontFamily: "var(--Bold)"}}>Вы специалист? Пришлите нам свое
                                резюме!</p>
                            <img className={s.cardImg} src={resume} alt=""/>
                            {(this.state.activeItem === 1 || this.state.isMobile) ? <div className={s.moreBtn}><Button onClick={() => this.props.setAgitIsOpen(!this.props.agitIsOpen)} variant={1} btnText={!this.props.agitIsOpen ? "Подробнее" : "Свернуть" } margin="0 auto"/></div>: null}
                        </div>
                    </div>
                    <div>
                        <div onClick={(e) => {
                            // this.props.onSetAgitModalNum(2)
                            e.stopPropagation()
                        }}
                            className={`${s.agitCard} ${this.state.activeItem === 2 || this.state.isMobile ? s.activeCard : s.agitCard}`}>
                            <p style={{color: "white", width: "90%", fontFamily: "var(--Bold)"}}>Пройди стажировку в
                                МегаФон!</p>
                            <img className={s.cardImg} src={trainee} alt=""/>
                            {(this.state.activeItem === 2 || this.state.isMobile) ? <div className={s.moreBtn}><Button onClick={() => this.props.setAgitIsOpen(!this.props.agitIsOpen)}  variant={1} btnText= {!this.props.agitIsOpen ? "Подробнее" : "Свернуть"} margin="0 auto"/></div>: null}

                        </div>
                    </div>
                </Slider>
            </div>
        );
    }
}

export default connect(mapStateToProps, {})(WorkWithUsSlider)