
import s from './ProbationStep.module.css'

const ProbationStep = ({title,content,isGreen = false,className = "", isLeft = false, stage}) =>{
    const color = isGreen ? "#00B856" : "#731982"
    return (
        <div className={`${className} ${s.stepCard} ${isLeft ? s.l : s.r}`}>
            <p className={s.title}
               style={{color:color}}>
                   {title}
            </p>
            <p className={s.content}>
                {content}
            </p>
            <div style = {{border: `4px solid ${color}`}} className={s.stageValueBar}><p style={{color: color}}>{stage}</p></div>
        </div>
    )
}

export default ProbationStep