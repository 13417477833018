import React, {useEffect, useState} from "react"
import s from "./ChoiceBar.module.css"
import {ChoiceItem} from "./ChoiceItem/ChoiceItem";


export const ChoiceBar = ({isManyChoice, items, onChange, selectedItemsIds, isLinks, isProbationDirections, activeItem, startName}) => { //TODO дописать в доки 2 последних пропса
    const [activeItemId, setActiveItemId] = useState(activeItem || undefined)
    const [selectedItems, setSelectedItems] = useState(selectedItemsIds || [])


    useEffect(() => {
        if (selectedItemsIds) {
            setSelectedItems(selectedItemsIds)
        }
    }, [selectedItemsIds])

    useEffect(() => {
        if (activeItem >= 0) {
            setActiveItemId(activeItem)
        }
    })

    const onChangeItems = (id) => {
        if (!isManyChoice) {
            setActiveItemId(id)
        } else {
            let newSelItems = [...selectedItems]
            if (newSelItems.find((i) => i === id) || newSelItems.find((i) => i === id) === 0) {
                setSelectedItems(newSelItems.filter((d) => d !== id))
            } else {
                newSelItems = [...newSelItems, id]
                setSelectedItems(newSelItems)
            }
        }
        onChange(id)
    }


    const itemsList = items.map((i) => <ChoiceItem isLink={isLinks} id={i.id} key={i.id} name={i.name || startName}
                                                   isActive={!isManyChoice ? i.id === activeItemId : i.id === selectedItems.find((d) => d === i.id
                                                   )}
                                                   setActiveItem={onChangeItems}/>)

    return (
        <div className={s.wrapper}>
            {itemsList}
        </div>
    )
}
