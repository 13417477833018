import React, {useEffect, useState} from "react";
import s from "./RegBar.module.css";
import {Input} from "../Input/Input";
import {Button} from "../Button/Button";
import logo from "../../Assets/Logo/logo.png"
import {CheckBox} from "../Checkbox/CheckBox";
import DatePicker from "react-datepicker";
import ConfirmationEmailBarContainer from "../СonfirmationEmailBar/СonfirmationEmailBarContainer";
import {useNavigate} from "react-router";
import AuthBarContainer from "../AuthBar/AuthBarContainer";
import RecoveryPasswordContainer from "../RecoveryPassword/RecoveryPasswordContainer";
import CustomDatePicker from "../DatePicker/DatePicker";
import {ToastsStore} from "react-toasts";


export const RegBar = ({goReg, regError, confirmEmailInProgress, toggleShowConfirmEmailBar, isMobile, goAuth}) => {
    const [password, setPassword] = useState("")
    const [secondPassword, setSecondPassword] = useState("")
    const [startDate, setStartDate] = useState(new Date());
    const [name, setName] = useState("")
    const [firstName, setFirstName] = useState("")
    const [patronymic, setPatronymic] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [genderId, setGenderId] = useState(undefined)
    const [passHidden, togglePassHidden] = useState(true)

    const [passShow, togglePassShow] = useState(false)

    const [firstNameIsValid, setFirstNameIsValid] = useState(undefined)
    const [nameIsValid, setNameIsValid] = useState(undefined)
    const [patronymicIsValid, setPatronymicIsValid] = useState(undefined)
    const [emailIsValid, setEmailIsValid] = useState(undefined)
    const [phoneIsValid, setPhoneIsValid] = useState(undefined)
    const [passIsValid, setPassIsValid] = useState(undefined)
    const [secPassIsValid, setSecPassIsValid] = useState(undefined)

    const [subcription, setSubcription] = useState(false)
    const [consent, setConsent] = useState(false)

    const [dateIsSelected, setDateIsSelected] = useState(false)

    const [showRequired, setShowRequired] = useState(false)

    const [authModalIsOpen, setAuthModalIsOpen] = useState(false)
    const [recoveryPassModalIsOpen, toggleRecoveryPassModalIsOpen] = useState(false)


    useEffect(() => {
        const nowDateArr = `${new Date()}`.split(" ")
        const interDateArr = `${startDate}`.split(" ")
        const nowDateStr = `${nowDateArr[1]} ${nowDateArr[2]} ${nowDateArr[3]}`
        const interDateStr = `${interDateArr[1]} ${interDateArr[2]} ${interDateArr[3]}`
        setDateIsSelected(!(nowDateStr === interDateStr))
    }, [startDate])

    const toggleShowPass = (show) => {
        togglePassHidden(!show)
        togglePassShow(show)
    }

    const onTryReg = () => {
        setShowRequired(true)
        if (firstNameIsValid && nameIsValid && patronymicIsValid && emailIsValid && passIsValid && secPassIsValid && dateIsSelected && genderId && consent && phoneIsValid) {
            let year = startDate.getFullYear()
            let month = startDate.getMonth() + 1
            let day = startDate.getDate()
            let birthDate = `${month}/${day}/${year}`
            
            goReg(name, firstName, patronymic, email, phone, genderId, birthDate, password, subcription, consent)
        }

    }
    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/`;
        navigate(path);
    }

    const closeConfirmEmailModal = (status) => {
        if (status === "ok") {
            goAuth(email, password)
                .then((result) => {
                    if (result === "ok") {
                        routeChange()
                    }
                })
        }
        toggleShowConfirmEmailBar(false)
    }

    const closeAuthModal = () => {
        setAuthModalIsOpen(false)
    }
    const onCloseRecoveryPassModal = (status) => {
        toggleRecoveryPassModalIsOpen(false)
    }

    const showConfirmEmailError = () => {
        if (!email) {
            ToastsStore.error("Почта не введена!")
        } else if (!emailIsValid) {
            ToastsStore.error("Почта указана неверно!")
        }
    }


    return (
        <div id="registration" className={s.regWrapper}>
            <div className={s.regForm}>
                <div className={s.logo}><img src={logo} alt=""/></div>
                <p className={s.head}>Регистрация</p>
                <Input margin="0 0 20px 0" callBackChangeText={setName} tone="light" inputType="name"
                       placeholderText="Имя"
                       textTone="light" required={true} minLength={2} maxLength={30} onSetValid={setNameIsValid}
                       showRequired={showRequired} startText=""/>
                <Input margin="0 0 20px 0" callBackChangeText={setFirstName} tone="light" textTone="light"
                       inputType="name"
                       placeholderText="Фамилия" required={true} minLength={2} maxLength={35}
                       onSetValid={setFirstNameIsValid} showRequired={showRequired} startText=""/>
                <Input margin="0 0 20px 0" callBackChangeText={setPatronymic} tone="light" textTone="light"
                       inputType="name" startText=""
                       placeholderText="Отчество" onSetValid={setPatronymicIsValid} minLength={2} maxLength={30}/>
                <Input margin="0 0 20px 0" callBackChangeText={setEmail} tone="light" textTone="light" inputType="email"
                       placeholderText="Email" required={true} onSetValid={setEmailIsValid}
                       showRequired={showRequired} startText=""/>
                <Input margin="0 0 38px 0" callBackChangeText={setPhone} tone="light" textTone="light" inputType="phone"
                       placeholderText="Телефон" onSetValid={setPhoneIsValid} required={true} minLength={9}
                       maxLength={13} showRequired={showRequired} startText=""/>
                <p className={s.subHead}>Выберите пол:</p>
                <div className={s.genderSelectBox}>
                    <div className={s.genderCheck}><CheckBox margin="0 7px 4px 0" iconHeight="19px"
                                                             onSetChecked={setGenderId}
                                                             isManyChoice={false} id={1}
                                                             selectItemId={genderId} text="Мужской"/>
                    </div>
                    <div className={s.genderCheck}><CheckBox margin="0 7px 4px 0" iconHeight="19px"
                                                             onSetChecked={setGenderId}
                                                             isManyChoice={false} id={2}
                                                             selectItemId={genderId} text="Женский"/>
                    </div>
                </div>
                <hr color="#EDEDED" size={1}/>
                {showRequired && !genderId ? <p className={s.subErrorText}>Укажите ваш пол</p> : null}
                <p className={`${s.subHead} ${s.birthDayHead}`}>Дата рождения:</p>
                <div className={s.selectBirthDayBox}>
                    <CustomDatePicker value={startDate} onChange={(date) => setStartDate(date)}/>
                </div>
                <hr color="#EDEDED" size={1}/>
                {showRequired && !dateIsSelected ? <p className={s.subErrorText}>Укажите дату рождения</p> : null}
                <div className={s.interPassBox}>
                    <Input isHidden={passHidden} margin="0 0 20px 0" callBackChangeText={setPassword} tone="light"
                           textTone="light"
                           inputType="password" startText=""
                           placeholderText="Пароль" required={true} minLength={6} maxLength={30}
                           onSetValid={setPassIsValid} showRequired={showRequired}/>
                    <Input isHidden={passHidden} margin="0 0 20px 0" callBackChangeText={setSecondPassword} tone="light"
                           textTone="light"
                           inputType="password" startText=""
                           placeholderText="Подтвердите пароль" required={true} maxLength={30} minLength={6}
                           onSetValid={setSecPassIsValid} showRequired={showRequired}/>
                    <span><CheckBox iconHeight="19px" onSetChecked={toggleShowPass}
                                    bool={passShow} margin="0 7px 4px 0" text="Показать пароль"/></span>
                </div>
                <div className={s.btnsWrapp}>
                    <Button btnId="reg"
                            formWrapId="registration"
                            onClick={onTryReg}
                            variant={4} btnText="Зарегистрироваться"/>
                    <Button margin="20px 0 0 0"
                            btnId="confirmEmail"
                            onClick={() => toggleShowConfirmEmailBar(true)}
                            variant={3} btnText="Подтвердить почту"
                            disFunk={!emailIsValid || !email ? showConfirmEmailError: null}
                    />

                </div>
                <p className={s.errorText}>{regError[0]}</p>
                <div className={s.confirmBar}>
                    <div className={s.check}><CheckBox margin="0 7px 4px 0"
                                                       text="Согласие на обработку и передачу персональных данных"
                                                       onSetChecked={setConsent} bool={consent}/></div>
                    {showRequired && !consent ?
                        <p className={s.subErrorText}>Обязательное условие для регистрации</p> : null}
                </div>
                <div className={`${s.check} ${s.confirmBar}`}><CheckBox margin="0 7px 4px 0"
                                                                        text="Согласие на получение рассылки"
                                                                        onSetChecked={setSubcription}
                                                                        bool={subcription}/></div>
                <div className={s.linksBar}>

                    <p onClick={() => setAuthModalIsOpen(true)} className={s.authLink}>УЖЕ ЕСТЬ АККАУНТ? ВОЙТИ</p>
                </div>
                {confirmEmailInProgress ?
                    <ConfirmationEmailBarContainer email={email || "почту"} onClose={closeConfirmEmailModal}/> : null}
            </div>
            {authModalIsOpen ? <AuthBarContainer onOpenRecovery={() => toggleRecoveryPassModalIsOpen(true)}
                                                 onClose={closeAuthModal}/> : null}
            {recoveryPassModalIsOpen ? <RecoveryPasswordContainer onClose={onCloseRecoveryPassModal}/> : null}
        </div>
    )
}