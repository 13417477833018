import AdminTable from "../../../Components/AdminTable/AdminTable"
import s from "./User.module.css"

import { getUsers } from "../../../DAL/ApiRequests"
import { useState } from "react"
import { useEffect } from "react"

const GENDERS = ["","Мужской","Женский"]

export const Users = () =>{
    const [page,setPage] = useState(1)
    const [count,setCount] = useState(10)
    const [totalCount, setTotalCount] = useState(0);

    const [users, setUsers] = useState([])

    const userTemplate = [
        {
            title:"ФИО",
            renderCell:(item)=>(<p>{item.last_name} {item.first_name} {item.patronymic}</p>)
        },
        {
            title:"Email",
            key:"email",
            
        },
        {
            title:"Телефон",
            key:"phone",
        },
        {
            title:"Пол",
            renderCell:(item)=>{
                return (<p>{GENDERS[item.gender]}</p>)
            }
        },
        
    ]

    const onGetUsers = (r) =>{
        if(r.status == "ok"){
            setUsers(r.data)
        }else
            alert(r.message)
    }

    useEffect(()=>{
        getUsers(page,count).then(onGetUsers) 
    },[page,count])
    return (

        <div className={s.container}>
            <AdminTable rows={users}
                            template={userTemplate}
                            page={page}
                            setPage={setPage}
                            count={count}
                            setCount={setCount}
                            totalCount={"---"}/>
        </div>
    )
}