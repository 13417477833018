import React, {Component} from "react";
import Slider from "react-slick";

import s from "./ReviewsSlider.module.css";
import next from "../../../../Assets/Icons/showNextSlideBlack.svg";
import previous from "../../../../Assets/Icons/showPreviousSlideBlack.svg";

function SampleNextArrow(props) {
    const {className, style, onClick, subOnClick} = props;
    return (
        <div style={{...style}} onClick={() => {
            onClick()
            subOnClick()
        }} className={`${className} ${s.nextArrow}`}>
            <img src={next} alt=""/>
        </div>
    );
}

function SamplePrevArrow(props) {
    const {className, style, onClick, subOnClick} = props;
    return (
        <div style={{...style}} onClick={() => {
            onClick()
            subOnClick()
        }} className={`${className} ${s.prevArrow}`}>
            <img src={previous} alt=""/>
        </div>
    );
}

export default class ReviewsSlider extends Component {

    componentDidMount() {

        window.addEventListener("resize", this.resizeMethod)
    }

    resizeMethod = () => {
        let width = window.innerWidth
        this.setState({screenWidth: width - 20})
        this.setState({isMobile: width <= 900})
    }


    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeMethod)
    }

    constructor(props) {
        super(props);
        this.state = {
            activeItem: 0,
            screenWidth: window.innerWidth - 20,
            isMobile: window.innerWidth <= 900,
        }
    }

    onGoNextSlide = (index) => {
        this.setState({activeItem: index})
    }

    render() {
        const settings = {
            arrows: true,
            lazyLoad: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: false,
            autoplay: true,
            autoplaySpeed: 4000,
            afterChange: this.onGoNextSlide,
            nextArrow: <SampleNextArrow subOnClick={this.onGoNextSlide}/>,
            prevArrow: <SamplePrevArrow subOnClick={this.onGoNextSlide}/>,
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 900,
                    settings: {
                        arrows: false,
                    }
                }
            ]
        };

        const reviewsText = this.props.reviews

        return (
            <div style={{maxWidth: `${this.state.screenWidth}px`, margin: "0 auto", position: "relative"}}>
                <Slider {...settings}>
                    {reviewsText.map((t, i) => (
                        <div key={i}>
                            <div>
                                <ReviewSlide photo={t.avatar} name={t.name}
                                             jobPosition={t.position} text={t.text}
                                             moreText={t.moreText}
                                             setReviewsModal={this.props.setReviewsModal}
                                             id={i}/>
                            </div>
                        </div>
                    ))}


                </Slider>
                <div className={s.paginationWrapper} style={{
                    position: "absolute",
                    bottom: "20px",
                    display: "flex",
                    justifyContent: "center",
                    width: "100%"
                }}>
                    <div className={s.paginationBar} style={{
                        display: "flex",
                        padding: "5px",
                        borderRadius: "10px"
                    }}>
                        <div className={this.state.activeItem === 0 ? s.activePage : s.pageElement}/>
                        <div className={this.state.activeItem === 1 ? s.activePage : s.pageElement}/>
                        <div className={this.state.activeItem === 2 ? s.activePage : s.pageElement}/>
                        <div className={this.state.activeItem === 3 ? s.activePage : s.pageElement}/>
                    </div>
                </div>
            </div>
        );
    }
}

const ReviewSlide = ({photo, name, jobPosition, text, moreText, id, setReviewsModal}) => {

    return (<div className={s.slide}>
        <img src={photo} alt=""/>
        <div className={s.textBar}>
            <div><p className={s.nameAge}>{name}</p>
                <p className={s.jobPosition}>{jobPosition}</p></div>
            <p className={s.text}>{text}</p>
            <p onClick={() => setReviewsModal(id)} className={`${s.showMoreText} ${s.more}`}>Далее...</p>
        </div>
    </div>)
}