import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {Input} from "../Input/Input";
import {DropList} from "../DropList/DropList";
import FilterContainer from "../Filter/FilterContainer";
import {Button} from "../Button/Button";
import s from "./SearchBar.module.css"


const SearchCard = ({v, listType, routeChange}) => {
    return (
        <div onClick={() => routeChange(v.id)} key={v.id}
             className={s.vacancy}>
            <div className={s.leftPart}>
                <p className={s.vacName}>{v.name}</p>
                <p className={s.townName}>{v.town}</p>
            </div>
            <div className={s.rightPart}>
                {listType === 1 ? null : (
                    <p className={s.salaryValue}>
                        {v.salary_start ? `от ${v.salary_start} сомони` : 'договорная'}
                    </p>
                )}
            </div>
        </div>
    )
}

export const SearchBar = ({isMobile, vacancies, regions, towns, onGetVacancies, foundVacanciesCount, onSetRegion, listType, isArchive, isAdmin = false}) => {
    const [searchText, setSearchText] = useState("")
    const [selectedRegion, setSelectedRegion] = useState(0)
    const [selectedTown, setSelectedTown] = useState(0)
    const [selectedFilterParams, setSelectedFilterParams] = useState([])
    const [vacListPage, setVacListPage] = useState(1)
    const [activeDroppedList, setActiveDroppedList] = useState(0)

    useEffect(() => {
        setActiveDroppedList(0)
    }, [selectedRegion, selectedTown])

    useEffect(() => {
        onSetRegion(selectedRegion)
    }, [selectedRegion])

    useEffect(() => {
        setVacListPage(1)
        goGetVacancies(false, 1)
    }, [searchText, selectedRegion, selectedTown, selectedFilterParams, isArchive])

    useEffect(() => {
        if (vacListPage > 1) {
            goGetVacancies(true)
        }
    }, [vacListPage])

    let navigate = useNavigate();
    const routeChange = (vacProbId) => {
        let firstParts = !isAdmin ? ["/vakansiya/", "/stazhirovka/"] :
            ["/admin/vacancy?id=", "/admin/probation?id="];
        let path;
        if (vacProbId) {
            path = `${firstParts[listType]}${vacProbId}`
        }
        navigate(path);
    }

    const setDroppedListId = (id) => {
        if (id === activeDroppedList) {
            setActiveDroppedList(0)
        } else {
            setActiveDroppedList(id)
        }
    }

    const goGetVacancies = (isGetMore = false, page = vacListPage) => {
        if ((selectedFilterParams?.[1]?.[0]?.selectIds !== undefined || selectedFilterParams?.[0]) && selectedRegion !== undefined && selectedTown !== undefined) {
            onGetVacancies(page, 20, selectedRegion, selectedTown, selectedFilterParams?.[1]?.[0]?.selectIds || [], selectedFilterParams?.[1]?.[1]?.selectIds || [], searchText, listType, isGetMore, isArchive)
        }
    }

    const goGetMoreVacancies = () => {
        setVacListPage(vacListPage + 1)
    }


    let vacanciesList = vacancies?.length > 0 ? vacancies.map((v) => <SearchCard v={v}
                                                                                 key={v.id}
                                                                                 listType={listType}
                                                                                 routeChange={routeChange}/>) :
        <p className={s.noneSearchText}>Ничего не найдено</p>;
    return <div onClick={() => setActiveDroppedList(0)} className={s.contentBar}>
        {isMobile ? <div className={s.searchInputsBar}>
            <div className={s.searchRowBar}>
                <Input tone="light" callBackChangeText={setSearchText} inputType="searchText"
                       placeholderText="Поиск по имени или описанию"/>
            </div>
            <div className={s.choiceTownBar}>
                <DropList id={1} activeId={activeDroppedList} setActiveId={setDroppedListId}
                          onSelect={setSelectedRegion} list={regions} listName="Выбрать регион"/>
                <DropList id={2} activeId={activeDroppedList} setActiveId={setDroppedListId} onSelect={setSelectedTown}
                          list={towns} listName="Выбрать город" isChangeable={true}/>
            </div>
        </div> : null}
        <div className={s.filterBar}>
            <FilterContainer onSelect={setSelectedFilterParams}/>
        </div>
        <div className={s.programListBar}>
            {!isMobile ? <div className={s.searchInputsBar}>
                <div className={s.searchRowBar}>
                    <Input tone="light" callBackChangeText={setSearchText} inputType="searchText"
                           placeholderText="Поиск по имени или описанию"/>
                </div>
                <div className={s.choiceTownBar}>
                    <DropList id={1} activeId={activeDroppedList} setActiveId={setDroppedListId}
                              onSelect={setSelectedRegion} list={regions} listName="Выбрать регион"/>
                    <DropList id={2} activeId={activeDroppedList} setActiveId={setDroppedListId}
                              onSelect={setSelectedTown} list={towns} listName="Выбрать город" isChangeable={true}/>
                </div>
            </div> : null}
            <div className={s.vacList}>
                {vacanciesList}
            </div>
            {vacanciesList.length < foundVacanciesCount ? <div className={s.showMore}>
                <Button variant={4}
                        onClick={goGetMoreVacancies}
                        btnText={`Показать еще (${vacanciesList.length} из ${foundVacanciesCount})`}/>
            </div> : null}
        </div>
    </div>
}