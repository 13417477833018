import s from './ProbationInfoItem.module.css'

const ProbationInfoItem = ({image,title,content}) =>{
    return (
        <div className={s.container}>
            <div className={s.imageConatiner}>
                <img src={image}/>
                
            </div>
            <span className={s.content}>
                <p className={s.title}>{title}</p>
                {content?.split("\n").map((t, i) =>(
                    <p key={i}>
                    {t}
                    <br/>
                    </p>
                ))}
            </span>
            <span className={s.hrLine}></span>
        </div>
    )
}


export default ProbationInfoItem