import * as React from "react";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import {ProbationInfo} from "./ProbationInfo";
import {setVacancyProbationTH} from "../../Redux/Reducers/AppReducer";


const mapStateToProps = (state) => {
    return {
        probationInfo: state.app.probationInfo,
        userSubscribe: state.app.userSubscribe,
        authorized: state.app.authorized
    }
}

const ProbationInfoContainer = (props) => {
    let params = useParams();
    useEffect(() => {
        props.setVacancyProbationTH(params.vacProbId, props.contentType)
    }, [])
    return (
        <ProbationInfo authorized = {props.authorized} vacancyId = {params.vacProbId} userSubscribe = {props.userSubscribe} probationInfo = {props.probationInfo} isMobile={props.isMobile}/>
    )
}

export default connect(mapStateToProps, {setVacancyProbationTH})(ProbationInfoContainer)


