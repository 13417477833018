import * as React from "react";
import {connect} from "react-redux";
import {authorizationTH, setAuthorizedAC, setConfirmEmailInProgressAC} from "../../Redux/Reducers/AppReducer";
import {AuthBar} from "./AuthBar";


const mapStateToProps = (state) => {
    return {
        errorMessage: state.app.errors.authError,
        confirmEmailInProgress: state.app.confirmEmailInProgress
    }
}

class AuthBarContainer extends React.Component {


    goAuth = (login, password) => {
        return this.props.authorizationTH(login, password)
    }

    onClearErrMessage = () => {
        this.props.setAuthorizedAC({status: "dropMessage", message: false})
    }

    toggleShowConfirmEmailBar = (bool) => {
        this.props.setConfirmEmailInProgressAC(bool)
    }


    render() {
        return <AuthBar confirmEmailInProgress={this.props.confirmEmailInProgress}
                        toggleShowConfirmEmailBar={this.toggleShowConfirmEmailBar}
                        onClearErrMessage={this.onClearErrMessage} errorMessage={this.props.errorMessage}
                        goAuth={this.goAuth} onClose={this.props.onClose} onOpenRecovery = {this.props.onOpenRecovery}/>
    }
}

export default connect(mapStateToProps, {
    authorizationTH,
    setAuthorizedAC,
    setConfirmEmailInProgressAC
})(AuthBarContainer)