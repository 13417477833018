import EmailSubscribeBlock from "./EmailSubscribeBlock";
import React from "react";
import {connect} from "react-redux";
import {subscribeSenderTH} from "../../Redux/Reducers/AppReducer";

const mapStateToProps = (state) => {
    return {
        userEmail: state.app.userEmail,
    }
}

 class EmailSubscribeBlockContainer extends React.Component {
    onSubscribe = (email) => {
        return this.props.subscribeSenderTH(email)
    }
    render() {
        return (
            <EmailSubscribeBlock onSubscribe = {this.onSubscribe} userEmail = {this.props.userEmail} isMobile = {this.props.isMobile} isDark = {this.props.isDark}/>
        )
    }
}


export default connect (mapStateToProps, {subscribeSenderTH})(EmailSubscribeBlockContainer)