import {UserProfileInfoCard} from "./UserProfileInfoCard";
import React from "react";
import {connect} from "react-redux";
import {editProfileTH, setDirectionsTH, setProfileInfoTH, subscribeSenderTH} from "../../Redux/Reducers/AppReducer";

const mapStateToProps = (state) => {
    return {
        userPhoto: state.app.userPhoto,
        userName: state.app.userName,
        userEmail: state.app.userEmail,
        userPhone: state.app.userPhone,
        userBirthDate: state.app.userBirthDate,
        userGender: state.app.userGender,
        userSubscribe: state.app.userSubscribe,
        userPriorityDirections: state.app.userPriorityDirections,
        directions: state.app.directions,
        editProfileError: state.app.errors.editProfileError
    }
}

class UserProfileInfoCardContainer extends React.Component {

    componentDidMount() {
        this.props.setDirectionsTH()
        // this.props.setProfileInfoTH()
    }

    onEdit = async (name, photo, email, phone, gender, birthDate, directions) => {
        this.props.editProfileTH(name, photo, email, phone, gender, birthDate, directions)
    }

    onSubscribe = (email) => {
        return this.props.subscribeSenderTH(email, !this.props.userSubscribe)
    }

    render() {
        return <UserProfileInfoCard directions={this.props.directions} userGender={this.props.userGender}
                                    userSubscribe={this.props.userSubscribe} userBirthDate={this.props.userBirthDate}
                                    userPhoto={this.props.userPhoto} userPhone={this.props.userPhone}
                                    userEmail={this.props.userEmail} userName={this.props.userName}
                                    userPriorityDirections={this.props.userPriorityDirections}
                                    onEdit={this.onEdit} isMobile={this.props.isMobile}
                                    editProfileError = {this.props.editProfileError}
                                    onSubscribe = {this.onSubscribe}
                                    setProfileInfo ={this.props.setProfileInfoTH}
        />
    }
}

export default connect(mapStateToProps, {editProfileTH, setDirectionsTH, subscribeSenderTH, setProfileInfoTH})(UserProfileInfoCardContainer)