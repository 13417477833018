import React, {useEffect, useState} from "react";
import s from "./СonfirmationEmailBar.module.css"
import close from "../../Assets/Icons/close-outline.svg";
import {Input} from "../Input/Input";
import {Button} from "../Button/Button";


export const ConfirmationEmailBar = ({email, confirmStatus, confEmailError, onSendCode, onClose, onResendCode}) => {

    const [code, setCode] = useState("")
    const [status, setStatus] = useState(confirmStatus)
    const [seconds, setSeconds] = useState(60)
    const [sendBlocked, setSendBlocked] = useState(false)

    let intervalLink;

    useEffect(() => {
        setStatus(confirmStatus)
    }, [confirmStatus])

    useEffect(() => {
        setSendBlocked(true)
        startTimer()
        return () => clearInterval(intervalLink)
    }, [])

    const startTimer = () => {
        intervalLink = setInterval(() => {
            setSeconds((seconds) => {
                if (seconds > 0) {
                    return seconds - 1
                } else {
                    clearInterval(intervalLink)
                    setSendBlocked(false)
                    setSeconds(60)
                }
            })
        }, 1000)
    }

    const sendCodeOnEmail = () => {
        onSendCode(email)
        setSendBlocked(true)
        startTimer()
    }

    const sendCode = () => {
        if (code.length !== 4) {
            setStatus("Код состоит из 4 символов")
        } else {
            setStatus("")
            onSendCode(code)
                .then((result) => {
                    if (result === "ok") {
                        onClose("ok")
                    }
                })
        }
    }

    return (
        <div className={s.wrapper}>
            <div className={s.subWrapper}>
                <img className={s.closeIcon} onClick={onClose} src={close}/>
                <div className={s.inputForm}>
                    {sendBlocked ?
                        <p className={s.timerText}>Вы сможете повторно отправить
                            код через {seconds}с</p> :
                        <div className={s.resendBar}><p className={s.sendCodeAgain}
                                                        onClick={() => {
                                                                onResendCode(email)
                                                                setSendBlocked(true)
                                                                startTimer()
                                                            }}>Отправить
                            код повторно</p></div>}
                    <p className={s.sendEmailHead}>Введите код, отправленный на {email}</p>
                    <p style = {{fontSize:"16px",lineHeight:"16px"}}>Не забудьте проверить папку "Спам"</p>
                    <Input margin="0 0 20px 0" inputType="text" placeholderText="Код подтверждения"
                           callBackChangeText={setCode}/>
                    {status ? <div className={s.statusBar}><p>{status}</p></div> : null}
                    <div className={s.btnWrapp}><Button margin="0 0 20px 0" variant={4} onClick={sendCode}
                                                        btnText="Подтвердить"/></div>
                    {!confirmStatus ? <p className={s.errorText}>{confEmailError[0]}</p> :
                        <p className={s.successText}>Почта подтверждена!</p>}
                </div>
            </div>
        </div>
    )
}