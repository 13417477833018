import Slider from "react-slick";

import img3 from "../../../../Assets/CardsImages/img1.png"
import s from "./CardSlider.module.css"
import React, {Component} from "react";
import previousBlack from "../../../../Assets/Icons/showPreviousSlideBlack.svg";
import nextBlack from "../../../../Assets/Icons/showNextSlideBlack.svg";
import {SlideCard} from "../../../Cards/SlideCard/SlideCard";
import {getVacanciesTH} from "../../../../Redux/Reducers/AppReducer";
import {connect} from "react-redux";


function SampleNextArrow(props) {
    const {className, style, onClick, subOnClick} = props;
    return (
        <div style={{...style}} onClick={() => {
            onClick()
            subOnClick()
        }} className={`${className} ${s.nextArrow}`}>
            <img src={nextBlack} alt=""/>
        </div>
    );
}

function SamplePrevArrow(props) {
    const {className, style, onClick, subOnClick} = props;
    return (
        <div style={{...style}} onClick={() => {
            onClick()
            subOnClick()
        }} className={`${className} ${s.prevArrow}`}>
            <img src={previousBlack} alt=""/>
        </div>
    );
}


const PaginationDot = ({i,activeItem}) =>{
    return(
        <div className={activeItem == parseInt(i) ? s.activePage : s.pageElement}/>
    )
}

const mapStateToProps = (state) => {
    return {
        vacancies: state.app.vacancies,
    }
}

 class CardSlider extends Component {

    componentDidMount() {
        this.props.getVacanciesTH(1, 6, 0, 0, [], [], "", 0, false, false)
        // if(this.props.vacancies)
        let windowSize = window.innerWidth

        if (windowSize <= 900) {
            this.setState({isMobile: true})
            this.setState({screenWidth: window.innerWidth - 608})
        }

        if (windowSize < 1535) {
            this.setState({viewSlidesCount: 2})
            this.setState({screenWidth: window.innerWidth - 650})
        }
        if (windowSize > 1535) {
            this.setState({viewSlidesCount: 3})
            this.setState({screenWidth: window.innerWidth - 20})
        }
        if (windowSize < 1440) {
            this.setState({viewSlidesCount: 1})
            this.setState({screenWidth: window.innerWidth - 975})
        }
        if (windowSize > 1440 && window.innerWidth < 1535) {
            this.setState({viewSlidesCount: 2})
        }

        window.addEventListener("resize", this.resizeMethod)
    }

     resizeMethod = () => {
         if (window.innerWidth <= 900) {
             this.setState({isMobile: true})
             this.setState({screenWidth: window.innerWidth - 608})
         } else {
             this.setState({isMobile: false})
         }
         if (window.innerWidth < 1535) {
             this.setState({viewSlidesCount: 2})
             this.setState({screenWidth: window.innerWidth - 650})
         }
         if (window.innerWidth > 1535) {
             this.setState({viewSlidesCount: 3})
             this.setState({screenWidth: window.innerWidth - 20})
         }
         if (window.innerWidth < 1440) {
             this.setState({viewSlidesCount: 1})
             this.setState({screenWidth: window.innerWidth - 975})
         }
         if (window.innerWidth > 1440 && window.innerWidth < 1535) {
             this.setState({viewSlidesCount: 2})
         }
     }


     componentWillUnmount() {
         window.removeEventListener("resize", this.resizeMethod)
     }

    constructor(props) {
        super(props);
        this.state = {
            activeItem: 0,
            screenWidth: window.innerWidth - 20,
            viewSlidesCount: 3,
            isMobile: false
        }
    }

    onGoPreviousSlide = (index) => {
        this.setState({activeItem: index})

    }

    onGoNextSlide = (index) => {
        this.setState({activeItem: index})
    }

    render() {
        const settings = {
            arrows: !this.state.isMobile,
            lazyLoad: false,
            infinite: true,
            speed: 500,
            slidesToShow: this.state.viewSlidesCount,
            slidesToScroll: 1,
            adaptiveHeight: true,
            nextArrow: <SampleNextArrow subOnClick={this.onGoNextSlide}/>,
            prevArrow: <SamplePrevArrow subOnClick={this.onGoPreviousSlide}/>,
            afterChange: this.onGoNextSlide,
            swipeToSlide: true
        };
        return (
            <div style={{
                maxWidth: `${!this.state.isMobile ? this.state.screenWidth: 350}px`,
                margin: "0 auto",
                position: "relative",
                minWidth: `${this.state.isMobile ? "350px" : "450px"}`,
            }}>
                <Slider {...settings}>
                    {this.props.vacancies?.map((s,i) =>(
                        <div key={s.id}>
                            <div style={{marginBottom: "10px", display: "flex", justifyContent:"center"}}>
                                <SlideCard btnVariant={4} id={s.id} headText={s.name}
                                        text={s.description} btnIsLink = {true} index={i}/>
                            </div>
                        </div>
                    ))}
                    
                    
                </Slider>
                <div style={{
                    position: "absolute",
                    bottom: "-25px",
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                }}>
                    <div className={s.backPagination}
                         style={{display: "flex", backgroundColor: "rgba(0, 184, 86, 0.5)", padding: "5px"}}>
                             {this.props.vacancies?.map((s,i) =>{
                                 return (
                                     <div key={s.id}>
                                         <PaginationDot i = {i} activeItem={this.state.activeItem}/>
                                     </div>
                             )})}

                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, {getVacanciesTH})(CardSlider)