import * as React from "react";
import {SendApplicationBar} from "./SendApplicationBar";
import {connect} from "react-redux";
import {sendApplicationTH, setSendApplicationStatusAC} from "../../Redux/Reducers/AppReducer";


const mapStateToProps = (state) => {
    return {
        userEmail: state.app.userEmail,
        userPhone: state.app.userPhone,
        userResumes: state.app.userResumes,
        startDates: state.app.startDates,
        userName: state.app.userName,
        errorText: state.app.errors.sendApplicationError,
        authorized: state.app.authorized
    }
}

class SendApplicationBarContainer extends React.Component {

    onSend = (email, phone, name, lastName, patronymic, resume, startDate, vacancyId) => {
        return this.props.sendApplicationTH(email, phone, name, lastName, patronymic, resume, startDate, vacancyId)
    }

    cleanErrorText = () => {
        this.props.setSendApplicationStatusAC({status: "ok", message: ""})
    }

    render() {
        return <SendApplicationBar onSend={this.onSend} userResumes={this.props.userResumes}
                                   userEmail={this.props.userEmail} userPhone={this.props.userPhone}
                                   startDates={this.props.startDates}
                                   onClose={this.props.onClose}
                                   isMobile={this.props.isMobile}
                                   userName={this.props.userName}
                                   vacancyId={this.props.vacancyId}
                                   respErrorText = {this.props.errorText}
                                   onDropErrorText = {this.cleanErrorText}
                                   authorized = {this.props.authorized}
        />
    }
}

export default connect(mapStateToProps, {sendApplicationTH, setSendApplicationStatusAC})(SendApplicationBarContainer)