import { useNavigate } from "react-router-dom"
import { exitAdmin } from "../../DAL/ApiRequests"
import { Button } from "../Button/Button"
import s from "./AdminNav.module.css"

const redirect = (link) =>{
    const a = document.createElement("a")
    a.href = link
    a.click()
}

const AdminNavButton = ({name,link}) =>{
    const navigate = useNavigate()
    return (
        <Button variant={1}
                backColor={"var(--dimGreen)"}
                textColor={"white"}
                width={"200px"}
                btnText={name}
                onClick={()=>{
                    
                    redirect(`/admin/${link}`)
                }}/>
    )
}


const ExitNavButton = () =>{
    const navigate = useNavigate()
    return (
        <Button variant={1}
                backColor={"#b2102f"}
                textColor={"white"}
                width={"200px"}
                btnText="Выход"
                onClick = {()=>{
                    exitAdmin().then(r=>{
                       if(r.status == "ok")
                           navigate("/")
                       else
                           alert(r.message)
                    })
                }}/>       
    )
}


const AdminNav = () =>{
    return(
        <div className={s.navBar}>
            <AdminNavButton name="Регионы и городы" link="cities"/>
            <AdminNavButton name="Специализации и Направления" link="directions"/>
            <AdminNavButton name="Создать Стажировку" link="probation"/>
            <AdminNavButton name="Создать вакансию" link="vacancy"/>
            <AdminNavButton name="Стажировки" link="probation_list"/>
            <AdminNavButton name="Вакансии" link="vacancy_list"/>
            <AdminNavButton name="Рассылка" link="mails"/>
            <AdminNavButton name="Заявки" link="requests"/>
            <AdminNavButton name="Пользователи" link="users"/>
            <AdminNavButton name="Резюме" link="resumes"/>
            <ExitNavButton/>
        </div>
    )
}

export default AdminNav