import React, { useEffect, useState } from "react";
import s from "./Profile.module.css";
import UserProfileInfoCardContainer from "../../Components/UserProfileInfoCard/UserProfileInfoCardContainer";
import { Button } from "../../Components/Button/Button";
import { NavLink, useNavigate } from "react-router-dom";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
import CreateResumeBarContainer from "../../Components/CreateResumeBar/CreateResumeBarContainer";
import CustomDatePicker from "../../Components/DatePicker/DatePicker";
import TestModal from "../../Components/TestModal";

import {getTest} from "../../DAL/ApiRequests"

export const Profile = ({
  userResumes,
  userVacResponses,
  userProbResponses,
  userFeatVacancies,
  userTests,
  isMobile,
  authorized,
}) => {
  const [userResumesList, setUserResumesList] = useState([]);
  const [userVacResponsesList, setUserVacResponsesList] = useState([]);
  const [userProbResponsesList, setUserProbResponsesList] = useState([]);
  const [userFeatVacanciesList, setUserFeatVacanciesList] = useState([]);
  const [userTestsList, setUserTestsList] = useState([]);
  const [sendResumeModalIsOpen, setSendResumeModalIsOpen] = useState(false);
  const [editResumeModalNum, setEditResumeModalNum] = useState(-1);

  const [openModal,setOpenModal] = useState(false)
  const [test,setTest] = useState(undefined)

  const onCloseSendResumeModal = (status) => {
    setSendResumeModalIsOpen(false);
  };
  const onCloseEditResumeModal = (status) => {
    setEditResumeModalNum(-1);
  };

  const getTestState = (id) => {
    getTest(id).then(r=> {
      if(r.status == "ok")
        setTest(r.data)
    })
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  useEffect(() => {
    let i = 0; //КОСТЫЛЬ
    setUserResumesList(
      userResumes.map((resume) => {
        if (i < userResumes.length) {
          i += 1;
        }
        return (
          <div
            onClick={() => {
              setEditResumeModalNum(resume.id);
            }}
            id={resume.id}
            key={resume.id}
            className={s.card}
          >
            <p>Резюме {i}</p>
          </div>
        );
      })
    );

    setUserVacResponsesList(
      userVacResponses.map((respV) => {
        let vacPath = `/vakansiya/${respV.id_vacancy_probation}`;
        return (
          <div
            id={respV.id_vacancy_probation}
            key={respV.id_vacancy_probation}
            className={s.card}
          >
            {respV.status ? (
              <p className={s.statusPlate}>
                  {respV.status.name}
              </p>
            ):null}
            
            <NavLink to={vacPath}>
              <p>{respV.name_vacancy_probation}</p>
            </NavLink>
          </div>
        );
      })
    );

    setUserProbResponsesList(
      userProbResponses.map((respP) => {
        let vacPath = `/stazhirovki/${respP.id_vacancy_probation}`;
        return (
          <div
            id={respP.id_vacancy_probation}
            key={respP.id_vacancy_probation}
            className={s.card}
          >
            <NavLink to={vacPath}>
              <p>{respP.name_vacancy_probation}</p>
            </NavLink>
          </div>
        );
      })
    );

    setUserFeatVacanciesList(
      userFeatVacancies.map((feat) => {
        let vacPath = `/vakansiya/${feat.id_vacancy}`;
        return (
          <div id={feat.id_vacancy} key={feat.id_vacancy} className={s.card}>
            <NavLink to={vacPath}>
              <p>{feat.name_vacancy}</p>
            </NavLink>
          </div>
        );
      })
    );

    if (userTests)
      setUserTestsList(
        userTests.map((ut, i) => (
          <div key={`test-${i}`} className={`${s.card} ${s.testCard}`} onClick={()=>{
            getTestState(ut.id_test)
          }}>
            <p>{ut.name}</p>
            {ut.required ? <p className={s.required}>Обязательный</p> : null}
            {ut.result ? <p className={s.success}>Пройдено</p> : (<p>Не пройдено</p>)}
            
            {ut.result ? <p>Результат: {ut.status ? ut.confirmed ? "Сдан" : "Не сдан" : "Не проверено"}</p> : null}
          </div>
        ))
      );
    else setUserTestsList([]);
  }, [userVacResponses, userResumes, userProbResponses, userTests]);

  return (
    <div className={s.wrapper}>
      <div className={s.breadCrumbsBar}>
        <BreadCrumbs steps={[["Главная", "/"], ["Профиль"]]} />
      </div>
      <div className={s.userInfoBar}>
        <UserProfileInfoCardContainer isMobile={isMobile} />
      </div>
      <div className={s.cardResumesBar}>
        <p className={s.head}>Мои резюме:</p>
        <div className={s.list}>
          {userResumesList.length > 0 ? (
            userResumesList
          ) : (
            <p>У вас пока нет резюме</p>
          )}
        </div>
      </div>
      <div className={s.setResumeBar}>
        <p className={s.setResumeHead}>
          Поможем Вам грамотно и эффективно составить резюме!
        </p>
        <div className={s.btnWrapp}>
          <Button
            onClick={() => setSendResumeModalIsOpen(true)}
            padding="0 30px"
            btnText="Создай свое резюме"
            variant={4}
          />
        </div>
      </div>
      <div className={s.cardResumesBar}>
        <p className={s.head}>Мои отклики:</p>
        <div className={s.list}>
          {userVacResponsesList.length > 0 ? (
            userVacResponsesList
          ) : (
            <p>У вас пока нет заявок</p>
          )}
        </div>
      </div>
      <div className={s.cardResumesBar}>
        <p className={s.head}>Избранные вакансии:</p>
        <div className={s.list}>
          {userFeatVacanciesList.length > 0 ? (
            userFeatVacanciesList
          ) : (
            <p>У вас пока нет избранных вакансий</p>
          )}
        </div>
      </div>
      <div className={s.cardResumesBar}>
        <p className={s.head}>Мои стажировки:</p>
        <div className={s.list}>
          {userProbResponsesList.length > 0 ? (
            userProbResponsesList
          ) : (
            <p>У вас пока нет заявок на стажировки</p>
          )}
        </div>
      </div>
      <div className={s.cardResumesBar}>
        <p className={s.head}>Мои тесты:</p>
        <div className={s.list}>
          {userTestsList.length > 0 ? (
            userTestsList
          ) : (
            <p>У вас пока нет тестов</p>
          )}
        </div>
      </div>

      {sendResumeModalIsOpen ? (
        <CreateResumeBarContainer
          isEditMode={false}
          isModal={true}
          isMobile={isMobile}
          onClose={onCloseSendResumeModal}
        />
      ) : null}
      {editResumeModalNum !== -1 ? (
        <CreateResumeBarContainer
          resumeId={editResumeModalNum}
          isEditMode={true}
          isModal={true}
          isMobile={isMobile}
          onClose={onCloseEditResumeModal}
        />
      ) : null}
      <TestModal test={test} onClose={()=>{
        setTest(undefined)
      }}/>

    </div>
  );
};
