import React from "react";
import s from "./ChoiceDirectionBar.module.css"
import {Heading} from "../Heading/Heading";
import {ChoiceBar} from "../ChoiceBar/ChoiceBar";


export const ChoiceDirectionBar = ({directions, color, isMobile}) => {
    return (
        <div style={{backgroundColor:`${color}`, width:`${isMobile ? "100%": "80%"}`, borderRadius:`${isMobile ? "0": "25px"}`}} className={s.wrapper}>
            <div className={s.subWrapper}>
                <Heading headFontSize = {isMobile ? 35 : null} headText="Направления работы" text="В нашей компании сотрудники проявляют себя в разных направлениях деятельности:" width={isMobile ? "100%": "70%"} headWidth="50%" textWidth={isMobile ? "100%": "80%"} />
                <ChoiceBar isLinks = {true} items={directions} isManyChoice={false}/>
            </div>
        </div>
    )
}