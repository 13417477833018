import { authAdmin } from "../../../DAL/ApiRequests"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"

import { Button } from "../../../Components/Button/Button"

import s from "./adminAuth.module.css"
import { useState } from "react"


const AuthInput = ({label,val,setter,name = "name",pass = false}) =>{
    return (
        <div className={`${s.form__group} ${s.field}`}>
            <label htmlFor="name" className={s.form__label}>{label}</label>
            <input type={pass ? "password" :"input"}
                   className={s.form__field} 
                   placeholder={label} 
                   name={name}
                   value={val} 
                   onChange={(ev)=>{
                       setter(ev.target.value)
                   }}
                   
                   required />
            
        </div>
    )
}

const AuthAdmin = ()=>{
    const navigate = useNavigate()

    const[login,setLogin] = useState()
    const[password,setPassword] = useState()
    const auth = () => {
        authAdmin(login,password).then(a=>{
            if(a.status == "ok"){
                navigate("/admin/cities")
            }
            
        })
    }
    useEffect(()=>{
        // auth()
    },[])
    

    return (
        <div className={s.container}>
            <div className={s.authForm}>
                <AuthInput label={"Логин"}
                           val={login}
                           setter={setLogin}/>
                <AuthInput label={"Пароль"}
                           val={password}
                           setter={setPassword}
                           pass />
                <Button variant={1}
                        backColor={"var(--dimGreen)"} textColor={"white"}
                        onClick={()=>{
                            auth()
                        }}
                        btnText={"Авторизироваться"}/>
            </div>
        </div>
        
    )
}

export default AuthAdmin