import * as React from "react";
import {ChangePasswordBar} from "./ChangePasswordBar";
import {connect} from "react-redux";
import {editPasswordTH} from "../../Redux/Reducers/AppReducer";


const mapStateToProps = (state) => {
    return {
        editPassError: state.app.errors.editPassError,
        editPassStatus: state.app.editPassStatus
    }
}

class ChangePasswordBarContainer extends React.Component {

    sendNewPass = (newPass, newPassRepeat) => {
        return this.props.editPasswordTH(newPass, newPassRepeat)
    }

    render() {
        return <ChangePasswordBar editPassError={this.props.editPassError} editPassStatus={this.props.editPassStatus}
                                  onClose={this.props.onClose} onChangePass={this.sendNewPass}/>

    }
}

export default connect(mapStateToProps, {editPasswordTH})(ChangePasswordBarContainer)