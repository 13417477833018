import React from "react";
import {connect} from "react-redux";
import {
    getVacanciesTH,
    setDirectionsTH, setRegionsAndSitiesTH,
    setSpecializationsTH
} from "../../Redux/Reducers/AppReducer";
import {SearchBar} from "./SearchBar";


const mapStateToProps = (state) => {
    return {
        vacancies: state.app.vacancies,
        regions: state.app.regions,
        towns: state.app.towns,
        userSubscribe: state.app.userSubscribe,
        totalVacanciesCount: state.app.totalVacanciesCount,
        foundVacanciesCount: state.app.foundVacanciesCount,

    }
}

class SearchBarContainer extends React.Component {
    componentDidMount() {
        this.props.setDirectionsTH(this.props.listType)
        this.props.setSpecializationsTH(this.props.listType)
    }

    onGetVacancies = (page, count, region, city, directions, specializations, searchText, flag, isGetMore,isArchive) => {
        this.props.getVacanciesTH(page, count, region, city, directions, specializations, searchText, flag, isGetMore, isArchive)
    }

    onSetRegion = (regId) => {
        this.props.setRegionsAndSitiesTH(regId)
    }


    render() {
        return <SearchBar onSetRegion={this.onSetRegion} foundVacanciesCount={this.props.foundVacanciesCount}
                          totalVacanciesCount={this.props.totalVacanciesCount} onGetVacancies={this.onGetVacancies}
                          userSubscribe={this.props.userSubscribe} regions={this.props.regions}
                          towns={this.props.towns} vacancies={this.props.vacancies} isMobile={this.props.isMobile}
                          listType={this.props.listType}
                          tListIsDropped={this.props.tListIsDropped} rListIsDropped={this.props.rListIsDropped}
                          tListToggleDropped={this.props.tListToggleDropped} rListToggleDropped = {this.props.rListToggleDropped}
                          directionId={this.props.directionId}
                          isAdmin={this.props.isAdmin}
                          isArchive={this.props.isArchive}
        />
    }
}

export default connect(mapStateToProps, {
    setDirectionsTH,
    setSpecializationsTH,
    getVacanciesTH,
    setRegionsAndSitiesTH
})(SearchBarContainer)