import s from "./EditInputKit.module.css"

import {CustomDateTimePicker} from "../DatePicker/DatePicker";
import { useEffect } from "react";


export const DateTime = ({ setter, val, k }) =>{
    return (
        <CustomDateTimePicker 
            value={val}
            onChange={(v) =>{
                var eventVal = val
                eventVal[k] = v
                setter({...v})
            }}/>
    )
}

export const Input = ({ type = "text", setter, val, k, placeholder = "" }) => {

    return (
      <input type={type} className={s.input}
        value={val[k]}
        placeholder={placeholder}
        onInput={(ev) => {
          var eventVal = val
          eventVal[k] = ev.target.value
          setter({ ...eventVal })
        }} />
    )
  }
  
  export const TextArea = ({ setter, val, k }) => {
    return (
      <textarea className={s.input}
        value={val[k]}
        onInput={(ev) => {
          var eventVal = val
          eventVal[k] = ev.target.value
          setter({ ...eventVal })
        }}
        style={{ height: "150px" }} />
    )
  }
  
  export const CheckBox = ({setter,val,k}) =>{
    return (
      <input type={"checkbox"}
              checked={val[k]}
             onChange = {(ev)=>{
                var eventVal = val
                eventVal[k] = ev.target.checked
                setter({...eventVal})
  
             }}/>
    )
  }
  
  export const Select = ({ setter, val, k, values }) => {
    useEffect(()=>{
      if(values.length > 0 && val[k] == 0){
        var eventVal = val
        eventVal[k] = parseInt(values[0].id)
        setter({ ...eventVal })
      }
    },[values])
    return (
      <select onChange={(ev) => {
        var eventVal = val
  
        eventVal[k] = parseInt(ev.target.value)
        
        setter({ ...eventVal })
      }}
        
        value={{...val}[k]}
        style={{
          fontSize: "22px"
        }}>
          
        {values.map(v =>  ( 
          <option value={v.id}>{v.name}</option> 
        ))}
      </select>
    )
  }
  
  export const Row = ({ title, children }) => {
    return (
      <div className={s.row}>
        <p>{title}</p>
        {children}
      </div>
    )
  }
  
  