import * as React from "react";
import {connect} from "react-redux";
import {Home} from "./Home";
import {setDirectionsTH, subscribeSenderTH} from "../../Redux/Reducers/AppReducer";


const mapStateToProps = (state) => {
    return {
        userSubscribe: state.app.userSubscribe,
        authorized: state.app.authorized,
        userEmail: state.app.userEmail
    }
}

class HomeContainer extends React.Component {
    componentDidMount() {
        this.props.setDirectionsTH()
    }
    render() {
        return <Home onSubscribe={this.props.subscribeSenderTH} userEmail = {this.props.userEmail} authorized = {this.props.authorized} userSubscribe = {this.props.userSubscribe} isMobile={this.props.isMobile}/>
    }
}

export default connect(mapStateToProps, {setDirectionsTH, subscribeSenderTH})(HomeContainer)