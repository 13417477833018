import React, {useEffect, useState} from "react";
import s from "./DropList.module.css"
import listHiddenIcon from "../../Assets/Icons/listHiddenIcon.svg"
import listVisibleIcon from "../../Assets/Icons/listVisibleIcon.svg"


export const DropList = ({listName, list, startValue, onSelect, margin, isChangeable, id, activeId, setActiveId, startIsPriority}) => {
    const [listArray, setListArray] = useState(list)
    const [selectedValue, setSelectedValue] = useState(null)
    const [listItems, setListItems] = useState(undefined)
    const [searchText, setSearchText] = useState("")

    const [isDropped, toggleIsDropped] = useState(false)

    // useEffect(() => {
    //     if(startIsPriority && startValue) {
    //         setSelectedValue(startIsPriority)
    //     }
    // }, [startValue])

    useEffect(() => {
        toggleIsDropped(id === activeId)
    }, [activeId])

    useEffect(() => {
        setListArray(list)
        if (isChangeable) {
            setSelectedValue(null)
            onSelect(0)
        }
    }, [list])

    const dropFunk = (ul) => {
        if (isDropped) {
            ul.style.height = "0"
            ul.style.padding = "0"
            toggleIsDropped(false)
        } else {
            ul.style.height = "auto"
            ul.style.padding = "25px 19px"
            toggleIsDropped(true)
        }
    }

    useEffect(() => {
        let listName = document.getElementById(id)
        let dropIcon = document.getElementById(`icon${id}`)
        let list = document.querySelector(`.list${id}`)
        const onToggle = () => {
            dropFunk(list)
        }
        listName.addEventListener("click", onToggle)
        dropIcon.addEventListener("click", onToggle)

        return () => {
            listName.removeEventListener("click", onToggle)
            dropIcon.removeEventListener("click", onToggle)
        }

    }, [])

    useEffect(() => {
        let list = document.querySelector(`.list${id}`)
        if (!isDropped) {
            list.style.height = "0"
            list.style.padding = "0"
        } else {
            list.style.height = "auto"
            list.style.padding = "25px 19px"
        }
    }, [isDropped])

    const onSelectVariant = (varName, varId) => {
        setSelectedValue(varName)
        onSelect(varId)
        setActiveId(0)
    }

    const onChangeSearchText = (e) => {
        setSearchText(e.target.value)
        if (e.target.value === "") {
            setListArray(list)
        } else {
            let findItems = list.map((item) => {
                let itemName = item.name.toUpperCase()
                let findName = e.target.value.toUpperCase()
                if (itemName.includes(findName)) {
                    return item
                }
            })
            let newItems = findItems.filter((i) => i !== undefined)
            setListArray(newItems)
        }
    }

    useEffect(() => {
        const itemsList = listArray.map((e) => <div onClick={() => onSelectVariant(e.name, e.id)}
                                                    className={s.listItem}
                                                    key={e.id}>{e.name}</div>)
        setListItems(itemsList)
    }, [listArray])

    return (
        <div className={s.wrapper} style={{margin: `${margin}`}}>
            <div className={s.listName} onClick={(e) => {
                setActiveId(id)
                e.stopPropagation()
            }}><p
                id={id}>{!startIsPriority ? selectedValue || startValue || listName : startValue || selectedValue || listName}</p>
                <img id={`icon${id}`} src={id === activeId ? listVisibleIcon : listHiddenIcon} alt=""/></div>
            <div onClick={(e) => {
                e.stopPropagation()
            }} className={`${s.listBar} list${id}`}>
                <div className={s.searchItemBar}>
                    <input onChange={onChangeSearchText} value={searchText} type="text"/>
                </div>
                <div className={s.selectItemBar}><p>{selectedValue || startValue || "Не выбрано"}</p>
                    <hr size={2} color="#B358C2"/>
                </div>
                <ul className={s.list} id={listName}>{listItems?.length > 0 ? listItems :
                    <p className={s.notFoundText}>Не найдено</p>}</ul>
            </div>
        </div>
    )
}