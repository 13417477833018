import { useState } from "react"
import { useEffect } from "react"
import s from "./Resumes.module.css"

import { getResumes, getAdminVP, getDirections,serverLink, getFullResume } from "../../../DAL/ApiRequests"
import { Button } from "../../../Components/Button/Button"
import AdminTable from "../../../Components/AdminTable/AdminTable"
import { useNavigate } from "react-router-dom"
import { Select } from "../../../Components/EditInputKit/EditInputKit"
import TextModal from "../../../Components/TextModal/TextModal"


export const NETWORKS = ["","WhatsApp","Telegram","Viber"]
export const CURENCYS = ["","Сомони","Рублей","USD"] // TODO ЖЕСТЬ КОСТЫЛЬ

export const EDUCATIONS = ["Не указывать","Очная","Заочная","Очно-заочная","Вечерняя"]


export const DataRow = ({title,data}) =>{
    return (
        <div className={s.row}>
            <b>
                
                {title}
                
            </b>
            <p>
                {data}
            </p>
        </div>
    )
}

export const DataManyText = ({title,data}) =>{
    return (
        <div className={s.manyText}>
            <b>
                {title}   
            </b>
            <p className={s.cont}>
                {data ? data.split("\n").map(r=>(
                    <p>
                        {r}
                        <br/>
                    </p>
                )) : data}
            </p>
        </div>
    )
}



const Resumes = () =>{

    const [resumes,setResumes] = useState([])
    const [onluUnreaded,setOnluUnreaded] = useState(true)
    const [directionId, setDirectionId] = useState({id:1});
    const [directions, setDirections] = useState([]);

    const [fullResume,setFullResume] = useState({})

    const [page,setPage] = useState(1)
    const [count,setCount] = useState(10)
    const [totalCount, setTotalCount] = useState(0);

    const [openModal,setOpenModal] = useState(false)
    const navigate = useNavigate()

    const resumesTemplate = [
        {
            title:"ФИО",
            renderCell:(item)=>(<p>{item.last_name} {item.first_name} {item.patronymic}</p>)
        },
        {
            title:"Документ",
            renderCell:(item)=>{
                if(!item.id_document)
                    return (<p>Нет документа</p>)
                return (<a href={`${serverLink}/get_resume?id_file=${item.id_document}`} >Скачать</a>)
            }
        },
        {
            title:"Ссылка",
            renderCell:(item) =>{
                if(item.text_resume != null)
                    return <a href={item.text_resume}>Ссылка</a>
                else
                    return <p>Нет ссылки</p>
            }
        },
        {
            title:"Вакансия / Стажировка",
            renderCell:(item)=>{
                if(item.id_vacancy_probation != null)
                    return (
                    
                    <Button variant={1}
                            backColor={"white"}
                            textColor="black"
                            btnText={"Перейти"}
                            onClick={()=>{
                                getAdminVP(item.id_vacancy_probation).then(r=>{
                                    if(r.status == "ok"){
                                        if(r.data.program_for != undefined)
                                            navigate(`/admin/probation?id=${item.id_vacancy_probation}`)
                                        else
                                            navigate(`/admin/vacancy?id=${item.id_vacancy_probation}`)
                                    }else{
                                        alert(r.message)
                                    }
                                })
                            }}/>)
                else 
                    return "Нет"}
        },
        {
            title:"Расширенный вид",
            renderCell:(item)=>{
                    return item.short ? (
                    <Button variant={1}
                            backColor={"white"}
                            textColor="black"
                            btnText={"Перейти"}
                            onClick={()=>{
                                getFullResume(item.id).then(r=>{
                                    if(r.status == "ok")
                                        setFullResume(r.data)
                                })
                               setOpenModal(true)
                            }}/>) : "Нет"
                }
        },
        {
            title:"Город",
            key:"city"
        }
    ]


    const getDirectionsString = (directionsArray) => {
        var directionsDict = {}
        for(var d of directions){
            directionsDict[d.id] = d.name
        }
        var result = ""
        directionsArray.forEach(d=>{
            result += `${directionsDict[d]}; `
        })

        return result
    }

    const onGetResumes = (r) => {
        if(r.status == "ok"){
            setResumes(r.data.resums.reverse())
        }
    }

    useEffect(() => {
        getDirections(directionId["id"]).then(r=>{
            setDirections(r.data)
        })
    }, []);

    useEffect(()=>{
        getResumes(directionId.id).then(onGetResumes)
    },[directionId])

    

    return (
        <div className={s.container}>
            <div className={s.df}>
                <p className={s.title}>
                    Направление
                </p>
                <Select val={directionId} setter={setDirectionId}
                        k={"id"} values={directions}/>
                
            </div>
            
            <div className={s.scroll}>
                <AdminTable rows={resumes}
                            template={resumesTemplate}
                            isPagination={false}/>
            </div>
            <TextModal open={openModal}
                       onClose={
                        ()=>{
                            setOpenModal(false)
                        }}
                        full>
                <div className={s.col}>
                    <DataRow title={"Фамилия"} data={fullResume.last_name}/>
                    <DataRow title={"Имя"} data={fullResume.first_name}/>
                    <DataRow title={"Отчество"} data={fullResume.patronymic}/>
                    <DataRow title={"Семейное положение"} data={fullResume.family_status ? "В браке" : "Нет брака"}/>
                    <DataRow title={"Должность"} data={fullResume.position}/>
                    <DataRow title={"Гражданство"} data={fullResume.citizenship}/>
                    <DataRow title={"Второе гражданство"} data={fullResume.second_citizenship}/>
                    <DataRow title={"Город"} data={fullResume.city}/>
                    <DataRow title={"Готовность к перезду"} data={fullResume.ready_to_move ? "Да" : "Нет"}/>
                    <DataRow title={"Страна/Город для перезда"} data={fullResume.target_of_move }/>
                    <DataRow title={"Готовность к командировкам"} data={fullResume.ready_to_trips ? "Да" : "Нет"}/>
                    <DataRow title={"Желаемая зарплата"} data={fullResume.desired_salary}/>
                    <DataRow title={"Валюта"} data={fullResume.currency? CURENCYS[fullResume.currency] : null}/>
                    <DataRow title={"Телефон"} data={fullResume.phone}/>
                    <DataRow title={"Привязанные к телефону сети"} data={fullResume.social_network_on_phone ? fullResume.social_network_on_phone.map(s=>(NETWORKS[s])).join("; ") : null}/>
                    <DataRow title={"Емейл"} data={fullResume.email}/>
                    <DataRow title={"Направления"} data={getDirectionsString(fullResume.directions || [])}/>
                    <DataRow title={"Образование"} data={fullResume.educations ? "" : "Нет"}/>
                    {fullResume.educations ? fullResume.educations.map(e=>(
                        <div className={s.block}>
                            <DataRow title={"Название учереждения"} data={e.name}/>
                            <DataRow title={"Год окончания обучения"} data={e.date_end}/>
                            <DataRow title={"Институт/ Факультет"} data={e.name_faculty}/>
                            <DataRow title={"Направления обучения"} data={e.direction}/>
                            <DataRow title={"Форма обучения"} data={e.form ? EDUCATIONS[e.form] : null}/>
                        </div>    
                    )): null}
                    <DataRow title={"Опыт работы"} data={fullResume.experience ? "" : "Нет"}/>
                    {fullResume.experience ? fullResume.experience.map(e=>(
                        <div className={s.block}>
                            <DataRow title={"Название организации"} data={e.name}/>
                            <DataRow title={"Должность"} data={e.position}/>
                            <DataRow title={"Старт работы - дата"} data={e.start_date}/>
                            <DataRow title={"Конец работы - дата"} data={e.end_date}/>
                            <DataManyText title={"Описание работы"} data={e.description}/>
                        </div>    
                    )): null}
                    <DataRow title={"Языки"} data={fullResume.languages}/>
                    <DataManyText title={"О себе"} data={fullResume.text_resume}/>



                </div>

            </TextModal>
            
        </div>
    )
}


export default Resumes