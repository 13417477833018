import * as React from "react";
import {connect} from "react-redux";
import {RegBar} from "./RegBar";
import {authorizationTH, registrationTH, setConfirmEmailInProgressAC} from "../../Redux/Reducers/AppReducer";


const mapStateToProps = (state) => {

    return {
        regError: state.app.errors.regError,
        confirmEmailInProgress: state.app.confirmEmailInProgress
    }
}

class RegBarContainer extends React.Component {

    goReg = (name, firstName, patronymic, email, phone, genderId, birthDate, password, subcription, consent) => {
        this.props.registrationTH(name, firstName, patronymic, email, phone, genderId, birthDate, password, subcription, consent)
    }
    goAuth = (login, password) => {
        return this.props.authorizationTH(login, password)
    }
    toggleShowConfirmEmailBar = (bool) => {
        this.props.setConfirmEmailInProgressAC(bool)
    }

    render() {
        return <RegBar goAuth = {this.goAuth} confirmEmailInProgress={this.props.confirmEmailInProgress} toggleShowConfirmEmailBar = {this.toggleShowConfirmEmailBar} regError = {this.props.regError} isMobile = {this.props.isMobile} goReg={this.goReg} onClose={this.props.onClose}/>
    }
}

export default connect(mapStateToProps, {registrationTH, setConfirmEmailInProgressAC, authorizationTH})(RegBarContainer)