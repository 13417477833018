import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import store from "./Redux/ReduxStore";
import AppContainer from "./AppContainer";
import {BrowserRouter} from "react-router-dom";

ReactDOM.render(
        <Provider store={store}>
            <React.StrictMode>
                <BrowserRouter basename="/">
                <AppContainer/>
                </BrowserRouter>
            </React.StrictMode>
        </Provider>,
    document.getElementById('root')
);


reportWebVitals();
