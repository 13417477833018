import React, {useEffect, useState} from "react";
import s from "./Vacancies.module.css";
import img1 from "../../Assets/SlidersImages/corp_1.png"
import {Heading} from "../../Components/Heading/Heading";
import EmailSubscribeBlockContainer from "../../Components/EmailSubscribeBlock/EmailSubscribeBlockContainer";
import SendResumeBarContainer from "../../Components/SendResumeBar/SendResumeBarContainer";
import SearchBarContainer from "../../Components/SearchBar/SearchBarContainer";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
import DocumentMeta from "react-document-meta";

export const Vacancies = ({isMobile, userSubscribe, authorized}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const meta = {
        description: 'Актуальные вакансии в IT, HR, PR, Продажи, Финансы и Бухгалтерия и других направлениях! Найдите работу своей мечты в МегаФон!',
        }
    return (
        <div className={`${s.wrapper}`}>
            <DocumentMeta {...meta}/>
            <div className={s.headBar}>
                <div className={s.headSubWrapp}>
                    <div className={s.headText}>
                        <Heading headText="Выбери работу, которая тебе по душе!"/>

                    </div>
                    <div className={s.headImgBox}>
                        <img src={img1} alt="happyStaff"/>
                    </div>
                </div>
            </div>
            <div className={s.breadCrumbsBar}><BreadCrumbs steps = {[["Главная","/"],["Вакансии"]]}/></div>
            <div className={s.searchProgramBar}>
                <SearchBarContainer isMobile={isMobile}
                                    listType={0}/> {/*listType определяет тип контента, где 0 - вакансии, 1 - стажировки*/}
            </div>

            <div className={s.activityBar}>
                <div className={s.activityBarHead}
                     style={{paddingLeft: !isMobile ? "20%" : "5%", marginBottom: !isMobile ? "70px" : "0"}}>{/*Не в inline формате стили не применяются*/}
                    <Heading width={!isMobile ? "65%" : "90%"} color="#731982"
                             headText="Не нашли подходящую вакансию?"/>
                </div>
                <div className={s.subscribeBar}>
                    {!userSubscribe && authorized ?
                        <EmailSubscribeBlockContainer isDark={true} isMobile={isMobile}/> : null}
                </div>
                <div className={s.sendResumeBar}>
                    <SendResumeBarContainer tone="dark" isMobile={isMobile}/>
                </div>
            </div>

            <div className={s.setResumeBar}>
            </div>
        </div>
    )
}