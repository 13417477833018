import s from "./SlideCard.module.css";
import {Button} from "../../Button/Button";
import {NavLink, useNavigate} from "react-router-dom";


import image1 from "./../../../Assets/CardsImages/1.png"
import image2 from "./../../../Assets/CardsImages/2.png"
import image3 from "./../../../Assets/CardsImages/3.png"
import image4 from "./../../../Assets/CardsImages/4.png"
import image5 from "./../../../Assets/CardsImages/5.png"
import image6 from "./../../../Assets/CardsImages/6.png"

var IMAGES = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
]


export const SlideCard = ({id, headText, text, btnVariant, btnOnClick, btnIsLink, index}) => {
    const link = `vakansiya/${id}`
    let maxSymbolCount = 140
    let currentText = "Узнай подробнее, нажав на кнопку ниже!"
    if (text?.length > 0 && text?.length <= maxSymbolCount) {
        currentText = text
    } else if (text?.length > maxSymbolCount) {
        let rightText = ""
        for (let i = 0; i <= maxSymbolCount; i++) {
            rightText += text[i]
        }
        let cutText = rightText.split(" ")
        cutText[cutText.length - 1] = "..."
        currentText = cutText.join(" ")
    }
    return (
        <div id={id} className={s.card}>
            <div className={s.imgBar}>
                <div className={s.hoverHead}>
                    <p className={s.hoverHeadTitle}>{headText}</p>
                </div>
                <img src={IMAGES[index]} alt=""/>
                <div className={s.sliderHeading}><p>{headText}</p></div>
            </div>
            <div className={s.slideText}><p>{currentText}</p></div>
            <hr size={2} color={"E5E5E5"}/>
            <div className={s.btnBar}>{btnIsLink ?
                <NavLink to={link}><Button onClick={btnOnClick} btnText="Подробнее" variant={btnVariant}/></NavLink> :
                <Button onClick={btnOnClick} btnText="Подробнее" variant={btnVariant}/>}</div>
        </div>
    )
}
