import React, {Component} from "react";
import s from "./ConditionsSlider.module.css";
import Slider from "react-slick";
import next from "../../../../Assets/Icons/showNextSlide.svg";
import previous from "../../../../Assets/Icons/showPreviousSlide.svg";

import card1 from "../../../../Assets/SlidersImages/card_1.png"
import card2 from "../../../../Assets/SlidersImages/card_2.png"
import card3 from "../../../../Assets/SlidersImages/card_3.png"
import card4 from "../../../../Assets/SlidersImages/card_4.png"
import card5 from "../../../../Assets/SlidersImages/card_5.png"
import card6 from "../../../../Assets/SlidersImages/card_6.png"
import card7 from "../../../../Assets/SlidersImages/card_7.png"



function SampleNextArrow(props) {
    const {className, style, onClick, subOnClick} = props;
    return (
        <div style={{...style}} onClick={() => {
            onClick()
            subOnClick()
        }} className={`${className} ${s.nextArrow}`}>
            <img src={next} alt=""/>
        </div>
    );
}

function SamplePrevArrow(props) {
    const {className, style, onClick, subOnClick} = props;
    return (
        <div style={{...style}} onClick={() => {
            onClick()
            subOnClick()
        }} className={`${className} ${s.prevArrow}`}>
            <img src={previous} alt=""/>
        </div>
    );
}

export default class ConditionsSlider extends Component {

    resizeMethod = () => {
        this.setState({screenWidth: window.innerWidth})
        if (window.innerWidth < 1550 && window.innerWidth > 900) {
            this.setState({screenWidth: window.innerWidth - 200})
        }
        if (window.innerWidth > 1550) {
            this.setState({screenWidth: window.innerWidth})
        }
        if (window.innerWidth <= 900) {
            this.setState({screenWidth: window.innerWidth})
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.resizeMethod)
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeMethod)
    }

    constructor(props) {
        super(props);
        this.state = {
            activeItem: 2,
            screenWidth: window.innerWidth,
            viewSlidesCount: 5,
            isMobile: this.props.isMobile,
        }
    }

    onGoPreviousSlide = (index) => {
        let nextActive = index - 1
        if (nextActive) {
            this.setState({activeItem: nextActive})
        }

    }

    onGoNextSlide = (index) => {
        let nextActive = index + 2
        if (nextActive <= 6) {
            this.setState({activeItem: nextActive})
        } else if (nextActive >= 7) {
            this.setState({activeItem: nextActive === 7 ? 0 : 1})
        }
    }

    render() {
        const settings = {
            arrows: !this.state.isMobile,
            lazyLoad: false,
            infinite: true,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 1,
            adaptiveHeight: false,
            nextArrow: <SampleNextArrow subOnClick={this.onGoNextSlide}/>,
            prevArrow: <SamplePrevArrow subOnClick={this.onGoPreviousSlide}/>,
            afterChange: this.onGoNextSlide,
            autoplay: true,
            autoplaySpeed: 4000,
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 1540,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 980,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 1,
                        arrows: false
                    }
                }
            ]

        };
        return (
            <div style={{
                maxWidth: `${this.state.screenWidth - 20}px`,
                position: "relative",
                height: "410px",
                margin: "0 auto"
            }}>
                <Slider {...settings}>
                    <div>
                        <div style={{marginBottom: "10px"}}>
                            <SlideCard isMobile={this.state.isMobile} isActive={this.state.activeItem === 0} img={card1}
                                       text="Компенсация за связь"/>
                        </div>
                    </div>
                    <div>
                        <div style={{marginBottom: "10px"}}>
                            <SlideCard isMobile={this.state.isMobile} isActive={this.state.activeItem === 1} img={card2}
                                       text="Компенсация спорта"/>
                        </div>
                    </div>
                    <div>
                        <div style={{marginBottom: "10px"}}>
                            <SlideCard isMobile={this.state.isMobile} isActive={this.state.activeItem === 2} img={card3}
                                       text="Стабильная ЗП"/>
                        </div>
                    </div>
                    <div>
                        <div style={{marginBottom: "10px"}}>
                            <SlideCard isMobile={this.state.isMobile} isActive={this.state.activeItem === 3} img={card4}
                                       text="Оплачиваемая стажировка"/>
                        </div>
                    </div>
                    <div>
                        <div style={{marginBottom: "10px"}}>
                            <SlideCard isMobile={this.state.isMobile} isActive={this.state.activeItem === 4} img={card5}
                                       text="Премии"/>
                        </div>
                    </div>
                    <div>
                        <div style={{marginBottom: "10px"}}>
                            <SlideCard isMobile={this.state.isMobile} isActive={this.state.activeItem === 5} img={card6}
                                       text="ДМС(страховка)"/>
                        </div>
                    </div>
                    <div>
                        <div style={{marginBottom: "10px"}}>
                            <SlideCard isMobile={this.state.isMobile} isActive={this.state.activeItem === 6} img={card7}
                                       text="Корпоративные мероприятия"/>
                        </div>
                    </div>
                </Slider>
                <div style={{
                    position: "absolute",
                    bottom: "60px",
                    display: "flex",
                    justifyContent: "center",
                    width: "100%"
                }}>
                    <div style={{
                        display: "flex",
                        backgroundColor: "rgba(0, 184, 86, 0.5)",
                        padding: "5px",
                        borderRadius: "10px"
                    }}>
                        <div className={this.state.activeItem === 0 ? s.activePage : s.pageElement}/>
                        <div className={this.state.activeItem === 1 ? s.activePage : s.pageElement}/>
                        <div className={this.state.activeItem === 2 ? s.activePage : s.pageElement}/>
                        <div className={this.state.activeItem === 3 ? s.activePage : s.pageElement}/>
                        <div className={this.state.activeItem === 4 ? s.activePage : s.pageElement}/>
                        <div className={this.state.activeItem === 5 ? s.activePage : s.pageElement}/>
                        <div className={this.state.activeItem === 6 ? s.activePage : s.pageElement}/>
                    </div>
                </div>
            </div>
        );
    }
}

export const SlideCard = ({img, text, isActive, isMobile}) => {
    return (<div className={`${s.slideWrapp}`}
    >
        <p>{text}</p>
        <div style={{margin: "0 auto"}} className={s.slideImg}>
            <img src={img} alt="card"/>
        </div>
    </div>)
}