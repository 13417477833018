import React, { useEffect, useState } from "react";
import s from "./Menu.module.css";
import logo from "../../Assets/Logo/logo.png";
import { Button } from "../Button/Button";
import menuIcon from "../../Assets/Icons/menuIcon.svg";
import AuthBarContainer from "../AuthBar/AuthBarContainer";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router";
import SendResumeBarContainer from "../SendResumeBar/SendResumeBarContainer";
import RecoveryPasswordContainer from "../RecoveryPassword/RecoveryPasswordContainer";
import CreateResumeBarContainer from "../CreateResumeBar/CreateResumeBarContainer";
import TestModal from "../TestModal";

export const Menu = ({ authorized, onSignOut, userPhoto, path }) => {
  const [authModalIsOpen, setAuthModalIsOpen] = useState(false);
  const [isMobile, toggleIsMobile] = useState(false);
  const [menuIsOpen, toggleMenuIsOpen] = useState(false);
  const [recoveryPassModalIsOpen, toggleRecoveryPassModalIsOpen] =
    useState(false);

  const closeAuthModal = () => {
    setAuthModalIsOpen(false);
  };
  const onCloseSendResumeModal = (status) => {
    setSendResumeModalIsOpen(false);
  };
  const onCloseRecoveryPassModal = (status) => {
    toggleRecoveryPassModalIsOpen(false);
  };
  const [sendResumeModalIsOpen, setSendResumeModalIsOpen] = useState(false);

  useEffect(() => {
    toggleMenuIsOpen(false);
  }, [path]);

  useEffect(() => {
    toggleIsMobile(window.innerWidth <= 900);
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 900) {
        toggleIsMobile(false);
      } else {
        toggleIsMobile(true);
      }
    });
  }, []);

  useEffect(() => {
    if (isMobile) {
      let mobileMenu = document.querySelector(".mobileMenu");
      let burger = document.querySelector(".burger");
      if (!menuIsOpen) {
        mobileMenu.style.height = "0px";
      }
      burger.addEventListener("click", () => {
        if (menuIsOpen) {
          mobileMenu.style.height = "0px";
        } else {
          mobileMenu.style.height = "calc(125vh - 84px)";
        }
      });
    }
  }, [isMobile, menuIsOpen]);

  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/`;
    navigate(path);
  };
  const goOut = () => {
    onSignOut().then((result) => {
      if (result === "ok") {
        routeChange();
      }
    });
  };

  return (
    <div className={s.menu}>
      <div className={s.leftPart}>
        {isMobile ? (
          <div
            style={
              isMobile
                ? { flex: "1", paddingLeft: "2%", cursor: "pointer" }
                : null
            }
          >
            <img
              className="burger"
              onClick={() => toggleMenuIsOpen(!menuIsOpen)}
              src={menuIcon}
              alt="menuIcon"
            />
          </div>
        ) : null}
        <div
          style={
            isMobile
              ? {
                  flex: "1",
                  display: "flex",
                  justifyContent: "end",
                  paddingRight: "2%",
                }
              : null
          }
          className={s.logo}
        >
          <NavLink to="/">
            <img src={logo} alt="" />
          </NavLink>
        </div>
        {!isMobile && authorized ? (
          <div className={s.createResumeBtn}>
            <Button
              onClick={() => setSendResumeModalIsOpen(true)}
              variant={4}
              btnText="Создай свое резюме!"
            />
          </div>
         ) : null}
      </div>
      <div
        style={
          isMobile
            ? {
                backgroundColor: "white",
                boxShadow: "4px 0px 4px rgba(0, 0, 0, 0.15)",
                flexDirection: "column",
                position: "fixed",
                top: "84px",
                zIndex: "998",
                height: "0",
                justifyContent: "start",
                alignItems: "start",
              }
            : null
        }
        className={`${s.rightPart} mobileMenu`}
      >
        {isMobile && authorized ? (
          <div className={s.createResumeBtn}>
            <NavLink
              onClick={() => toggleMenuIsOpen(false)}
              className="navLink"
              to="/profil"
            >
              <img className={s.userPhotoMobile} src={userPhoto} alt="" />
            </NavLink>
            <Button
              onClick={() => setSendResumeModalIsOpen(true)}
              width="85%"
              variant={4}
              btnText="Создай свое резюме!"
            />
          </div>
        ) : null}
        <div
          style={
            isMobile
              ? { display: "flex", flexDirection: "column", flex: "1" }
              : null
          }
          className={s.links}
        >
          <NavLink
            onClick={() => toggleMenuIsOpen(false)}
            className={({ isActive }) => (isActive ? s.activeLink : "")}
            style={
              isMobile
                ? {
                    marginLeft: "8%",
                    padding: "0",
                    marginBottom: "3%",
                    maxWidth: "100px",
                    marginTop: isMobile && !authorized ? "35px" : null,
                  }
                : null
            }
            to="/"
          >
            Главная
          </NavLink>
          <NavLink
            onClick={() => toggleMenuIsOpen(false)}
            className={({ isActive }) => (isActive ? s.activeLink : "")}
            to="/vakansii"
            style={
              isMobile
                ? {
                    marginLeft: "8%",
                    padding: "0",
                    marginBottom: "3%",
                    maxWidth: "100px",
                  }
                : null
            }
          >
            Вакансии
          </NavLink>
          <NavLink
            onClick={() => toggleMenuIsOpen(false)}
            className={({ isActive }) => (isActive ? s.activeLink : "")}
            style={
              isMobile
                ? {
                    marginLeft: "8%",
                    padding: "0",
                    marginBottom: "3%",
                    maxWidth: "100px",
                  }
                : null
            }
            to="/stazhirovki"
          >
            Стажировка
          </NavLink>
          <NavLink
            onClick={() => toggleMenuIsOpen(false)}
            className={({ isActive }) => (isActive ? s.activeLink : "")}
            style={
              isMobile
                ? {
                    marginLeft: "8%",
                    padding: "0",
                    marginBottom: "12%",
                    maxWidth: "100px",
                  }
                : null
            }
            to="/o-kompanii"
          >
            О компании
          </NavLink>
        </div>
        {!authorized ? (
          <div
            style={
              isMobile
                ? {
                    flex: "0",
                    marginLeft: "8%",
                    marginBottom: "5%",
                    width: "100%",
                  }
                : null
            }
            className={s.entryExitBtn}
            onClick={() => setAuthModalIsOpen(true)}
          >
            <Button
              variant={4}
              btnText="Вход"
              width={isMobile ? "35%" : "100%"}
            />
          </div>
        ) : (
          <div
            style={
              isMobile
                ? {
                    flex: "1",
                    marginLeft: "8%",
                    marginBottom: "5%",
                    width: "100%",
                  }
                : null
            }
            className={s.entryExitBtn}
            onClick={() => {
              goOut();
              setAuthModalIsOpen(true);
            }}
          >
            <Button
              variant={4}
              btnText="Выход"
              width={isMobile ? "35%" : "100%"}
            />
          </div>
        )}
        {!authorized ? (
          <div
            style={isMobile ? { marginLeft: "8%", width: "100%" } : null}
            className={s.regBtn}
          >
            <NavLink
              className={({ isActive }) => (isActive ? s.activeLink : "")}
              to="/registracziya"
            >
              <Button
                onClick={() => toggleMenuIsOpen(false)}
                width={isMobile ? "90%" : "100%"}
                variant={6}
                btnText="Регистрация"
              />
            </NavLink>
          </div>
        ) : (
          <div className={s.userPhoto}>
            <NavLink to="/profil">
              <img src={userPhoto} alt="photo" />
            </NavLink>
          </div>
        )}
      </div>
      {sendResumeModalIsOpen ? (
        <CreateResumeBarContainer
          isEditMode={false}
          isModal={true}
          isMobile={isMobile}
          onClose={onCloseSendResumeModal}
        />
      ) : null}
      {authModalIsOpen && !authorized ? (
        <AuthBarContainer
          onOpenRecovery={() => toggleRecoveryPassModalIsOpen(true)}
          onClose={closeAuthModal}
        />
      ) : null}
      {recoveryPassModalIsOpen ? (
        <RecoveryPasswordContainer onClose={onCloseRecoveryPassModal} />
      ) : null}
      
    </div>
  );
};
