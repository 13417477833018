
import thunkMiddleware from "redux-thunk"
import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import AppReducer from "./Reducers/AppReducer";

const enchancers = [applyMiddleware(thunkMiddleware),
     // window.__REDUX_DEVTOOLS_EXTENSION__()
]

const reducers = combineReducers(
    {
        app: AppReducer
    }
)

const store = createStore(reducers, compose(...enchancers));


export default store