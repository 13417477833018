import s from "./VerticalArrayChanger.module.css"

import Modal from "../Modal/Modal"
import {Input} from "../Input/Input"

import { Button } from "../Button/Button"
import { useState } from "react"

const ElementPlate = ({text,id,edit,select}) =>{
    return (
        <div className={s.plate}>
            <p onClick={()=>{
                select(id)
            }}>{text}</p>
            <div className={s.editButton}
                onClick={() => {edit(id,text)}}>
                Ред.
            </div>
        </div>
    )
}

const VerticalArrayChanger = ({data,create,edit,select}) =>{
    const [isEdit,setIsEdit] = useState(false)
    const [isCreate,setIsCreate] = useState(false)

    const [selectedId,setSelectedId] = useState(0)

    const [modalText,setModalText] = useState("")

    const [modalIsValid,setModalIsValid] = useState(false)


    const closeModal = () => {
        setIsCreate(false)
        setIsEdit(false)
    }

    const openEdit = (id,value) =>{
        setSelectedId(id)
        setModalText(value)
        setIsEdit(true)
    }


    const openCreate = () =>{
        setModalText("")
        setIsCreate(true)
    }

    return (
    <div>
        <div className={s.container}>
            <div className={s.scroller}>
                {data.map(el => (
                    <ElementPlate text={el.name} id={el.id} edit={openEdit} select={select} />
                ))}
            </div>
            <Button btnText={"Добавить"} 
                    variant={1}
                    backColor={"#731982"}
                    textColor="white"
                    onClick = {()=>{
                        openCreate()
                    }}
                    
                    width="100%"/>
        </div>
        <Modal open = {isCreate || isEdit}
                onClose = {closeModal}>
            <div className={s.subWrapper}>
            <input type="text" className={s.input}
                    placeholder={"Введите значение"}
                    value={modalText}
                    onInput = {(ev)=>{
                        setModalText(ev.target.value)
                    }}/>
            <Button btnText={"Сохранить"} 
                    variant={1}
                    backColor={"#2CB56D"}
                    textColor="white"
                    width={"300px"}
                    onClick = {()=>{
                        if(modalText != ""){
                            if(isCreate)
                                create(modalText)
                            else if (isEdit)
                                edit(selectedId,modalText)
                            setIsCreate(false)
                            setIsEdit(false)
                        }
                    }}/>
            </div>
        </Modal>
        
    </div>
    )
}

export default VerticalArrayChanger