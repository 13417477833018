import s from './VacancyInfoBanner.module.css'
import image from "../../Assets/pageImages/VaacancyPageImages/BanerImage.png"
import star1 from "../../Assets/pageImages/VaacancyPageImages/unFeaturedStar.svg"
import star2 from "../../Assets/pageImages/VaacancyPageImages/featuredStar.png"

import { Button } from '../Button/Button'
import React from "react";
import {ToastsStore} from "react-toasts";

const VacancyInfoBanner = ({vacancyName, startSalary, endSalary, onAddToFeatured, vacancyId, shareLink, featured, authorized}) =>{

    const showToast = () => {
        ToastsStore.success("Ссылка скопирована")
    }

    const copyLink = () => {
        navigator.clipboard.writeText(shareLink)
            .then(() => {
                showToast()
            })
    }


    return (
        <div className={s.banner}>
            <div className={s.bannerImage}>
                <img src={image} alt=""/>
            </div>
            
            <div className={s.textContainer}>
                <p style = {vacancyName?.length > 60 ?{fontSize:"50px", lineHeight:"55px"}: null} className={s.bannerTitle}>{vacancyName}</p>
                <p className={s.bannerDescription}>От {startSalary} {endSalary > 0 ? `до ${endSalary}`: null} Сомони</p>
                <Button onClick={copyLink}
                        btnText={"Поделиться вакансией"}
                        textColor={"black"}
                        variant={1}/>
            </div>

            {authorized ?<div className={s.starContainer}>
                <img onClick={() => onAddToFeatured(vacancyId)} className={s.featuredStatusStar}
                     src={featured ? star2 : star1} alt=""/>
                <p className={s.featuredHead}>
                    Избранное
                </p>
            </div>: null}
        </div>
    )
}


export default VacancyInfoBanner