import s from "./MailSender.module.css"

import Modal from "../../../Components/Modal/Modal";
import { useState } from "react";


import { Row, Select, TextArea, Input, CheckBox, DateTime } from "../../../Components/EditInputKit/EditInputKit"
import { Button } from "../../../Components/Button/Button";

import { CustomDateTimePicker } from "../../../Components/DatePicker/DatePicker";
import { useEffect } from "react";

import { subscribesEmails, createSender, getSenders, deleteSender } from "../../../DAL/ApiRequests";

import AdminTable from "../../../Components/AdminTable/AdminTable";

const dataTemplate =
    {
        "datetime": new Date(),
        "header": "",
        "message": "",
        "loop": false,
        "interval": 1
    }

const loopTitles = ['','Каждый день','Каждую неделю','Каждый месяц']

const p = (v) =>{
    if(v.toString().length < 2 )
        return `0${v}`
    return v
} 

const dateForHuman= (d)=>{
    const date = new Date(d)
    return `${date.getFullYear()}-${p(date.getMonth()+1)}-${p(date.getDate())}` +
            ` ${p(date.getHours())}:${p(date.getMinutes())}`
}



const MailCard = ({data, edit,del}) =>{
    return (
        <div className={s.mailPlate}>
            <p>Статус</p>
            <p>{new Date(data.datetime) < new Date()? 
                "Отправлен" :
                "Ожидает"}</p>
            <p>Дата</p>
            <p>{dateForHuman(data.datetime) }</p>
            Повтоярется
            <p>{data.loop ? loopTitles[data.interval] : 
                "Нет"}</p>
            <Button variant={1}
                    backColor={"var(--dimGreen)"}
                    textColor="white"
                    onClick={()=>{
                        edit(data)
                    }}
                    btnText={"Подробнее"}/> 
            <Button variant={1}
                    backColor={"var(--violet)"}
                    textColor="white"
                    btnText={"удалить"}/>        
                    
        </div>
    )
}


const MailSender = () => {
    const [createMail,setCreateMail] = useState(false)
    const [editMail,    setEditMail] = useState(false)
    const [data,setData] = useState(dataTemplate)

    const [subMails,setSubMails] = useState([])
    const [senders,setSenders] = useState([])

    const getTasks = () => {
        getSenders().then(r =>{
            if(r.status == "ok"){
                setSenders([...r.data.job_with_loop,...r.data.job_not_loop])
            }else{
                alert(r.message)
            }
        })
    }

    const sendersTemplate = [
        {   
            title:"Заголовок",
            key:"header",
        },
        {   
            title:"Сообщение",
            renderCell:(item)=>(
                <Button variant={1}
                        backColor={"var(--violet)"}
                        textColor={"white"}
                        btnText={"Подробнее"}
                        onClick={()=>{
                            alert(item.message)
                        }}/>
            ),
        },
        {
            title:"Дата",
            renderCell:(item)=>{
                return `${dateForHuman(item.next_run * 1000)}`
            }
        },
        {   
            title:"Повторяется",
            renderCell:(item)=>(
                item.interval ? "Да" : "Нет"
            ),
        },
        {   
            title:"Интервал",
            renderCell:(item)=>(
                item.interval ? loopTitles[item.interval] : ""
            ),
        },
        {
            title:"",
            renderCell:(item)=>(
                <Button variant={1}
                        backColor={"#b2102f"}
                        textColor={"white"}
                        btnText={"Удалить"}
                        onClick={()=>{
                            var y = window.confirm("Вы действительно хотите удалить?") 
                            if(y){
                                deleteSender(item.id_job).then(r=>{
                                    if(r.status == "ok")
                                        getTasks()
                                    else
                                        alert(r.message)
                                })
                            }
                        }}/>
            )
        }
    ]


    const setEdit = (d) =>{
        setData(d)
        setEditMail(true)
    }

    useEffect(()=>{
        subscribesEmails().then(r=>{
            if(r.status == "ok"){
                setSubMails(r.data.emails)
            }
            else
                setSubMails(["Ошибка при загрузке почт"])
        })
        getTasks()
    },[])



     return (
         <div className={s.container}>
            
                    <AdminTable rows={senders}
                                template={sendersTemplate}
                                isPagination={false}/>

                    <div style={{zoom:2}}>
                        <Button variant={1}
                            backColor={"var(--dimGreen)"}
                            textColor={"white"}
                            btnText="Создать"
                            onClick={()=>{
                                setData(dataTemplate)
                                setCreateMail(true)
                            }} />
                    </div>
                    <p className={s.mt10}>Подписанные</p>
                    <div>
                        { subMails.map((f) => (
                            <div className={`${s.mailPlate} ${s.mt10} ${s.mb10}`}>
                                <p>{f.email}</p>
                            </div>
                        ))}
                    </div>
                 
             
            
             <Modal open={createMail | editMail} onClose={()=>{
                    setCreateMail(false)
                    setEditMail(false)
             }}>
                 <div className={`${s.plate} ${s.modalBody}`} >
                    <Row title={"Заголовок"}>
                        {createMail ? (<Input val={data} setter={setData} k={"header"}/>): 
                                      (<p>{data["header"]}</p>)}
                    </Row>
                    <Row title={"Содержание"}>
                        {createMail ? (<TextArea val={data} setter={setData} k={"message"}/>): 
                                      (<p>{data["header"]}</p>)}
                    </Row>
                    <Row title={"Дата и время"}>
                    {createMail ? (<CustomDateTimePicker value={data["datetime"]}
                                              onChange={(val)=>{
                                                var eventVal = data
                                                eventVal["datetime"] = val
                                                setData({...eventVal})
                                              }}/>): 
                                      (<p>{dateForHuman(data["datetime"]) }</p>)}
                    </Row>
                    <Row title={"Повторять"}>
                        
                        {createMail ? (<CheckBox val={data} setter={setData} k={"loop"}/>): 
                                      data.loop ? (<p>Повторяется {loopTitles[data.interval]}</p>) : <></>}
                    </Row>
                    {data.loop === true  && createMail == true? (
                        <Row title={"Интервал"}>
                            <Select val={data} 
                                    setter={setData} 
                                    k={"interval"}
                                    values={[{name:"День",id:1},{name:"Неделя",id:2},{name:"Месяц",id:3}]}/>
                            
                        </Row>
                    ) : (<></>)}
                    <p className={s.mt10}></p>
                    {createMail ? (
                        <Button variant={1}
                            backColor={"var(--dimGreen)"}
                            textColor={"white"}
                            btnText="Создать"
                            onClick={()=>{
                                var sendData = data
                                sendData.datetime = dateForHuman(sendData.datetime)
                                sendData.message.replace("\n","<br>")
                                createSender(sendData).then(r=>{
                                    if(r.status == "ok"){
                                        //GET
                                        alert("Успешно создано")
                                        setCreateMail(false)
                                        getTasks()
                                    }else{
                                        alert(r.message)
                                    }
                                })
                                }}/> )
                        : <></>}

                 </div>
             </Modal>
         </div>
     )
};

export default MailSender;