import s from "./CreateResumeBar/CreateResumeBar.module.css";
import close from "../Assets/Icons/close-outline.svg";
import TestSingleAnswer from "./TestSingleAnswers/TestSingleAnswer";
import TestMultipleAnswer from "./TestSingleAnswers/TestMultipleAnswers";
import TestTextAnswer from "./TestSingleAnswers/TestTextAnswer";
import { Button } from "./Button/Button";
import { useState } from "react";
import { answerOnTest } from "../DAL/ApiRequests";

const TestModal = ({ onClose, test }) => {
  const [answers, setAnswers] = useState({});

  const setAnswer = (answer, id_quest) => {
    setAnswers((a) => {
      a[id_quest] = answer;
      return { ...a };
    });
  };

  const validateAnswers = () => {
    var isValid = true;
    for (var q of test.questions) {
      if (answers[q.id]) if (answers[q.id].length == 0) isValid = false;
    }

    console.log("isValid");
    console.log(isValid);
    return isValid;
  };

  return test ? (
    <div
      onClick={(e) => {
        onClose();
      }}
      className={s.wrapper}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
          // onClose();
        }}
        className={s.subWrapper}
        style={{display:"flex",flexDirection:"column",gap:"20px"}}
      >
        <img
          className={s.closeIcon}
          alt="close"
          onClick={() => {
            onClose();
          }}
          src={close}
        />
        <p className={s.head} style={{paddingBottom:"20px"}}>{test.name}</p>
        {test.questions.map((q, i) => (
          <div>
            <p >{q.title}</p>
            {q.type == "single" ? (
              <TestSingleAnswer
                answers={q.answers}
                onChange={(ans) => {
                  setAnswer(ans, q.id);
                }}
              />
            ) : q.type == "multiple" ? (
              <TestMultipleAnswer
                answers={q.answers}
                onChange={(ans) => {
                  setAnswer(ans, q.id);
                }}
              />
            ) : (
              <TestTextAnswer
                onChange={(ans) => {
                  setAnswer(ans, q.id);
                }}
              />
            )}
          </div>
        ))}

        <Button
          variant={1}
          backColor={validateAnswers() ? "var(--dimGreen)" : "var(--skyGrey)"}
          textColor={"white"}
          onClick={() => {
            if (validateAnswers()) {
              const resultAnswers = Object.keys(answers).map((a) => ({
                id: a,
                answer: answers[a],
              }));
              answerOnTest(parseInt(test.id), resultAnswers);
              onClose()
            }
          }}
          btnText="Подтвердить"
        />
      </div>
    </div>
  ) : null;
};

export default TestModal;
