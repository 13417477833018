import React, {useEffect} from "react";
import s from "./Registration.module.css"
import RegBarContainer from "../../Components/RegBar/RegBarContainer";


export const Registration= ({isMobile}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className={s.wrapper}>
            <RegBarContainer isMobile = {isMobile}/>
        </div>
    )
}