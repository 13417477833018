import s from './ListVacancies.module.css'
import SearchBarContainer from '../../../Components/SearchBar/SearchBarContainer'

import { useState } from "react"


const ListVacancies = ({vac = 0}) => {
    const [isArchived, setIsArchived] = useState(false);

    

    return (
        <div className={s.container}>
            <SearchBarContainer isMobile={false}
                                listType={vac}
                                isAdmin={true}
                                isArchive={isArchived}/>


            <div className={s.isArchive}>
                <input type="checkbox" checked={isArchived}
                        onChange={(ev)=>{
                            setIsArchived(ev.target.checked)
                        }}/>
                <p>Удаленные</p>
            </div>
        </div>
    )
}

export default ListVacancies

