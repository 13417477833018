import React, {useEffect, useState} from "react";
import s from "./AboutCompany.module.css"
import playIcon from "../../Assets/Icons/startVideoIcon.svg"
import salonImg from "../../Assets/pageImages/aboutCompanyPageImages/salon.png"
import salonImg2 from "../../Assets/pageImages/aboutCompanyPageImages/salon2.png"
import mittingPeopleIcon from "../../Assets/pageImages/aboutCompanyPageImages/mittingPeopleIcon.svg"
import flagPeopleIcon from "../../Assets/pageImages/aboutCompanyPageImages/flagPeopleIcon.svg"
import countryIcon from "../../Assets/pageImages/aboutCompanyPageImages/countryIcon.svg"
import lead from "../../Assets/pageImages/aboutCompanyPageImages/lead.png"
import diagram from "../../Assets/pageImages/aboutCompanyPageImages/diagramm.svg"
import mountain from "../../Assets/pageImages/aboutCompanyPageImages/mountain.svg"
import pazle from "../../Assets/pageImages/aboutCompanyPageImages/pazle.png"
import arrow from "../../Assets/pageImages/aboutCompanyPageImages/arrow.png"
import noteBook from "../../Assets/Icons/noteBookIcon.svg"
import tower from "../../Assets/Icons/towerIcon.svg"
import tower5G from "../../Assets/Icons/5gTower.svg"
import QR from "../../Assets/Icons/QRcode.png"
import cImg1 from "../../Assets/pageImages/aboutCompanyPageImages/cardMix/img1.png"
import cImg2 from "../../Assets/pageImages/aboutCompanyPageImages/cardMix/img2.png"
import cImg3 from "../../Assets/pageImages/aboutCompanyPageImages/cardMix/img3.png"
import cImg4 from "../../Assets/pageImages/aboutCompanyPageImages/cardMix/img4.png"
import cImg5 from "../../Assets/pageImages/aboutCompanyPageImages/cardMix/img5.png"
import cImg6 from "../../Assets/pageImages/aboutCompanyPageImages/cardMix/img6.png"
import cImg7 from "../../Assets/pageImages/aboutCompanyPageImages/cardMix/img7.png"
import {Heading} from "../../Components/Heading/Heading";

import cImg2_1 from "../../Assets/pageImages/aboutCompanyPageImages/cardMix/img2_1.png"
import cImg2_2 from "../../Assets/pageImages/aboutCompanyPageImages/cardMix/img2_2.png"
import cImg2_3 from "../../Assets/pageImages/aboutCompanyPageImages/cardMix/img2_3.png"
import cImg2_4 from "../../Assets/pageImages/aboutCompanyPageImages/cardMix/img2_4.png"
import cImg2_5 from "../../Assets/pageImages/aboutCompanyPageImages/cardMix/img2_5.png"
import cImg2_6 from "../../Assets/pageImages/aboutCompanyPageImages/cardMix/img2_6.png"
import cImg2_7 from "../../Assets/pageImages/aboutCompanyPageImages/cardMix/img2_7.png"
import cImg2_8 from "../../Assets/pageImages/aboutCompanyPageImages/cardMix/img2_8.png"
import cImg2_9 from "../../Assets/pageImages/aboutCompanyPageImages/cardMix/img2_9.png"
import cImg2_10 from "../../Assets/pageImages/aboutCompanyPageImages/cardMix/img2_10.png"

import icon1 from "../../Assets/pageImages/HomePageImages/icon1.png";
import icon2 from "../../Assets/pageImages/HomePageImages/icon2.png";
import icon3 from "../../Assets/pageImages/HomePageImages/icon3.png";
import icon4 from "../../Assets/pageImages/HomePageImages/icon4.png";
import icon5 from "../../Assets/pageImages/HomePageImages/icon5.png";
import icon6 from "../../Assets/pageImages/HomePageImages/icon6.png";
import icon7 from "../../Assets/pageImages/HomePageImages/icon7.png";
import photo1 from "../../Assets/pageImages/aboutCompanyPageImages/photo1.png"
import photo2 from "../../Assets/pageImages/aboutCompanyPageImages/photo2.png"
import photo3 from "../../Assets/pageImages/aboutCompanyPageImages/photo3.png"
import photo4 from "../../Assets/pageImages/aboutCompanyPageImages/photo4.png"
import photo5 from "../../Assets/pageImages/aboutCompanyPageImages/photo5.png"
import photo6 from "../../Assets/pageImages/aboutCompanyPageImages/photo6.png"
import photo7 from "../../Assets/pageImages/aboutCompanyPageImages/photo7.png"
import planet2 from "../../Assets/pageImages/aboutCompanyPageImages/planet.svg";
import {Button} from "../../Components/Button/Button";
import ConditionsSlider from "../../Components/slickSlider/AboutCompanySliders/ConditionsSlider/ConditionsSlider";
import HistoriesSlider from "../../Components/slickSlider/AboutCompanySliders/HistoriesSlider/HistoriwsSlider";

import  BreadCrumbs  from "../../Components/BreadCrumbs/BreadCrumbs";
import TextModal from "../../Components/TextModal/TextModal"

import video from '../../Assets/Videos/aboutVideo.mp4'

import {useRef} from "react";
import EmailSubscribeBlockContainer from "../../Components/EmailSubscribeBlock/EmailSubscribeBlockContainer";
import {NavLink} from "react-router-dom";
import close from "../../Assets/Icons/close-outline.svg";
import DocumentMeta from "react-document-meta";

const colors = [
    "#00B856",
    "#FFFFFF",
    "#731982"]

export const AboutCompany = ({isMobile, userSubscribe, authorized}) => {

    const [storyModal, setStoryModal] = useState(-1)

    const storiesText = [
        {
            startText: "До МегаФона я работал в государственной компании. Но было ощущение, что развитие нашей отрасли приостановилось." +
                " Мобильные сети, наоборот, развивались очень бурно. Особенно выделялся своими продуктами оператор МЛТ. На нем я и остановил" +
                " свой выбор. Так 12 лет назад я оказался в компании, которая сейчас носит название «МегаФон Таджикистан».",
            moreText: "Работу начал с позиции инженера отдела ОТУ. В компании удивило все: большое число базовых станций, решение проблем абонентов, удаленное включение и отключение дизельных генераторов… Мне как инженеру электросвязи это было близко и интересно. \n" +
                "Чтобы занять свою сегодняшнюю позицию, я упорно занимался саморазвитием, проходил тренинги, участвовал в таких программах," +
                " как «Кадровый актив». Горжусь тем, что автоматизировал многие рабочие процессы отдела, своими предложениями значительно" +
                " сэкономил расходы на топливо для дизельных установок и электроэнергию на площадках базовых станций. Мой совет: идите вперед," +
                " развивайтесь – и у вас всё получится!"
        },
        {
            startText: "Работаю в компании 13 лет. Еще студентом во время преддипломной практики ходил к специалисту" +
                " МегаФона на консультации. А после зашиты диплома решил работать в этой команде.",
            moreText: "Вы не поверите, но я побывал на всех позициях инженера, которые есть в компании! Работал в отделе" +
                " эксплуатации SSS, был ведущим инженером по эксплуатации опорной сети, руководил отделом эксплуатации" +
                " сервисных платформ, потом Службой роуминга и сервисных платформ. Для адаптации нужно было изучить очень" +
                " много материалов и такие системы, которых, думаю, до сих пор нет больше нигде в стране" +
                "Это была отличная школа. Мне повезло: когда только пришёл на работу в МегаФон, в Душанбе шла замена коммутатора – такие" +
                " проекты бывают раз в 5-6 лет. Потом сам запускал новый коммутатор в Бохтаре. Были периоды очень напряженной работы," +
                " зато сейчас есть чем гордиться! И еще я понял: когда тебя поддерживают коллеги, никакая задача не будет слишком сложной."
        },
        {
            startText: "Путь в МегаФоне я начал с позиции специалиста-кассира. Работал в Душанбе, потом уехал в Хорог. Тогда в области" +
                " у нас был один офис и всего две базовые станции. А напротив был офис другого оператора. Туда каждый день заходили по" +
                " 200-300 человек, а к нам – один-два! Было обидно, и я поставил перед собой цель: сделать всё, чтобы МегаФон стал лидером" +
                " на рынке ГБАО. Предложил развивать сеть, строить новые БС. И в 2010 году было принято решение начать такую стройку." +
                " За два года установили 90 базовых станций, это дало хороший рост по выручке и новым абонентам.",
            moreText: "В 2010-м я стал специалистом, который отвечал за всю коммерцию в ГБАО. Мы начали успешно завоёвывать рынок." +
                " И когда через год появилось отдельное региональное управление, директором назначили меня. Было очень трудно, но" +
                " благодаря поддержке команды, прежде всего коллег из Душанбе, я почувствовал себя уверенно." +
                "За эти годы коллектив нашего РУ вырос с тех человек до семнадцати. Мы первыми в области начали запускать" +
                " базовые станции 4G. Стали лидерами по передаче данных. И, судя по выплатам в налоговые органы ГБАО," +
                " стали зарабатывать больше всех. Вот что значит иметь перед собой чёткую цель."
        },
        {
            startText: "Еще студентом я видел, как МегаФон продвигает свои услуги и целенаправленно  завоевывает рынок. Решил," +
                " что обязательно должен попасть в компанию на работу – и в 2012 году успешно прошел собеседование.",
            moreText: "Начинал я специалистом-кассиром в Курган-Тюбе. При большом потоке абонентов было непросто, но вскоре" +
                " я втянулся в работу. Уже в следующем году перешел на позицию менеджера. Курировал розничную сеть в пяти районах," +
                " часто ездил «в поля», проводил акции для жителей Хатлона. И «ступенька за ступенькой» добрался до своей сегодняшней должности." +
                "Я горжусь тем, что работаю в большом дружном коллективе. В компании, у которой самый быстрый интернет среди операторов," +
                " отличный сервис, уникальные услуги. В компании, где твою работу обязательно оценят по достоинству."
        },
        {
            startText: "Свой первый день в МегаФоне я помню, будто это было вчера, хотя с тех пор прошло 19 лет. Я учился" +
                " тогда на втором курсе Технологического университета. Пришел в компанию на практику – и тут же получил толстенную" +
                " книгу с техническим описанием оборудования связи, изучить которую предстояло всего за несколько дней. По книге," +
                " конечно, все было понятно, но хотелось самому всё посмотреть, потрогать руками. Опытные коллеги помогли мне с обучением," +
                " поделились своими знаниями. И через полгода я был принят в штат компании.",
            moreText: "В технической службе я прошел все ступени. Был инженером по эксплуатации сети. Обеспечивал связью" +
                " международный «Форум чистой воды». Запускал сети нескольких поколений. Интересно, что связь 3G появилась" +
                " в Таджикистане раньше, чем в России, и наши специалисты помогали потом российским коллегам. Но больше всего" +
                " горжусь причастностью к запуску 5G. МегаФон Таджикистан стал не только первым в стране – одним из первых в мире!" +
                "Сейчас я руковожу направлением ФШПД, новым для нашей компании. Уверен, что и в области фиксированного интернета" +
                " нам удастся достичь отличных результатов. Потому что для нас это не просто работа – это жизнь, причём очень интересная!"
        },
        {
            startText: "Всегда хотел работать в телеком-отрасли, потому что она из года в год становится всё более интересной." +
                " И 1 сентября 2008 года пришёл в МегаФон на должность менеджера по дополнительным услугам. Мы создавали продукты," +
                " которые приносят ощутимую пользу абонентам. Причём даже большой объём работы могли выполнить малыми силами!",
            moreText: "Вскоре я стал старшим менеджером, потом возглавил службу новых продуктов и дополнительных услуг." +
                " На каждом этапе были определённые сложности. Но за счет постоянного желания развиваться мне удавалось достичь" +
                " высоких показателей и занять новую, более высокую позицию в компании." +
                "МегаФон Таджикистан всегда идет впереди коллег по рынку. Поэтому мы первыми запускаем многие продукты." +
                " Хочу отметить ряд стратегически важных «молодых» проектов, у истоков которых я стоял: это «МегаФон Life»" +
                " и мобильный «Кошелек», кобрендинговые карты и другие Fintech-проекты, порталы по интересам – медицинский Yakdu, «Арбуз ТВ»," +
                " eSport, образовательный ресурс «Академия МегаФон». Скоро запускаем ряд новых продуктов, которые будут уникальными" +
                " для Таджикистана и помогут нам укрепить своё цифровое лидерство на телеком-рынке. У нас очень увлекательная работа!"
        },
        {
            startText: "Членом большой семьи МегаФона я стал в 2011 году, сразу после университета." +
                " С тех пор постоянно расту – как личность и как эксперт в своей области.",
            moreText: "Наверное, каждому молодому специалисту хочется работать в большой компании. МегаФон Таджикистан" +
                " дал мне такую возможность. Сначала работал специалистом-кассиром офиса обслуживания (как же давно это было!)." +
                " Каждый день – поток абонентов. Мне, застенчивому парню, было тяжело общаться с незнакомыми людьми, но со временем" +
                " я стал одним из коммуникабельных сотрудников. Вот что значит опыт!" +
                "За десять лет я прошёл путь от рядового специалиста до довольно высокой руководящей позиции." +
                " Многие знакомые даже не верят, что такое возможно. На это я отвечаю: «Вы просто не работаете в МегаФоне!»" +
                " Горжусь тем, что прошёл этот путь. И сердечно благодарю всех руководителей за то," +
                " что верили в меня и давали возможность развиваться!"
        },
    ]
    

    const anchors = [
        "#leadBar",
        "#teamBar"
    ]

    const [videoIsStarted, toggleVideoIsStarted] = useState(true)
    const [descriptionModal, setDescriptionModal] = useState(-1)
    const setModalNum = (num) => {
        if (num === descriptionModal) {
            setDescriptionModal(-1)
        } else {
            setDescriptionModal(num)
        }
    }

    const videoEl = useRef(null)

    function getFadeMiddleColor(index, percent) {

        
        var startPercent = percent / 100;

        var finishPercent = 1 - startPercent;

        var R, G, B;

        var aRGBStart = colors[index].replace("#", "").match(/.{2}/g);
        var aRGBFinish = colors[index  + 1].replace("#", "").match(/.{2}/g);

        R = Math.floor(('0x' + aRGBStart[0]) * startPercent + ('0x' + aRGBFinish[0]) * finishPercent);
        G = Math.floor(('0x' + aRGBStart[1]) * startPercent + ('0x' + aRGBFinish[1]) * finishPercent);
        B = Math.floor(('0x' + aRGBStart[2]) * startPercent + ('0x' + aRGBFinish[2]) * finishPercent);
   
        return 'rgb(' + R+ ',' + G + ',' + B + ')';
    }

    const simpleScroll = function (element, style, value) {


        element.style.backgroundColor = colors[0]
        anchors.forEach((a,i) =>{

            const rect = document.querySelector(a).getBoundingClientRect()

            if(rect.top > 300 && rect.top < 1200){
                element.style.backgroundColor = getFadeMiddleColor(i, (rect.top -300) / 1200 * 100)
            }else if (rect.top < 300){
                    element.style.backgroundColor = colors[i+1]
            }
            
        })

        let isMob = window.innerWidth <= 900;
       
        // let heightAddDesc = 300
        // // if (isMob) {
        // //     pctTwo = (scrollTop - scrollPointThree) / scrollPointFour * 7
        // // } else {
        // //     pctTwo = (scrollTop - scrollPointThree) / scrollPointFour * 19
        // // }

        const rows = document.querySelectorAll(".scrollRow")

        rows.forEach((r,i) =>{
            const bound = r.getBoundingClientRect()
            if(bound.top > 0 && bound.top < (window.innerHeight / 2) + 400 ){
                r.style.left = !isMob ? `${2 + (5 * i)}%` : "0px"
            }
        })

        // if (scrollTop >= rowScrollPointOne && scrollTop <= rowScrollPointTwo) {
        //     let rows = document.querySelectorAll(".scrollRow")
        //     if (scrollTop > rowScrollPointOne + (isMob ? 300 : 1000 - heightAddDesc)) {
        //         rows[0].style.left = !isMob ? "2%" : "0px"
        //     }
        //     if (scrollTop > rowScrollPointOne + (isMob ? 400 : 1150 - heightAddDesc)) {
        //         rows[1].style.left = !isMob ? "7%" : "0px"
        //     }
        //     if (scrollTop > rowScrollPointOne + (isMob ? 500 : 1250 - heightAddDesc)) {
        //         rows[2].style.left = !isMob ? "12%" : "0px"
        //     }
        //     if (scrollTop > rowScrollPointOne + (isMob ? 600 : 1350 - heightAddDesc)) {
        //         rows[3].style.left = !isMob ? "17%" : "0px"
        //     }
        //     if (scrollTop > rowScrollPointOne + (isMob ? 700 : 1450 - heightAddDesc)) {
        //         rows[4].style.left = !isMob ? "23%" : "0px"
        //     }

        // }
        // if (scrollTop <= scrollPointTwo && scrollTop >= scrollPointOne) {
        //     for (let i = 0; i < colorsValOne[0].length; i++) {
        //         if (colorsValOne[0][i] < colorsValOne[1][i]) {
        //             valOne = ((colorsValOne[1][i] - colorsValOne[0][i]) * pctOne) + colorsValOne[0][i]
        //             currentColorOne[i] = Math.round(valOne)
        //         } else {
        //             valOne = colorsValOne[0][i] - ((colorsValOne[0][i] - colorsValOne[1][i]) * pctOne)
        //             currentColorOne[i] = Math.round(valOne)
        //         }

        //     }

        //     element.style[style] = 'rgb(' + currentColorOne.join(',') + ')'
        // } else if (scrollTop <= scrollPointFour && scrollTop >= scrollPointThree) {
        //     for (let i = 0; i < colorsValTwo[0].length; i++) {
        //         if (colorsValTwo[0][i] < colorsValTwo[1][i]) {
        //             valTwo = ((colorsValTwo[1][i] - colorsValTwo[0][i]) * pctTwo) + colorsValTwo[0][i]
        //             currentColorTwo[i] = Math.round(valTwo)
        //         } else {
        //             valTwo = colorsValTwo[0][i] - ((colorsValTwo[0][i] - colorsValTwo[1][i]) * pctTwo)
        //             currentColorTwo[i] = Math.round(valTwo)
        //         }

        //     }

        //     element.style[style] = 'rgb(' + currentColorTwo.join(',') + ')'
        // } else if (scrollTop < scrollPointOne) {
        //     element.style[style] = 'rgb(0, 184, 86)'
        // } else if (scrollTop > scrollPointFour) {
        //     element.style[style] = 'rgb(115, 25, 130)'
        // }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    useEffect(() => {
        let obj = document.querySelector('.scrollBox');
        let scrollFunk = () => simpleScroll(obj, 'background-color', {
            one: ['0,184,86', '255,255,255'],
            two: ['255,255,255', '115,25,130']
        })
        scrollFunk()
        window.addEventListener("scroll", scrollFunk)
        return () => {
            window.removeEventListener("scroll", scrollFunk)
        }
    }, [])


    useEffect(() => {
        if (videoIsStarted)
            videoEl?.current?.play()
        else
            videoEl?.current?.pause()

    }, [videoIsStarted])

    const meta = {
        description: 'Миссия, ценности, команда и отзывы наших сотрудников. Присоединяйтесь к МегаФон!',
        }


    return (
        <div className={`${s.wrapper} scrollBox`}>
            <DocumentMeta {...meta} />
            {!isMobile ? <div className={s.videoPlayerBar} onClick={() => toggleVideoIsStarted(!videoIsStarted)}>
                <div className={s.processIconBar}>{!videoIsStarted ?
                    <img src={playIcon} alt="start"/> : null}</div>
                <video src={video} ref={videoEl} muted style={{objectFit: "cover"}} className={s.video} loop>
                </video>
            </div> : 
            <div className={s.videoLink}>
                <a href="https://www.youtube.com/watch?v=6AttLOVDPMw" target="_blank">
                    <img className={s.linkIcon} src={playIcon} alt="start"/>
                </a>
            </div>}
            <div className={s.infoBar}>
                <div className={s.breadContainer}>
                    <BreadCrumbs steps = {[["Главная","/"],["О компании"]]}
                                inActiveColor={"white"}/>
                </div>
                <div className={s.happyClientBar}>
                    
                    <div className={s.textBar}>
                        <Heading width={isMobile ? "90%" : "100%"} headWidth={isMobile ? "60%" : null}
                                 textWidth={isMobile ? "80%" : null} headText="Счастливый клиент" text="Каждый день мы видим глаза счастливых абонентов.
                         Это вдохновляет и придает уверенности. Мы точно знаем, что будущее компании зависит
                          от каждого из нас."/>
                    </div>
                    <div className={s.infImgBar}>
                        <img src={salonImg} alt="salon"/>
                        <span id="rowScrollPointOne"/></div>
                    <div style={{width: "194px", height: "194px", left: "300px", bottom: "190px"}}
                         className={s.circle}/>
                    <div style={{width: "336px", height: "336px", left: "0", bottom: "-100px"}} className={s.circle}/>
                </div>

                <div className={s.statisticsBar}>
                    <div className={s.iconsBar}>
                        <img src={mittingPeopleIcon} alt=""/>
                        <img src={flagPeopleIcon} alt=""/>
                        <img src={countryIcon} alt=""/>
                        <div style={{width: "160px", height: "160px", right: "0%", bottom: "-100%"}}
                             className={s.circle}/>
                    </div>
                    <span id="rowScrollPointTwo"/>
                    <div className={s.overflowHid}>
                        <div className={s.statisticsRowsBar}>
                        
                            <div className={`${s.statisticRowBox} scrollRow`}><p>У нас более <span
                                className={s.statisticNum}>1 500 000</span> абонентов!</p></div>
                            <div className={`${s.statisticRowBox} scrollRow`}><p>Уверенное присутствие в <span
                                className={s.statisticNum}>4</span> регионах страны</p></div>
                            <div className={`${s.statisticRowBox} scrollRow`}><p>Мы первыми в Таджикистане запустили <span
                                className={s.statisticNum}>5G</span></p></div>
                            <div className={`${s.statisticRowBox} scrollRow`}><p>Свыше <span className={s.statisticNum}>3 000</span> базовых станций
                            </p></div>
                            <div className={`${s.statisticRowBox} scrollRow`}><p>стандартов <span
                                className={s.statisticNum}>2G/3G/4G</span></p>
                            </div>
                        </div>
                        <img className={s.notebookImg} src={noteBook} alt=""/>
                        <img className={s.towerImg} src={isMobile ? tower5G : tower} alt=""/>
                    </div>
                    <div className={s.planetBoxWrapper}>
                        {!isMobile ? <div className={s.planetBox}>
                            <Planet setModalNum={setModalNum}/>
                        </div> : null}
                        <div className={s.textContainer}>
                            <Heading padding={!isMobile ? "0 0 0 20%" : "0 0 5% 5%"}
                                     headText="Высокий уровень обслуживания"
                                     text="Сервисы экосистемы МегаФон"/>
                            {!isMobile ? <div className={s.imgContainer}> <a href="https://life.tj" title="Лучшая экосистема для абонентов"><img src={QR} alt=""/></a></div> : null}
                        </div>
                        {isMobile ? <div className={s.planetBox}>
                            <Planet setModalNum={setModalNum}/>
                        </div> : null}
                        {isMobile ? <div className={s.qrBar}><a href="https://life.tj"><img className={s.qrCode} src={QR} alt=""/></a>
                            <p>Приложение <br/>
                                для клиентов</p></div> : null}
                        <div style={{width: "85px", height: "85px", right: "250px", top: "-110px"}}
                             className={s.circle}/>
                        <div style={{width: "160px", height: "160px", right: "100px", top: "-10px"}}
                             className={s.circle}/>
                        <div style={{width: "244px", height: "244px", right: "30px", bottom: "-100px"}}
                             className={s.circle}/>
                    </div>
                    <span id="scrollPointOne"/>
                    <div className={s.specialistsBar}>
                        {!isMobile ? <div className={s.infImgBar}>
                            <img src={salonImg2} alt="salon"/>
                        </div> : null}
                        <div className={s.textContainer}>
                            <Heading padding={!isMobile ? "0 0 0 20%" : "0 0 56px 5%"}
                                     headText="Наших специалистов ценят за их работу" text="Наши сотрудники — настоящие асы своего дела!
                             Они востребованы в разных отраслях, представлены в качестве экспертов и с каждым днем становятся все лучше и лучше!"/>
                        </div>
                        {isMobile ? <div className={s.infImgBar}>
                            <img src={salonImg2} alt="salon"/>
                        </div> : null}
                        <div style={{width: "375px", height: "375px", left: "-4%", top: "-80%"}} className={s.circle}/>
                    </div>
                    <div className={s.leadBar} id="leadBar">
                        {!isMobile ? <div className={s.infImgBar}>
                            <img src={lead} alt="lead"/>
                        </div>: null}
                        <div style={{paddingLeft: isMobile ? "5%" : "10%"}} className={s.textContainer}>
                            <p style={{
                                paddingBottom: !isMobile ? "25px" : "15px",
                                fontSize: isMobile ? "50px" : "65px"
                            }} className={s.leadSubTitle}>Лидерство</p>
                            <Heading color="black" headText="Начинается с тебя"
                                     text="Принеси в нашу команду свою энергию и свежие идеи. А мы поможем их реализовать."/>
                        </div>
                        {isMobile ? <div className={s.infImgBar}>
                            <img src={lead} alt="lead"/>
                        </div>: null}
                    </div>
                    <div className={s.careerGrowthBar}>
                        <div className={s.textContainer}>
                            <div className={s.careerGrowthHeadBar}>
                                <p className={s.careerGrowthSubTitle}>Карьерный рост —
                                    это реальность</p>
                                <Heading color="black"
                                         text="С нами ты построишь крутую карьеру. Главное – не бояться делать! Не раздумывай, начинай расти!"/>
                            </div>
                        </div>
                        <div className={`${s.infImgBar} ${s.careerImgBar}`}>
                            <img src={diagram} alt="diagram"/>
                        </div>
                    </div>
                    <span id="scrollPointTwo"/>
                    <div className={s.checkAllVacanciesBar}>
                        <p>Стань лидером с МегаФон уже сегодня!</p>
                        <div className={s.checkAllBtnBar}><NavLink to="/vakansii"><Button
                            padding="10px 0 10px 0"
                            width="100%" variant={4}
                            btnText="Посмотреть все вакансии"/></NavLink>
                        </div>
                    </div>
                    <div className={s.mountainBar}>
                        <div className={s.mountainText}>
                            <div className={s.partOne}>
                                <Heading
                                    padding={isMobile ? "0 0 0 5%" : "0"}
                                    headText="Растем до небес начиная с самых низов! Стажировки в МегаФон!"
                                    color="#731982" width={!isMobile ? "95%" : "70%"}/></div>
                            <div className={s.partTwo}><p>Стажировка в компании –
                                начало интересного и увлекательного
                                профессионального пути.<br/>
                                Начни менять мир в команде профессионалов!</p></div>
                        </div>
                        <div className={s.mountain}>
                            <img src={mountain} alt=""/>
                        </div>
                    </div>
                    <div className={s.storiesBar}>
                        <p className={s.storiesBarHead}>Истории успеха</p>
                        {!isMobile ? <div>
                            <div className={s.peopleBox}>
                                <div className={s.photo}><img src={photo1} alt="photo"/></div>
                                <div style={{paddingLeft: "9%"}} className={s.storyText}>
                                    <p className={s.name}>Комрон Гадоев</p>
                                    <p className={s.jobPosition}>Руководитель отдела технического управления</p>
                                    <p className={s.story}>{storiesText[0].startText}
                                    <br/>
                                        <span onClick={() => setStoryModal(0)} className={s.showMoreText}>Читать
                                            далее...</span>
                                    </p>
                                </div>
                            </div>
                            <div className={s.peopleBox}>
                                <div style={{paddingRight: "9%"}} className={s.storyText}>
                                    <p className={s.name}>Азамджон Джонов</p>
                                    <p className={s.jobPosition}>Руководитель службы сервисных платформ и роуминга</p>
                                    <p className={s.story}>{storiesText[1].startText} <br/>
                                        <span onClick={() => setStoryModal(1)} className={s.showMoreText}>Читать
                                            далее...</span>
                                    </p>
                                </div>
                                <div className={s.photo}><img src={photo2} alt="photo"/></div>
                            </div>
                            <div className={s.peopleBox}>
                                <div className={s.photo}><img src={photo3} alt="photo"/></div>
                                <div style={{paddingLeft: "9%"}} className={s.storyText}>
                                    <p className={s.name}>Раим Садоншоев</p>
                                    <p className={s.jobPosition}>Директор регионального управления по ГБАО</p>
                                    <p className={s.story}>{storiesText[2].startText} <br/>
                                        <span onClick={() => setStoryModal(2)} className={s.showMoreText}>Читать
                                            далее...</span>
                                    </p>
                                </div>
                            </div>
                            <div className={s.peopleBox}>
                                <div style={{paddingRight: "9%"}} className={s.storyText}>
                                    <p className={s.name}>Фирдавс Шамсуддинов</p>
                                    <p className={s.jobPosition}>Коммерческий руководитель регионального управления по
                                        Хатлонской области</p>
                                    <p className={s.story}>{storiesText[3].startText} <br/>
                                        <span onClick={() => setStoryModal(3)} className={s.showMoreText}>Читать
                                            далее...</span>
                                    </p>
                                </div>
                                <div className={s.photo}><img src={photo4} alt="photo"/></div>
                            </div>
                            <div className={s.peopleBox}>
                                <div className={s.photo}><img src={photo5} alt="photo"/></div>
                                <div style={{paddingLeft: "9%"}} className={s.storyText}>
                                    <p className={s.name}>Алиджон Ганиев</p>
                                    <p className={s.jobPosition}>Руководитель департамента развития и эксплуатации ФШПД</p>
                                    <p className={s.story}>{storiesText[4].startText} <br/>
                                        <span onClick={() => setStoryModal(4)} className={s.showMoreText}>Читать
                                            далее...</span>
                                    </p>
                                </div>
                            </div>
                            <div className={s.peopleBox}>
                                <div style={{paddingRight: "9%"}} className={s.storyText}>
                                    <p className={s.name}>Юрий Глущенко</p>
                                    <p className={s.jobPosition}>Руководитель службы новых бизнесов и партнерств</p>
                                    <p className={s.story}>{storiesText[5].startText} <br/>
                                        <span onClick={() => setStoryModal(5)} className={s.showMoreText}>Читать
                                            далее...</span>
                                    </p>
                                </div>
                                <div className={s.photo}><img src={photo6} alt="photo"/></div>
                            </div>
                            <div className={s.peopleBox}>
                                <span id="scrollPointThree"></span>
                                <div className={s.photo}><img src={photo7} alt="photo"/></div>
                                <div style={{paddingLeft: "9%"}} className={s.storyText}>
                                    <p className={s.name}>Комрон Кенжаев</p>
                                    <p className={s.jobPosition}>Коммерческий руководитель регионального управления
                                        по г.Душанбе и РРП
                                    </p>
                                    <p className={s.story}>{storiesText[6].startText} <br/>
                                        <span onClick={() => setStoryModal(6)} className={s.showMoreText}>Читать
                                            далее...</span>
                                    </p>
                                </div>
                            </div>
                        </div> : <div>
                            <span id="scrollPointThree"></span>
                            <HistoriesSlider stories={storiesText} setStoryModal={setStoryModal}/>
                        </div>}
                    </div>
                </div>
            </div>
            <div className={`${s.lifeInMegafonBar} scrollTwoEnd`} id="teamBar">
                <div className={s.teamBar}>
                    <div className={s.textContainer}>
                        <p className={s.teamSubTitle}>Команда</p>
                        {isMobile ? <span id="scrollPointFour"></span> : null}
                        <Heading width="100%" headWidth="40%" textWidth={!isMobile ? "55%" : "90%"}
                                 headText="начинается с тебя"
                                 text="В работе мы используем самые современные и эффективные методики организации работы наших команд!"/>
                    </div>
                    {!isMobile ? <span id="scrollPointFour"></span> : null}
                    <div className={s.infImgBar}>
                        <img src={!isMobile ? pazle : arrow} alt="salon"/>
                    </div>
                    <div style={{width: "255px", height: "255px", right: "-130px", top: "-300px"}}
                         className={s.circle}/>
                    <div style={{width: "160px", height: "160px", left: "30px", bottom: "40%"}} className={s.circle}/>
                    <div style={{width: "255px", height: "255px", left: "150px", bottom: "2%"}} className={s.circle}/>
                    <div style={{width: "160px", height: "160px", right: "20px", bottom: "-160px"}}
                         className={s.circle}/>
                </div>
                <div className={s.lifeInMegafonTitle}>
                    <Heading width={!isMobile ? "30%" : "90%"} headText="Жизнь внутри МегаФон"/>
                </div>
                {!isMobile ? <div className={s.imgMixBoxOne}>
                    <div className={s.mixOneRowOne}>
                        <div style={{flex: "486"}}><img src={cImg1} alt="c1"/></div>
                        <div style={{flex: "728"}}><img src={cImg2} alt="c2"/></div>
                        <div style={{flex: "392", display: "flex", flexDirection: "column"}}>
                            <div style={{flex: "1"}}><img src={cImg3} alt="c3"/></div>
                            <div style={{
                                flex: "1",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "white"
                            }}><p>Наши офисы</p></div>
                        </div>
                        <div style={{
                            flex: "303",
                            objectFit: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover"
                        }}>
                            <img style={{maxWidth: "100%", maxHeight: "100%"}} src={cImg4} alt="c4"/>
                        </div>
                    </div>
                    <div className={s.mixOneRowTwo}>
                        <div style={{flex: 1223, display: "flex"}}>
                            <div><img src={cImg5} alt="c5"/></div>
                            <div><img src={cImg6} alt="c6"/></div>
                        </div>
                        <div style={{flex: 691}}><img src={cImg7} alt="c7"/></div>
                    </div>
                </div> : <div className={s.imagesLine} style={{width: "100%"}}>
                    <img src={cImg2} alt=""/>
                    <img src={cImg3} alt=""/>
                    <img src={cImg7} alt=""/>
                    <img src={cImg6} alt=""/>
                    <img src={cImg4} alt=""/>
                    <img src={cImg1} alt=""/>
                </div>}
                <div className={s.workСonditionsBar}>
                    <div className={s.headBar}><Heading width={!isMobile ? "100%" : "60%"}
                                                        headText="Условия труда"/>
                    </div>
                    <div className={s.sliderBar}>
                        <ConditionsSlider isMobile={isMobile}/>
                    </div>
                    <div
                        style={{width: "160px", height: "160px", right: "10%", top: "-30%", backgroundColor: "#00B856"}}
                        className={s.circle}/>
                    <div style={{width: "75px", height: "75px", right: "5%", top: "-5%", backgroundColor: "#00B856"}}
                         className={s.circle}/>

                    <div style={{
                        width: "160px",
                        height: "160px",
                        left: "6%",
                        bottom: "-30%",
                        backgroundColor: "#00B856"
                    }} className={s.circle}/>
                    <div style={{width: "75px", height: "75px", left: "2%", bottom: "-5%", backgroundColor: "#00B856"}}
                         className={s.circle}/>
                </div>
                <div className={s.projectMaterialsBar}>
                    {!isMobile ? <div className={s.infImgBar}>
                        <img src={pazle} alt="lead"/>
                    </div> : null}
                    <div
                        className={s.textContainer}>
                        <Heading width="85%" color="white" headText="Проектные команды, кроссотдел"/>
                    </div>
                    {isMobile ? <div className={s.infImgBar}>
                        <img src={pazle} alt="lead"/>
                    </div> : null}
                    <div style={{
                        width: "155px",
                        height: "155px",
                        left: "100px",
                        bottom: "-275px",
                        backgroundColor: "#00B856"
                    }} className={s.circle}/>
                    <div style={{
                        width: "140px",
                        height: "140px",
                        right: "0",
                        bottom: "-300px",
                        backgroundColor: "#00B856"
                    }} className={s.circle}/>
                    <div style={{
                        width: "240px",
                        height: "240px",
                        left: "-120px",
                        bottom: "-500px",
                        backgroundColor: "#00B856"
                    }} className={s.circle}/>
                </div>
                <div className={s.megaLifeHead}>
                    <p className={s.MLhead}>МегаЖизнь</p>
                    {!isMobile ? <p>Взаимное развитие, годовые командные цели</p> : null}
                </div>
                {!isMobile ? <div className={s.imgMixBoxTwo}>
                    <div className={s.mixTwoRowOne}>
                        <div style={{flex: "1246", display: "flex", flexDirection: "column"}}>
                            <div style={{flex: "406", display: "flex"}}>
                                <div style={{flex: "826"}}><img src={cImg2_1} alt="c2_1"/></div>
                                <div style={{flex: "420"}}>
                                    <div style={{
                                        backgroundColor: "white",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "100%",
                                        height: "100%"
                                    }}>Обучение
                                    </div>
                                </div>
                            </div>
                            <div style={{flex: "347", display: "flex"}}>
                                <div style={{flex: "449"}}><img src={cImg2_2} alt="c2_2"/></div>
                                <div style={{flex: "797"}}><img src={cImg2_3} alt="c2_3"/></div>
                            </div>
                        </div>
                        <div style={{flex: "672"}}>
                            <img src={cImg2_4} alt="c2_4"/>
                        </div>
                    </div>

                    <div className={s.mixTwoRowTwo}>
                        <div style={{flex: "449"}}><img src={cImg2_5} alt="c2_5"/></div>
                        <div style={{flex: "1044", display: "flex"}}>
                            <div style={{flex: "387", display: "flex", flexDirection: "column"}}>
                                <div style={{flex: "372"}}>
                                    <div style={{
                                        backgroundColor: "white",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "100%",
                                        height: "100%"
                                    }}>Развлечения
                                    </div>
                                </div>
                                <div style={{flex: "360"}}><img src={cImg2_6} alt="c2_8"/></div>
                            </div>
                            <div style={{flex: "650", position: "relative"}}>
                                <img src={cImg2_7} alt="c2_7"/>
                                <div style={{
                                    position: "absolute",
                                    bottom: "0",
                                    left: "0",
                                    backgroundColor: "white",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "58%",
                                    height: "50%"
                                }}>Корпоративы
                                </div>
                            </div>
                        </div>
                        <div style={{flex: "432"}}><img src={cImg2_8} alt="c2_8"/></div>
                    </div>

                    <div className={s.mixTwoRowThree}>
                        <div style={{flex: "985", display: "flex"}}>
                            <div style={{
                                flex: "582",
                                backgroundColor: "white",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>Спорт
                            </div>
                            <div style={{flex: "403"}}><img src={cImg2_9} alt="c2_9"/></div>
                        </div>
                        <div style={{flex: "938"}}><img src={cImg2_10} alt="c2_10"/></div>
                    </div>
                </div> : <div className={`${s.imagesLine} ${s.lineTwo}`} style={{width: "100%"}}>
                    <img src={cImg2_1} alt=""/>
                    <img src={cImg2_2} alt=""/>
                    <img src={cImg2_3} alt=""/>
                    <img src={cImg2_6} alt=""/>
                    <img src={cImg2_8} alt=""/>
                    <img src={cImg2_7} alt=""/>
                </div>}
            </div>
            <div className={s.subscriptionBarWrapp}>
                <div style={{width: "100%", textAlign: "center", padding: "80px 0"}}><NavLink to="/vakansii">
                    <Button padding="10px 0 10px 0"
                            width={!isMobile ? "48%" : "80%"}
                            variant={4}
                            btnText="Присоединяйся к лучшей команде!"/>
                </NavLink>
                </div>
                {!userSubscribe && authorized ? <div className={s.subscriptionBar}>
                    <EmailSubscribeBlockContainer isMobile={isMobile}/>
                </div> : null}
            </div>
            
            {storyModal !== -1 ? 
            <TextModal open={storyModal !== -1}
                onClose = {()=>{
                setStoryModal(-1)
                }}>
                <div className={s.storyText}>
                    <p>{storiesText[storyModal].startText} {storiesText[storyModal].moreText}</p>
                </div>
            </TextModal>
            : null}
        </div>
    )
}

const Planet = ({}) => {
    return (
        <div className={s.planetContainer}>
            <div className={s.iconsContainer}>
                <div className={s.iconContainer}><a href="https://megamusic.tj/" target="_blank">
                    <img className={`${s.planIcon}`} src={icon1} alt=""/>
                </a></div>
                <div style={{}} className={s.iconContainer}>
                    <a target="_blank" href="https://megaquest.megafon.tj/"><img style={{width: "40%"}}
                                                                                 className={`${s.planIcon}`} src={icon2}
                                                                                 alt=""/></a>
                </div>


                <div className={s.iconContainer}>
                    <a target="_blank" href="http://academy.megafon.tj/"><img className={`${s.planIcon}`} src={icon3}
                                                                              alt=""/></a>
                </div>

                <div className={s.iconContainer}>
                    <a target="_blank" href="https://megamovie.tj/"><img className={`${s.planIcon}`} src={icon4}
                                                                         alt=""/></a>
                </div>

                <div className={s.iconContainer}>
                    <a target="_blank" href="https://life.tj/"><img style={{width: "23%"}}
                                                                                className={`${s.planIcon}`} src={icon5}
                                                                                alt=""/></a>
                </div>

                <div className={s.iconContainer}>
                    <a target="_blank" href="https://www.megafon.tj/podderzhka/novosti/megafon_tadzhikistan_sozdal_prilozhenie_dlya_zriteley_kanala_futbol_/"><img className={`${s.planIcon}`} src={icon6} alt=""/></a>
                </div>

                <div className={s.iconContainer}>
                    <a target="_blank"
                       href="https://www.megafon.tj/podderzhka/novosti/arbuz_tv_televizor_v_tvoem_smartfone/"><img
                        className={`${s.planIcon}`} src={icon7} alt=""/></a>
                </div>
            </div>
            <div className={s.planet}>
                <img src={planet2} alt="planet"/>
            </div>
        </div>
    )
}