import React, {useEffect, useState} from "react";
import s from "./Filter.module.css"
import {CheckBox} from "../Checkbox/CheckBox";

export const Filter = ({filterParams, onSelect, directionId}) => {
    const [setAll, toggleSetAll] = useState(!directionId ? true : false)
    const [activeItems, setActiveItems] = useState([])
    const [paramsFormed, setParamsFormed] = useState(false)

    useEffect(() => {
        let parts = filterParams.params.map((p) => {
            return {
                partName: `${p.title}`,
                selectIds: []
            }
        })
        setActiveItems(parts)
        setParamsFormed(true)
    }, [])

    useEffect(() => {
        onSelect([setAll, activeItems])
    }, [activeItems, setAll])


    useEffect(() => {
        if (directionId) {
            if (paramsFormed) {
                onSelectItem(directionId, "Направления")
            }
        }
    }, [paramsFormed])


    const onSetAll = (isActive) => {
        toggleSetAll(isActive)
        if (isActive) {
            let parts = filterParams.params.map((p) => {
                return {
                    partName: `${p.title}`,
                    selectIds: []
                }
            })
            setActiveItems(parts)
        }
    }

    const onSelectItem = (id, partName) => {
        let newActiveItems = [...activeItems]
        const currentPart = newActiveItems.findIndex((p) => p.partName === partName)
        const isActive = newActiveItems[currentPart].selectIds.find((i) => i === id) >= 0
        if (isActive) {
            newActiveItems[currentPart].selectIds = newActiveItems[currentPart].selectIds.filter((i) => i !== id)
        } else {
            newActiveItems[currentPart].selectIds.push(id)
        }
        if (newActiveItems[0].selectIds.length === 0 && newActiveItems[1].selectIds.length === 0) {
            toggleSetAll(true)
        } else {
            toggleSetAll(false)
        }

        setActiveItems(newActiveItems)
    }

    let filtersList = filterParams.params.map((f) => {
        let filtItems = f.path.map((i) => {
            return (<div key={i.id} className={s.item}><CheckBox id={i.id} isManyChoice={true}
                                                                 onSetChecked={onSelectItem}
                                                                 text={i.name}
                                                                 flexValue="18"
                                                                 iconHeight="21px"
                                                                 part={f.title}
                                                                 selectedAll={setAll}
                                                                 margin="0 18px 0 0"
                                                                 isStartChecked={f.title === "Направления" && directionId && directionId === i.id}
            />
                <p className={s.countText}>{i.count}</p></div>)
        })
        return (
            <div className={s.part} key={f.title}>
                <p className={s.partTitle}>{f.title}</p>
                {filtItems}
            </div>
        )
    })

    return (
        <div className={s.filterWrapper}>
            <div className={s.subWrapper}>
                <div className={`${s.item} ${s.selectAll}`}><CheckBox flexValue = "18" text="Выбрать все" isManyChoice={false} onSetChecked={onSetAll}
                                                                      bool={setAll} margin="0 18px 0 0"/><p className={s.countText}>{filterParams.totalCount}</p></div>
                {filtersList}
            </div>
        </div>
    )
}