import s from './InfoBanner.module.css'
import image from "../../Assets/pageImages/ProbationPageImages/bannerImg.png"

import { Button } from '../Button/Button'

const InfoBanner = ({probationName, description, toggleShowSendApplicationModal}) =>{
    return (
        <div className={s.banner}>
            <div className={s.bannerImage}>
                <img src={image} alt=""/>
            </div>
            
            <div className={s.textContainer}>
                <p className={s.bannerTitle}>{probationName}</p>
                <p className={s.bannerDescription}>{description}</p>
                <Button onClick={() => toggleShowSendApplicationModal(true)}
                        btnText={"Записаться на стажировку"}
                        textColor={"white"}
                        variant={6}/>
            </div>
        </div>
    )
}


export default InfoBanner