import s from './EmailSubscribeBlock.module.css'

import {useEffect, useState} from 'react'


import { Input } from '../Input/Input'
import {Button} from '../Button/Button'

const EmailSubscribeBlock = ({isMobile, isDark, userEmail, onSubscribe}) =>{
    const [email, setEmail] = useState(userEmail)
    const [emailIsValid, setEmailIsValid] = useState(true)

    useEffect(() => {
        setEmail(userEmail)
    }, [userEmail])

    const goSubscribe = () => {
        onSubscribe(email)
    }

    return (
        <div style={{backgroundColor:isDark ?"#EDEDED": null}} className={s.subscriptionBar}>
                <div className={s.subscriptionSubWrapp}>
                    <p className={s.subscriptionBarHead}>Подпишись на нашу рассылку!</p>
                    <p className={s.subscriptionBarText}>Не пропусти места на новые вакансии!</p>
                    <div className={s.inputBar}>
                        <div style={{flex: "4"}}><Input tone={isDark? "light": null}  margin="0 0 20px 0"
                                                        callBackChangeText={setEmail}
                                                        inputType="email"
                                                        placeholderText="Ваш e-mail" required={false}
                                                        startText={userEmail}
                                                        isUnchangeable={true}
                                                        onSetValid={setEmailIsValid}/></div>
                        <div className={s.btnWrapp} style={{paddingLeft: isMobile ? null :"20px", marginBottom: isMobile ? "20px": null}}>
                                                                                            <Button disabled={!emailIsValid}
                                                                                                variant={4}
                                                                                                btnText="Подписаться!"
                                                                                                width="100%"
                                                                                                onClick={goSubscribe}/>
                            
                        </div>
                    </div>
                    <a href="#">Нажимая кнопку, Вы даете согласие на обработку ваших персональных данных.</a>
                </div>
            </div>
    )
}


export default EmailSubscribeBlock