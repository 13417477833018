import React, {useState} from "react";
import s from "./ChangePasswordBar.module.css"
import {Input} from "../Input/Input";
import {Button} from "../Button/Button";
import close from "../../Assets/Icons/close-outline.svg"
import {editPasswordTH} from "../../Redux/Reducers/AppReducer";

export const ChangePasswordBar = ({onClose, onChangePass, editPassError, editPassStatus}) => {
    const [firstPass, setFirstPass] = useState("")
    const [secondPass, setSecondPass] = useState("")
    const [passIsValid, setPassIsValid] = useState(true)

    const onSendNewPass = () => {
        if (firstPass && secondPass && firstPass === secondPass && passIsValid) {
            onChangePass(firstPass, secondPass)
                .then((result)=> {
                    if(result === "ok") {
                        onClose("ok")
                    }
                })
        }
    }

    return (
        <div className={s.changePassWrapper}>
            <div className={s.subWrapper}>
                    <img className={s.closeIcon} onClick={onClose} src={close}/>
                    <div className={s.changePassForm}>
                        <p className={s.head}>Сменить пароль</p>
                        <Input margin="0 0 20px 0" minLength={6} maxLength={20}  onSetValid={setPassIsValid} callBackChangeText={setFirstPass} tone="light" inputType="password"
                               placeholderText="Новый пароль"/>
                        <Input margin="0 0 20px 0" callBackChangeText={setSecondPass} mismatchPass={secondPass !== firstPass} tone="light" inputType="password"
                               placeholderText="Подтвердите пароль"/>
                        <div className={s.btnWrapp}>
                            <Button onClick={onSendNewPass} variant={4} btnText="Отправить"/>
                        </div>
                        <p className={s.errorText}>{editPassError?.[0]}</p>
                    </div>
            </div>
        </div>
    )
}