import * as React from "react";
import {connect} from "react-redux";
import {RecoveryPassword} from "./RecoveryPassword";
import {editPasswordTH, recoveryPasswordTH, setRecoveryPasswordStatusAC} from "../../Redux/Reducers/AppReducer";


const mapStateToProps = (state) => {
    return {
        recPassError: state.app?.errors?.recoveryPasswordError,
        editPassError: state.app.errors.editPassError
    }
}
class RecoveryPasswordContainer extends React.Component {
    onSendCode = (email = "", code = "") => {
        return this.props.recoveryPasswordTH(email, code)
    }

    sendNewPass = (newPass, newPassRepeat) => {
        return this.props.editPasswordTH(newPass, newPassRepeat)
    }

    onDropError = () => {
        this.props.setRecoveryPasswordStatusAC({status: 'ok', message: ""})
    }
    render() {
        return <RecoveryPassword onDropError = {this.onDropError} editPassError = {this.props.editPassError} recPassError = {this.props.recPassError} sendNewPass = {this.sendNewPass} onSendCode={this.onSendCode} onClose={this.props.onClose} />
    }
}

export default connect(mapStateToProps, {recoveryPasswordTH, setRecoveryPasswordStatusAC, editPasswordTH})(RecoveryPasswordContainer)