import * as React from "react";
import {connect} from "react-redux";
import {ChoiceDirectionBar} from "./ChoiceDirectionBar";


const mapStateToProps = (state) => {
    return {
        directions: state.app.directions
    }
}

class AppContainer extends React.Component {
    render() {
        return <ChoiceDirectionBar isMobile={this.props.isMobile}  directions={this.props.directions} color={this.props.color}/>
    }
}

export default connect(mapStateToProps, {})(AppContainer)