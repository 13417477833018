import React, {useEffect, useState} from "react";
import s from "./Probations.module.css"
import happyStaffImg from "../../Assets/pageImages/ProbatiosPageImages/happyStaffImg.png"
import okBoomerImg from "../../Assets/pageImages/ProbatiosPageImages/okBoomerImg.png"
import {Heading} from "../../Components/Heading/Heading";
import ChoiceDirectionBarContainer from "../../Components/ChoiceDirectionBar/ChoiceDirectionBarContainer";

import photo1 from "../../Assets/SlidersImages/reviewPeople1.png"
import photo2 from "../../Assets/SlidersImages/reviewPeople2.png"
import photo3 from "../../Assets/SlidersImages/reviewPeople3.png"
import photo4 from "../../Assets/SlidersImages/reviewPeople4.png"

import ProbationStepsBlock from "../../Components/ProbationSteps/ProbationStepsBlock";
import SendResumeBarContainer from "../../Components/SendResumeBar/SendResumeBarContainer";
import ReviewsSlider from "../../Components/slickSlider/ProbationsSliders/ReviewsSlider/ReviewsSlider";
import InstitutesSlider from "../../Components/slickSlider/ProbationsSliders/InstitutesSlider/InstitutesSlider";
import EmailSubscribeBlockContainer from "../../Components/EmailSubscribeBlock/EmailSubscribeBlockContainer";
import SearchBarContainer from "../../Components/SearchBar/SearchBarContainer";
import close from "../../Assets/Icons/close-outline.svg";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
import TextModal from "../../Components/TextModal/TextModal";
import DocumentMeta from "react-document-meta";

const reviewsText = [
    {
        name:"Икбол Худжов",
        position:"Инженер по эксплуатации транспортной сети",
        text: `Осенью 2021 года я в течение месяца проходил стажировку в компании «МегаФон Таджикистан». С первого же дня приступил к интересным задачам, сложность которых постепенно возрастала.`,
        moreText: `Осенью 2021 года я в течение месяца проходил стажировку в компании «МегаФон Таджикистан». С первого же дня приступил к интересным задачам, сложность которых постепенно возрастала. Благодаря этому – и замечательному коллективу тоже – мое желание развиваться в этой сфере только окрепло.
        Очень хочу поблагодарить Фируза Косимова. Его советы помогли мне выявить и свои сильные стороны, и те, над которыми стоит ещё поработать. За время стажировки я получил много полезных навыков и знаний. Уверен, что они пригодится мне в дальнейшем профессиональном развитии. Стажировка превзошла все мои ожидания. Искренняя благодарность всему коллективу отдела эксплуатации транспортных сетей!`,
        avatar:photo2
    },
    {
        name:"Гулбахор Сангова",
        position:"Android-разработчик",
        text: `Во время стажировки в компании я многому научилась: принципам Android Material design, отправке http-запросов, работе с Rest-api и базами данных с помощью библиотеки Room.`,
        moreText: `Во время стажировки в компании я многому научилась: принципам Android Material design, отправке http-запросов, работе с Rest-api и базами данных с помощью библиотеки Room. Научилась работать с такими форматами данных, как XML, CSS, JSON. Узнала про архитектуру MVVM. Работала с Github. Изучала язык kotlin. Реализовала такие скрины в приложении Life 3.0, как нотификация. Создала мультиадаптер для скрина «Ещё», реализовала скрины «Добавить аккаунт», «Выгода», работала над скринами «Мои услуги» и «Выбор тарифа». 
        Мне попался очень хороший руководитель, который за короткое время научил многому. Он помогал мне на всем протяжении моей стажировки. Что огорчило? Может быть, только долгое оформление документов. Мне не сразу сделали пропуск, одно время просила кого-нибудь встретить и пропустить меня в офис.`,
        avatar:photo1
    },
    {
        name:"Шерзод Саидов",
        position:"Инженер по эксплуатации сервисных платформ",
        text: `В начале своей карьеры в компании «МегаФон Таджикистан» я был сотрудником Контактного центра, обслуживал абонентов на первой линии. `,
        moreText: `В начале своей карьеры в компании «МегаФон Таджикистан» я был сотрудником Контактного центра, обслуживал абонентов на первой линии. Но со временем захотелось большего, профессионального роста в соответствии с тем образованием, которое я получил в университете. 
        Как раз в это время появилась вакансия инженера в отделе сервисных платформ. Изначально я не знал, какие навыки требуются, и коллеги из этого отдела посоветовали мне пройти стажировку. Так я начал изучать структуру сети, протоколы, прохождение вызовов, развивался в таких направлениях, как СМС/USSD/IVR. Было много интересных проектов, которые способствуют профессиональному росту. 
        После стажировки я успешно прошел собеседование, сейчас являюсь сотрудником отдела сервисных платформ.`,
        avatar:photo4
    },
    {
        name:"Фаррух Шодиев",
        position:"Инженер по эксплуатации транспортной сети",
        text: `На стажировке я приобрёл ценные знания, как теоретические, так и практические. Конечно, сталкивался с трудностями, но мои наставники всегда давали обратную связь и делились своим опытом.`,
        moreText: `На стажировке я приобрёл ценные знания, как теоретические, так и практические. Конечно, сталкивался с трудностями, но мои наставники всегда давали обратную связь и делились своим опытом. У нашей команды были интересные задачи: MegaFest, настройка Wi-Fi-устройств с последующим их монтажом в парках. За это время познакомился со многими в коллективе, мне нравится атмосфера в офисе в целом. Прошло всего два месяца, а я чувствую себя более опытным, чем был. `,
        avatar:photo3
    },
]




export const Probations = ({isMobile, userSubscribe, authorized}) => {

    const [reviewsModal, setReviewsModal] = useState(-1)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const meta = {
        description: 'Лучшие возможности для старта карьеры! Получите бесценный опыт работы в большой международной компании - лидере телеком индустрии!',
        }

        
    return (
        <div className={s.wrapper}>
            
            <DocumentMeta {...meta} /> 
            <div className={s.headBar}>
                {!isMobile ?<div className={s.headImgBox}>
                    <img src={happyStaffImg} alt="happyStaff"/>
                </div>: null}
                <div className={s.headText}>
                    <p>Возможности</p>
                    <Heading headText="начинаются с тебя"/>
                </div>
                {isMobile ?<div className={s.headImgBox}>
                    <img src={happyStaffImg} alt="happyStaff"/>
                </div>: null}
            </div>
            <div className={s.breadCrumbsBar}><BreadCrumbs steps = {[["Главная","/"],["Стажировка"]]}/></div>
            <div className={s.choiceDirBar}>
                <ChoiceDirectionBarContainer isMobile = {isMobile} color="#731982"/>
            </div>
            
            <div className={s.okBoomerBar}>
                <div className={s.okBoomerImg}>
                    <img src={okBoomerImg} alt="okBoomer"/>
                </div>
                <div className={s.okBoomerText}>
                    <Heading headText="Для кого наши стажировки" text="От опытных специалистов до вчерашних студентов, от тех,
                     кто делает свои первые шаги в карьере, до бывалых “ремесленников”- каждый может стать частью МегаФон пополнив
                      ряды стажеров! Только вступаете в профессию - прекрасно, мы подберём опытного наставника и поможем во всем разобраться.
                       Вы много лет в бизнесе, но хотите погрузиться в новые для себя сферы - не беда, с нашей помощью у Вас получится раскрыть
                        свои сильные стороны в новой сфере! Выбирайте интересующую программу и добро пожаловать!" color="black" textWidth={!isMobile?"85%": "100%"}/>
                </div>
            </div>
            <p className={s.searchHead}>Поиск программ</p>
            <div className={s.searchProgramBar}>
                <SearchBarContainer isMobile = {isMobile} listType = {1}/> {/*listType определяет тип контента, где 0 - вакансии, 1 - стажировки*/}
            </div>
            <div className={s.probationStepsBar}>
                <ProbationStepsBlock/>
            </div>
            <div className={s.sendResumeBar}>
                <SendResumeBarContainer tone = "dark" isMobile = {isMobile}/>
            </div>
            <div className={s.reviewsSliderBar}>
                <div className={s.reviewsSliderBarHead}><Heading headText="Отзывы прошедших стажировку в МегаФон" color="#731982"/></div>
                <ReviewsSlider setReviewsModal = {setReviewsModal} reviews = {reviewsText}/>
            </div>
            <div className={s.institutesBar}>
                <div className={s.institutesHead}>
                    <Heading headText="Студенты ведущих ВУЗов строят свою карьеру в МегаФон" color="#731982" width={!isMobile ? "50%": "100%"}/>
                </div>
                <div className={s.institutesSliderBar}>
                    <InstitutesSlider/>
                </div>
            </div>
            <div className={s.subscribeBar}>
                {!userSubscribe && authorized?<EmailSubscribeBlockContainer isDark={true} isMobile={isMobile}/>: null}
            </div>
            {reviewsModal !== -1 ? (
                <TextModal open={reviewsModal !== -1} onClose={()=>{
                    setReviewsModal(-1)
                }}>
                    <div className={s.reviewsText}>
                        <p>{reviewsText[reviewsModal].text} {reviewsText[reviewsModal].moreText}</p>
                    </div>
                </TextModal>
            ) : null}
            
            
        </div>
    )
}

