import s from "./RecoveryPassword.module.css"
import React, {useEffect, useState} from "react";
import {Input} from "../Input/Input";
import {Button} from "../Button/Button";
import close from "../../Assets/Icons/close-outline.svg";

export const RecoveryPassword = ({onSendCode, onClose, recPassError, onDropError, sendNewPass, editPassError}) => {
    const [email, setEmail] = useState("")
    const [code, setCode] = useState("")
    const [emailIsValid, setEmailIsValid] = useState(false)
    const [errorText, setErrorText] = useState("")
    const [showRequired, setShowRequired] = useState(false)
    const [writeCodeInputIsOpen, setWriteCodeInputIsOpen] = useState(false)
    const [writePassInputsIsOpen, setWritePassInputsIsOpen] = useState(false)

    const [firstPass, setFirstPass] = useState("")
    const [secondPass, setSecondPass] = useState("")
    const [passIsValid, setPassIsValid] = useState(true)

    useEffect(() => {
        if (recPassError?.[0] || editPassError?.[0]) {
            setErrorText(editPassError?.[0] || recPassError?.[0])
        }
    }, [recPassError, editPassError])

    const onSendData = () => {
        if (!writeCodeInputIsOpen) {
            if (email && emailIsValid) {
                onSendCode(email)
                    .then((status) => {
                        if (status === "ok") {
                            setErrorText("")
                            setWriteCodeInputIsOpen(true)
                        }
                    })
            } else if (!email) {
                setShowRequired(true)
            }
        } else {
            if (code.length !== 4) {
                setErrorText("Код состоит из 4 символов")
            } else {
                setErrorText("")
                onSendCode("", code)
                    .then((result) => {
                        if (result === "ok") {
                            setErrorText("")
                            setWriteCodeInputIsOpen(false)
                            setWritePassInputsIsOpen(true)
                        }
                    })
            }
        }

    }

    const onSendNewPass = () => {
        if (firstPass && secondPass && firstPass === secondPass && passIsValid) {
            sendNewPass(firstPass, secondPass)
                .then((result) => {
                    if (result === "ok") {
                        setErrorText("")
                        onClose()
                    }
                })
        }
    }


    useEffect(() => {
        if (email) {
            onDropError()
        }
    }, [email])

    const [seconds, setSeconds] = useState(60)
    const [sendBlocked, setSendBlocked] = useState(false)
    let intervalLink;
    useEffect(() => {
        if (writeCodeInputIsOpen) {
            setSendBlocked(true)
            startTimer()
            return () => clearInterval(intervalLink)
        }
    }, [writeCodeInputIsOpen])
    const startTimer = () => {
        intervalLink = setInterval(() => {
            setSeconds((seconds) => {
                if (seconds > 0) {
                    return seconds - 1
                } else {
                    clearInterval(intervalLink)
                    setSendBlocked(false)
                    setSeconds(60)
                }
            })
        }, 1000)
    }
    return (
        <div className={s.wrapper}>
            <div className={s.subWrapper}>
                <p className={s.head}>Восстановление пароля</p>
                <img className={s.closeIcon} onClick={() => {
                    onClose()
                }} src={close}/>
                <div className={s.form}>
                    {!writePassInputsIsOpen ?<div>
                        {!writeCodeInputIsOpen ?
                            <div>
                                <p className={s.subHead}>Введите ваш Email</p>
                                <Input tone="light" margin="0 0 20px 0" callBackChangeText={setEmail} inputType="email"
                                       placeholderText="Введите Email" showRequired={showRequired} startText=""
                                       required={true} onSetValid={setEmailIsValid}/></div> :
                            <div>
                                <p className={s.subHead}>Введите код, отправленный на {email}</p>
                                {sendBlocked ?
                                    <p className={s.timerText}>Вы сможете повторно отправить
                                        код через {seconds}с</p> :
                                    <div className={s.resendText}><p className={s.sendCodeAgain}
                                                                                                onClick={() => {
                                                                                                    onSendCode(email)
                                                                                                    setSendBlocked(true)
                                                                                                    startTimer()
                                                                                                }}>Отправить
                                        код повторно</p></div>}
                                <Input tone="light" margin="0 0 20px 0" inputType="text" placeholderText="Код подтверждения"
                                       callBackChangeText={setCode}/>
                            </div>}
                    </div>: null}
                    {writePassInputsIsOpen ? <div>
                        <p className={s.subHead}>Введите новый пароль</p>
                        <Input margin="0 0 20px 0" minLength={6} maxLength={20} onSetValid={setPassIsValid}
                               callBackChangeText={setFirstPass} tone="light" inputType="password"
                               placeholderText="Новый пароль"/>
                        <Input margin="0 0 20px 0" callBackChangeText={setSecondPass}
                               mismatchPass={secondPass !== firstPass} tone="light" inputType="password"
                               placeholderText="Подтвердите пароль"/>
                        <div className={s.btnWrapp}>
                            <Button onClick={onSendNewPass} variant={4} btnText="Отправить"/>
                        </div>
                    </div> : null}
                    <div className={s.btnWrapp}>
                        {! writePassInputsIsOpen ?<Button
                            margin="20px 0 10px 0" variant={4} onClick={onSendData}
                            btnText={writeCodeInputIsOpen ? "Отправить код" : "Получить код подтверждения"}/>: null}
                    </div>
                </div>
                <p className={s.errorText}>{errorText || ""}</p>
            </div>
        </div>
    )
}