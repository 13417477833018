import s from "./BreadCrumbs.module.css"
import {NavLink} from "react-router-dom";



const CrumbArrow = ({color}) =>(
    <svg style={{marginLeft:"10px"}} width="10" height="15" viewBox="0 0 27 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="27.8555" height="5.57109" rx="2" transform="matrix(-0.774457 0.632627 -0.774457 -0.632627 26.0508 18.2754)" fill={color}/>
        <rect width="28.2684" height="5.57109" rx="2" transform="matrix(-0.774457 -0.632627 0.774457 -0.632627 21.9556 21.4077)" fill={color}/>
    </svg>

)



const BreadCrumbs = ({steps, inActiveColor = "#00b856", activeColor = "#731982",
                      marginLeft = "0px",marginBottom = "0px", marginTop = "0px"}) => {
    return (
        <div className={s.row}
             style={{marginLeft,marginBottom,marginTop}}>
            {steps.map((s,i) => <div key={i}>
                <NavLink style={{color:i < steps.length - 1 ? inActiveColor : activeColor}}
                    to={s.length > 1 ? s[1]: ""}>
                    {s[0]}
                </NavLink>
                {i < steps.length - 1 ? 
                 <CrumbArrow color={i < steps.length - 2 ? inActiveColor : activeColor}/>:
                null}
                </div>
            )}
        </div>
    )
}


export default BreadCrumbs