import React from "react";
import {connect} from "react-redux";
import {Vacancies} from "./Vacancies";

const mapStateToProps = (state) => {
    return {
        userSubscribe: state.app.userSubscribe,
        authorized: state.app.authorized
    }
}

const VacanciesContainer = (props) => {
    return <Vacancies authorized={props.authorized} userSubscribe = {props.userSubscribe} isMobile={props.isMobile}/>
}

export default connect(mapStateToProps, {})(VacanciesContainer)