import React, {Component, useState} from "react";
import Slider from "react-slick";
import s from "./HistoriesSlider.module.css";
import photo1 from "../../../../Assets/pageImages/aboutCompanyPageImages/photo1.png"
import photo2 from "../../../../Assets/pageImages/aboutCompanyPageImages/photo2.png"
import photo3 from "../../../../Assets/pageImages/aboutCompanyPageImages/photo3.png"
import photo4 from "../../../../Assets/pageImages/aboutCompanyPageImages/photo4.png"
import photo5 from "../../../../Assets/pageImages/aboutCompanyPageImages/photo5.png"
import photo6 from "../../../../Assets/pageImages/aboutCompanyPageImages/photo6.png"
import photo7 from "../../../../Assets/pageImages/aboutCompanyPageImages/photo7.png"
import close from "../../../../Assets/Icons/close-outline.svg";

export default class HistoriesSlider extends Component {

    componentDidMount() {
        window.addEventListener("resize", this.resizeMethod)
    }

    resizeMethod = () => {
        let width = window.innerWidth
        this.setState({screenWidth: width - 20})
        this.setState({isMobile: width <= 900})
    }


    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeMethod)
    }

    constructor(props) {
        super(props);
        this.state = {
            activeItem: 0,
            screenWidth: window.innerWidth - 20,
            isMobile: window.innerWidth <= 900,
        }
    }

    onGoNextSlide = (index) => {
        this.setState({activeItem: index})
    }

    render() {
        const settings = {
            arrows: false,
            lazyLoad: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: false,
            autoplay: true,
            autoplaySpeed: 4000,
            afterChange: this.onGoNextSlide,
            swipeToSlide: true

        };
        const storiesText = this.props.stories

        return (
            <div style={{maxWidth: `${this.state.screenWidth}px`, margin: "0 auto", position: "relative"}}>
                <Slider {...settings}>
                    <div>
                        <div>
                            <HistorySlide photo={photo1} name="Комрон Гадоев"
                                          jobPosition="Руководитель отдела технического управления"
                                          startText={storiesText[0].startText} moreText={storiesText[0].moreText} id={0}
                                          openStory={this.props.setStoryModal}/>
                        </div>
                    </div>
                    <div>
                        <div>
                            <HistorySlide photo={photo2} name="Азамджон Джонов"
                                          jobPosition="Руководитель службы сервисных платформ и роуминга"
                                          startText={storiesText[1].startText} moreText={storiesText[1].moreText} id={1}
                                          openStory={this.props.setStoryModal}/>
                        </div>
                    </div>
                    <div>
                        <div>
                            <HistorySlide photo={photo3} name="Раим Садоншоев"
                                          jobPosition="Директор регионального управления по ГБАО"
                                          startText={storiesText[2].startText} moreText={storiesText[2].moreText}
                                          id={2} openStory = {this.props.setStoryModal}/>
                        </div>
                    </div>
                    <div>
                        <div>
                            <HistorySlide photo={photo4} name="Фирдавс Шамсуддинов" jobPosition="Коммерческий руководитель регионального управления по
                                    Хатлонской области" startText={storiesText[3].startText}
                                          moreText={storiesText[3].moreText} id={3} openStory = {this.props.setStoryModal}/>
                        </div>
                    </div>
                    <div>
                        <div>
                            <HistorySlide photo={photo5} name="Алиджон Ганиев"
                                          jobPosition="Руководитель службы развития и эксплуатации ФШПД"
                                          startText={storiesText[4].startText} moreText={storiesText[4].moreText}
                                          id={4} openStory = {this.props.setStoryModal}/>

                        </div>
                    </div>
                    <div>
                        <div>
                            <HistorySlide photo={photo6} name="Юрий Глущенко"
                                          jobPosition="Руководитель службы новых бизнесов и партнерств"
                                          startText={storiesText[5].startText} moreText={storiesText[5].moreText}
                                          id={5} openStory = {this.props.setStoryModal}/>
                        </div>
                    </div>
                    <div>
                        <div>
                            <HistorySlide photo={photo7} name="Комрон Кенжаев" jobPosition="Коммерческий руководитель регионального управления
                                    по г.Душанбе и РРП" startText={storiesText[6].startText}
                                          moreText={storiesText[6].moreText} id={6} openStory = {this.props.setStoryModal}/>
                        </div>
                    </div>
                </Slider>
                <div style={{
                    position: "absolute",
                    bottom: "-20px",
                    display: "flex",
                    justifyContent: "center",
                    width: "100%"
                }}>
                    <div style={{
                        display: "flex",
                        backgroundColor: "rgba(0, 184, 86, 0.5)",
                        padding: "5px",
                        borderRadius: "10px"
                    }}>
                        <div className={this.state.activeItem === 0 ? s.activePage : s.pageElement}/>
                        <div className={this.state.activeItem === 1 ? s.activePage : s.pageElement}/>
                        <div className={this.state.activeItem === 2 ? s.activePage : s.pageElement}/>
                        <div className={this.state.activeItem === 3 ? s.activePage : s.pageElement}/>
                        <div className={this.state.activeItem === 4 ? s.activePage : s.pageElement}/>
                        <div className={this.state.activeItem === 5 ? s.activePage : s.pageElement}/>
                        <div className={this.state.activeItem === 6 ? s.activePage : s.pageElement}/>
                    </div>
                </div>
            </div>
        );
    }
}

const HistorySlide = ({photo, name, jobPosition, startText, moreText, id, openStory}) => {
    return (<div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
        <img style={{width: "90%", marginBottom: "75px"}} src={photo} alt=""/>
        <div className={s.textBar}>
            <div style={{marginBottom: "20px"}}><p className={s.name}>{name},</p>
                <p className={s.jobPosition}>{jobPosition}</p></div>
            <p className={s.story}>{startText} <br/>
                <span onClick={() => openStory(id)} className={s.showMoreText}>Читать далее...</span>
            </p>
        </div>
    </div>)
}