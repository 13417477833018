import React, {Component} from "react";
import Slider from "react-slick";
import inst1 from "../../../../Assets/SlidersImages/inst1.svg"
import inst2 from "../../../../Assets/SlidersImages/inst2.svg"
import inst3 from "../../../../Assets/SlidersImages/inst3.svg"
import inst4 from "../../../../Assets/SlidersImages/inst4.svg"
import inst5 from "../../../../Assets/SlidersImages/inst5.svg"
import inst6 from "../../../../Assets/SlidersImages/inst6.svg"
import s from "./InstitutesSlider.module.css";
import next from "../../../../Assets/Icons/showNextSlide.svg";
import previous from "../../../../Assets/Icons/showPreviousSlide.svg";

const photos = [
    inst1,
    inst2,
    inst3,
    inst4,
    inst5,
    inst6,
    
]

function SampleNextArrow(props) {
    const {className, style, onClick, subOnClick} = props;
    return (
        <div style={{...style}} onClick={() => {
            onClick()
            subOnClick()
        }} className={`${className} ${s.nextArrow}`}>
            <img src={next} alt=""/>
        </div>
    );
}

function SamplePrevArrow(props) {
    const {className, style, onClick, subOnClick} = props;
    return (
        <div style={{...style}} onClick={() => {
            onClick()
            subOnClick()
        }} className={`${className} ${s.prevArrow}`}>
            <img src={previous} alt=""/>
        </div>
    );
}

export default class InstitutesSlider extends Component {

    componentDidMount() {

        window.addEventListener("resize", this.resizeMethod)
    }

    resizeMethod = () => {
        let width = window.innerWidth
        this.setState({screenWidth: width - 20})
        this.setState({isMobile: width <= 900})
    }


    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeMethod)
    }

    constructor(props) {
        super(props);
        this.state = {
            activeItem: 1,
            screenWidth: window.innerWidth - 20,
            isMobile: window.innerWidth <= 900,
        }
    }

    onGoNextSlide = (index) => {
        this.setState({activeItem: index !== photos.length - 1 ? index +1 : 0})
    }

    render() {
        

        const settings = {
            arrows: true,
            lazyLoad: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            adaptiveHeight: false,
            autoplay: true,
            autoplaySpeed: 4000,
            afterChange: this.onGoNextSlide,
            nextArrow: <SampleNextArrow subOnClick={this.onGoNextSlide}/>,
            prevArrow: <SamplePrevArrow subOnClick={this.onGoNextSlide}/>,
            swipeToSlide: true,
            responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                }
            },
            ]
        };

        return (
            <div style={{maxWidth: `${this.state.screenWidth}px`, margin: "0 auto", position: "relative"}}>
                <Slider {...settings}>
                    {photos.map((p,i)=>(
                        <div key={i}>
                            <div className={`${s.slideWrapp} ${this.state.activeItem === i ? s.activeSlide: ""}`}>
                                
                                <img src={p} alt=""/>
                                
                            </div>
                        </div>
                    ))}
                </Slider>
                <div style={{
                    position: "absolute",
                    bottom: "-70px",
                    display: "flex",
                    justifyContent: "center",
                    width: "100%"
                }}>
                    <div style={{
                        display: "flex",
                        padding: "5px",
                        borderRadius: "10px"
                    }}>
                        {photos.map((p,i)=>(
                            <div key={i} className={this.state.activeItem === i ? s.activePage : s.pageElement}/>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}