import * as React from "react";
import {connect} from "react-redux";
import {AboutCompany} from "./AboutCompany";


const mapStateToProps = (state) => {
    return {
        userSubscribe: state.app.userSubscribe,
        authorized: state.app.authorized
    }
}

class AboutCompanyContainer extends React.Component {
    componentDidMount() {

    }

    render() {
        return(<AboutCompany authorized = {this.props.authorized} userSubscribe = {this.props.userSubscribe} isMobile ={this.props.isMobile}/>)
    }
}

export default connect (mapStateToProps, {})(AboutCompanyContainer)