
import closeIcon from "../../Assets/Icons/close-outline.svg";
import s from "./TextModal.module.css"





const TextModal = ({open,onClose, children, full = false}) =>{

    const close = () =>{
        if(onClose)
            onClose()
    }
    return open ? (
    <div className={s.reviewsWrapper} >
        <div className={s.clickableZone}
                 onClick = {close}></div>
        <div className={s.reviewsSubWrapper} 
             style={full ? {width:"80%", maxHeight:"80%", marginTop:"0px"} : null}>
            <img className={s.closeIcon} onClick={close} src={closeIcon}/>
            {children}
        </div>
    </div>
    ) : null
}

export default TextModal