import './App.css';
import React, {useEffect, useState} from "react"
import {ToastsContainer, ToastsStore} from "react-toasts";
import "react-datepicker/dist/react-datepicker.css";
import {Footer} from "./Components/Footer/Footer";
import HomeContainer from "./Pages/Home/HomeContainer";
import AboutCompanyContainer from "./Pages/AboutCompany/AboutCompanyContainer";
import {Outlet, Route, Routes} from "react-router";
import {Registration} from "./Pages/Registration/Registration";
import ProbationsContainer from "./Pages/Probations/ProbationsContainer";
import VacanciesContainer from "./Pages/Vacancies/ VacanciesContainer";
import MenuContainer from "./Components/Menu/MenuContainer";
import ProfileContainer from "./Pages/Profile/ProfileContainer";
import AdminPageContainer from "./Components/AdminPageContainer/AdminPageContainer"
import CitiesPage from './Admin/Pages/CitiesPage/CitiesPage';
import SpecializationDirection from './Admin/Pages/SpecializationDirection/SpecializationDirection';
import VacanciesAdmin from './Admin/Pages/Vacancies/Vacancies';
import ProbationInfoContainer from "./Pages/ProbationInfo/ProbationInfoContainer";
import VacancyInfoContainer from "./Pages/VacancyInfo/VacancyInfoContainer";
import MailSender from './Admin/Pages/MailSender/MailSender';

import AuthAdmin from "./Admin/Pages/Auth/Auth"
import ListVacancies from './Admin/Pages/ListVacancies/ListVacancies';
import RequestesPage from './Admin/Pages/Requestes/Requestes';
import { Users } from './Admin/Pages/Users/Users';
import Resumes from './Admin/Pages/Resumes/Resumes';
import Loader from './Components/Loader/Loader';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';


function App({authorized, isLoad}) {

    const [isMobile, toggleIsMobile] = useState(false)

    useEffect(() => {
        toggleIsMobile(window.innerWidth <= 900)
        window.addEventListener("resize", () => {
            toggleIsMobile(window.innerWidth <= 900)
        })
    }, [])



    return (
            <div className="App">
                <Routes>
                    <Route path = "/" element={<Layout authorized = {authorized} isMobile = {isMobile} isLoad = {isLoad}/>}>
                        <Route index element={<HomeContainer isMobile = {isMobile}/>}/>
                        <Route path="o-kompanii" element={<AboutCompanyContainer isMobile = {isMobile}/>}/>
                        <Route  path="registracziya" element={<Registration isMobile = {isMobile}/>}/>
                        <Route  path="stazhirovka/:vacProbId" element={<ProbationInfoContainer contentType = {1} isMobile = {isMobile}/>}/>{/*от contentType завизит, что именно подгрузится (0 - вакансии; 1 - стажировки)*/}
                        <Route  path="vakansiya/:vacProbId" element={<VacancyInfoContainer contentType = {0} isMobile = {isMobile}/>}/>
                        <Route  path="vakansii" element={<VacanciesContainer isMobile = {isMobile}/>}/>
                        <Route  path="vakansii/:directionId" element={<VacanciesContainer isMobile = {isMobile}/>}/>
                        <Route  path="stazhirovki" element={<ProbationsContainer isMobile = {isMobile}/>}/>
                        <Route  path="stazhirovki/:directionId" element={<ProbationsContainer isMobile = {isMobile}/>}/>

                        <Route  path="profil" element={<ProfileContainer isMobile = {isMobile}/>}/>
                        
                        <Route  path="*" element={<HomeContainer isMobile = {isMobile}/>}/>
                        <Route path='admin_log' element={<AuthAdmin/>}/>
                        <Route path='/politika_konfidencialnosti' element={<PrivacyPolicy/>}/>
                    </Route>
                    <Route path = "/admin/" element = {<AdminPageContainer/>}>
                        <Route path='cities' element={<CitiesPage/>}/>
                        <Route path='directions' element={<SpecializationDirection/>}/>
                        <Route path='vacancy' element={<VacanciesAdmin/>}/>
                        <Route path='probation' element={<VacanciesAdmin vac={false}/>}/>
                        <Route path='mails' element={<MailSender/>}/>
                        <Route path='vacancy_list' element={<ListVacancies/>}/>
                        <Route path='probation_list' element={<ListVacancies vac={1}/>}/>
                        <Route path='requests' element={<RequestesPage/>}/>
                        <Route path='users' element={<Users/>}/>
                        <Route path='resumes' element={<Resumes/>}/>
                    </Route>
                </Routes>
            </div>
    );
}

export const Layout = ({isMobile, authorized, isLoad}) => {
    return <div className="MAIN_WRAPPER">
        <MenuContainer/>
        
        
        <Outlet/>
        <ToastsContainer position="bottom_right" store={ToastsStore}/>
        <Footer authorized = {authorized} isMobile={isMobile}/>
        <Loader isLoad={isLoad}/>
    </div>

}

export default App;
