import * as React from "react";
import {connect} from "react-redux";
import {Profile} from "./Profile";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";


const mapStateToProps = (state) => {
    return {
        userResumes: state.app.userResumes,
        userVacResponses: state.app.userVacanciesResponses,
        userProbResponses: state.app.userProbationsResponses,
        userFeatVacancies: state.app.userFeaturedVacancies,
        userSubscribe: state.app.userSubscribe,
        authorized: state.app.authorized,
        userTests: state.app.userTests
    }
}

class ProfileContainer extends React.Component {

    render() {
        return <Profile
            userSubscribe = {this.props.userSubscribe}
            userResumes = {this.props.userResumes} userVacResponses = {this.props.userVacResponses}
            userProbResponses = {this.props.userProbResponses}
            userFeatVacancies = {this.props.userFeatVacancies}
            userTests = {this.props.userTests}
            isMobile = {this.props.isMobile}
            authorized = {this.props.authorized}
        />
    }
}

export default connect(mapStateToProps, {})(ProfileContainer)