import React, {useEffect, useState} from "react";
import s from "./SendResumeBar.module.css"
import {Input} from "../Input/Input";
import {ChoiceBar} from "../ChoiceBar/ChoiceBar";
import {Button} from "../Button/Button";
import close from "../../Assets/Icons/close-outline.svg";
import {useNavigate} from "react-router-dom";

export const SendResumeBar = ({
                                  isMobile, userName, userCity, directions, onSendResume,
                                  tone, isModal, onClose, sendResumeError, isEditMode,
                                  selectedDirections, resumeLink, authorized
                              }) => {
    const [name, setName] = useState(userName)
    const [city, setCity] = useState(userCity)
    const [link, setLink] = useState("")
    const [file, setFile] = useState(undefined)
    const [selectDirections, setSelectDirections] = useState(selectedDirections || [])
    const [selectedResumeDirections, setSelectedResumeDirections] = useState(selectedDirections)

    useEffect(() => {
        setName(userName)
        if (isEditMode){
            setCity(userCity)
            setSelectDirections(selectedDirections)
            setSelectedResumeDirections(selectedDirections)
        }
    }, [userName, userCity, selectedDirections])


    const [errorText, setErrorText] = useState("")

    const onSelectDirections = (id) => {
        let newSelDirs = [...selectDirections]
        if (newSelDirs.find((i) => i === id) || newSelDirs.find((i) => i === id) === 0) {
            setSelectDirections(newSelDirs.filter((i) => i !== id))
        } else {
            newSelDirs.push(id)
            setSelectDirections(newSelDirs)
        }
    }
    let navigate = useNavigate()

    const onSend = () => {
        if (authorized) {
            if ((file || link) && city && selectDirections.length > 0 && name) {
                if (file) {
                    onSendResume(name, city, file, selectDirections)
                        .then((result) => {
                            if (result === "ok") {
                                onClose("ok")
                            }
                        })
                    setErrorText("")
                } else {
                    if (link) {
                        onSendResume(name, city, link, selectDirections)
                            .then((result) => {
                                if (result === "ok") {
                                    onClose("ok")
                                }
                            })
                        setErrorText("")
                    }
                }
            } else if (!name) {
                setErrorText("ФИО не может быть пустым")
            } else if (!city) {
                setErrorText("Город не может быть пустым")
            } else if (selectDirections.length === 0) {
                setErrorText("Выберите интересующие вас направления")
            } else if (!file && !link) {
                setErrorText("Всавьте файл или ссылку на ваше резюме")
            }
        }
        else {
            navigate("/registracziya")
        }
    }
    return (
        <div style={{backgroundColor: tone === "dark" ? "#F7F7F7" : null}}
             className={!isModal ? s.wrapper : s.wrapperModal}>
                 {isModal ? <div className={s.clickableZone}
                 onClick={()=>{
                    onClose()
                 }}></div> : null}
            <div className={!isModal ? null : s.subWrapperModal}>
                {isModal ? <img className={s.closeIcon} alt="close" onClick={() => {
                    onClose()
                }} src={close}/> : null}
                <p className={s.head}>{isEditMode ? "Редактирование резюме" : "Отправьте нам свое резюме, мы с Вами свяжемся!"}</p>
                <Input tone={tone === "dark" ? "light" : null} margin="0 0 28px 0" callBackChangeText={setName}
                       startText={userName} inputType="text"
                       placeholderText="ФИО"/>
                <Input tone={tone === "dark" ? "light" : null} margin="0 0 28px 0" callBackChangeText={setCity}
                       startText={city} inputType="text"
                       placeholderText="Город"/>

                <p className={s.subHead}>{!isEditMode ?"Выберите интересное для Вас направление работы": "Выбранные направления:"}</p>
                <ChoiceBar isManyChoice={true} selectedItemsIds={selectedResumeDirections} onChange={onSelectDirections}
                           items={directions}/>

                <p className={s.subHead}>{!isEditMode ? "Прикрепите файл резюме в форматах pdf или word, либо вставьте ссылку на него" : "Прикрепите новый файл резюме, либо вставьте ссылку на него"}</p>
                <Input tone={tone === "dark" ? "light" : null} margin="0 0 0 0" callBackChangeFile={setFile}
                       callBackChangeText={setLink}
                       placeholderText="Ваш файл или ссылка"
                       inputType="fileOrText"/>
                {isEditMode ? <div className={s.getLinkBar}>
                    <a className={s.loadFile} target="_blank" href={resumeLink}>Скачать текущий файл</a>
                </div>: null}

                {/* {!isMobile ?
                    <a href="#">Нажимая кнопку, Вы даете согласие на обработку ваших персональных данных.</a> : null} */}
                <div className={s.btnWrapp} style={{
                    display: "flex",
                    marginTop: "30px",
                    justifyContent: "center",
                    marginBottom: isMobile ? "23px" : null
                }}><Button
                    width="100%"
                    onClick={onSend}
                    btnText={isEditMode ? "Сохранить" : "Отправить"}
                    variant={4}/></div>
                <p className={s.errorText}>{sendResumeError?.[0] || errorText}</p>
                {/* {isMobile ?
                    <a href="#">Нажимая кнопку, Вы даете согласие на обработку ваших персональных данных.</a> : null} */}
            </div>
        </div>
    )
}