import {
    addToFeatured,
    authorization, confirmEmail, editFullResume,
    editPassword, editProfile, editResume, exit, getDirections,
    getProfileInfo, getRegions, getResumeInfo, getSpecializations, getVacancies, getVacancyProbation, recoveryPassword,
    registration, resendCode,
    sendApplication, sendFullResume,
    sendResume, subscribeSender
} from "../../DAL/ApiRequests";
import {ToastsStore} from "react-toasts";
const TOGGLE_IS_LOAD = "TOGGLE_IS_LOAD"
const SET_AUTHORIZED = "SET_AUTHORIZED"
const SET_VACANCIES = "SET_VACANCIES"
const SET_MORE_VACANCIES = "SET_MORE_VACANCIES"
const SET_CONFIRM_EMAIL_STATUS = "SET_CONFIRM_EMAIL_STATUS"
const SIGN_OUT = "SIGN_OUT"
const SET_REGISTRATION_STATUS = "SET_REGISTRATION_STATUS"
const SET_CONFIRM_EMAIL_IN_PROGRESS = "SET_CONFIRM_EMAIL_IN_PROGRESS"
const SET_PROFILE_INFO = "SET_PROFILE_INFO"
const SET_EDIT_PROFILE_STATUS = "SET_EDIT_PROFILE_STATUS"
const SET_EDIT_PASS_STATUS = "SET_EDIT_PASS_STATUS"
const SET_DIRECTIONS = "SET_DIRECTIONS"
const SET_SEND_RESUME_STATUS = "SET_SEND_RESUME_STATUS"
const SET_SEND_APPLICATION_STATUS = "SET_SEND_APPLICATION_STATUS"
const SET_SPECIALIZATIONS = "SET_SPECIALIZATIONS"
const SET_REGIONS_AND_SITIES = "SET_REGIONS_AND_SITIES"
const SET_VACANCY = "SET_VACANCY"
const SET_PROBATION = "SET_PROBATION"
const SET_OPEN_RESUME_INFO = "SET_OPEN_RESUME_INFO"
const SET_RECOVERY_PASS_STATUS = "SET_RECOVERY_PASS_STATUS"




const initialState = {
    isLoad: false,
    authorized: false,
    userName: "",
    userPhone: "",
    userEmail: "",
    userBirthDate: "",
    userGender: "",
    userPhoto: "",
    userSubscribe: false,
    userPriorityDirections: [],
    userResumes: [],
    userVacanciesResponses: [],
    userProbationsResponses: [],
    userFeaturedVacancies: [],
    regions: [],
    towns: [],
    directions: [],
    specializations: [],
    probationInfo: {},
    vacancyInfo: {},
    resumeInfo: {},
    programs: [
        {id: 0, name: "position11"},
        {id: 1, name: "position22"},
        {id: 2, name: "position33"},
        {id: 3, name: "position44"}
    ],
    startDates: [
        {id: 0, name: "Готов приступить сейчас"},
        {id: 1, name: "Готов приступить в этом месяце"},
        {id: 2, name: "Хочу к вам на летнюю стажировку"},
        {id: 3, name: "Еще не определился"},
    ],
    vacancies: [],
    totalVacanciesCount: 0,
    foundVacanciesCount: 0,
    confirmEmailStatus: false,
    confirmEmailInProgress: false,
    editPassStatus: false,
    currenciesList: [
        {id: 1, name: "Сомони"},
        {id: 2, name: "Рублей"},
        {id: 3, name: "USD"},
    ],
    maritalStatusList: [
        {id: 1, name: "Не замужем / не женат"},
        {id: 2, name: "Замужем / женат"},
    ],
    educationFormsList: [
        {id: 0, name: "Не указывать"},
        {id: 1, name: "Очная"},
        {id: 2, name: "Заочная"},
        {id: 3, name: "Очно-заочная"},
        {id: 4, name: "Вечерняя"},
    ],
    errors: {   // [0] - текст ошибки; [1] - код ошибки. Объектом реализовать нельзя.
        authError: [undefined, undefined],
        regError: [undefined, undefined],
        confEmailError: [undefined, undefined],
        editPassError: [undefined, undefined],
        editProfileError: [undefined, undefined],
        sendResumeError: [undefined, undefined],
        sendApplicationError: [undefined, undefined],
        recoveryPasswordError: [undefined, undefined]
    },

    visualState: {
        listDropped: false
    }
}


const AppReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTHORIZED: {
            if (action.status === "error") {
                return {
                    ...state, errors: {...state.errors, authError: [action.message, action.code]}
                }
            } else if (action.status === "ok" && action.userInfo) {
                return {
                    ...state,
                    authorized: true,
                    errors: {...state.errors, authError: [undefined, undefined]},
                    userName: `${action.userInfo.last_name} ${action.userInfo.first_name} ${action.userInfo.patronymic}`,
                    userGender: action.userInfo.gender,
                    userBirthDate: action.userInfo.birth_day,
                    userEmail: action.userInfo.email,
                    userPhoto: action.userInfo.photo,
                    userPhone: action.userInfo.phone,
                    userSubscribe: action.userInfo.subscripton,
                    userResumes: action.userInfo.my_resums
                }
            } else if (action.status === "dropMessage") {
                return {
                    ...state, errors: {...state.errors, authError: false}
                }
            }
            break
        }
        case SET_REGISTRATION_STATUS:
            if (action.status === "error") {
                return {
                    ...state, errors: {...state.errors, regError: [action.message, action.code]}
                }
            } else if (action.status === "ok") {
                return {
                    ...state, errors: {...state.errors, regError: [undefined, undefined]}, confirmEmailInProgress: true
                }
            }
            break
        case SET_CONFIRM_EMAIL_STATUS:
            if (action.status === "error") {
                return {
                    ...state,
                    confirmEmailStatus: false,
                    errors: {...state.errors, confEmailError: [action.message, action.code]}
                }
            } else if (action.status === "ok") {
                return {
                    ...state,
                    confirmEmailStatus: true,
                    errors: {...state.errors, confEmailError: [undefined, undefined]}
                }
            }
            break
        case SET_CONFIRM_EMAIL_IN_PROGRESS:
            return {
                ...state, confirmEmailInProgress: action.bool
            }
        case SIGN_OUT: {
            return {
                ...state,
                authorized: false,
                userName: "",
                userGender: "",
                userBirthDate: "",
                userEmail: "",
                userPhoto: "",
                userPhone: "",
                userSubscribe: false,
                userPriorityDirections: [],
                userResumes: [],
                errors: {
                    authError: [undefined, undefined],
                    regError: [undefined, undefined],
                    confEmailError: [undefined, undefined]
                }
            }
        }
        case SET_PROFILE_INFO: {
            if (action.status === "error") {
                return {
                    ...state, errors: {...state.errors, authError: [action.message, action.code]}
                }
            } else if (action.status === "ok" && action.userInfo) {
                return {
                    ...state,
                    authorized: true,
                    errors: {...state.errors, authError: [undefined, undefined]},
                    userName: `${action.userInfo.last_name} ${action.userInfo.first_name} ${action.userInfo.patronymic}`,
                    userGender: action.userInfo.gender,
                    userBirthDate: action.userInfo.birth_day,
                    userEmail: action.userInfo.email,
                    userPhoto: action.userInfo.photo,
                    userPhone: action.userInfo.phone,
                    userSubscribe: action.userInfo.subscription,
                    userResumes: action.userResumes,
                    userPriorityDirections: action.userDirections,
                    userVacanciesResponses: action.userVacanciesResponses,
                    userProbationsResponses: action.userProbationsResponses,
                    userFeaturedVacancies: action.userFeaturedVacancies,
                    userTests: action.userTests

                }
            } else if (action.status === "dropMessage") {
                return {
                    ...state, errors: {...state.errors, authError: false}
                }
            }
            break
        }
        case SET_VACANCIES:
            return {
                ...state,
                vacancies: action.vacancies,
                totalVacanciesCount: action.totalCount,
                foundVacanciesCount: action.foundCount
            }
        case SET_MORE_VACANCIES:
            return {
                ...state,
                vacancies: [...state.vacancies, ...action.vacancies],
            }
        case SET_EDIT_PASS_STATUS:
            if (action.status === "error") {
                return {
                    ...state,
                    editPassStatus: false,
                    errors: {...state.errors, editPassError: [action.message, action.code]}
                }
            } else if (action.status === "ok") {
                return {
                    ...state, editPassStatus: true, errors: {...state.errors, editPassError: [undefined, undefined]}
                }
            }
            break
        case SET_DIRECTIONS:
            return {
                ...state, directions: action.directions
            }
        case SET_SPECIALIZATIONS:
            return {
                ...state, specializations: action.specializations
            }
        case SET_EDIT_PROFILE_STATUS:
            if (action.status === "error") {
                return {
                    ...state, errors: {...state.errors, editProfileError: [action.message, action.code]}
                }

            } else if (action.status === "ok") {
                return {
                    ...state, errors: {...state.errors, editProfileError: [undefined, undefined]}
                }
            }
            break
        case SET_SEND_RESUME_STATUS:
            if (action.status === "error") {
                return {
                    ...state, errors: {...state.errors, sendResumeError: [action.message, action.code]}
                }

            } else if (action.status === "ok") {
                return {
                    ...state, errors: {...state.errors, sendResumeError: [undefined, undefined]}
                }
            }
            break
            case SET_SEND_APPLICATION_STATUS:
            if (action.status === "error") {
                return {
                    ...state, errors: {...state.errors, sendApplicationError: [action.message, action.code]}
                }

            } else if (action.status === "ok") {
                return {
                    ...state, errors: {...state.errors, sendApplicationError: [undefined, undefined]}
                }
            }
            break
        case SET_REGIONS_AND_SITIES:
            let townsArr = action.regions.map((r) => r.cities)
            let towns = [{id: 0, name: "Любой город"}]
            for (let i in townsArr) {
                let currentTowns = townsArr[i]
                for (let t in currentTowns) {
                    towns.push(currentTowns[t])
                }
            }
            let regions = [{name: "Любой регион", id: 0, cities: []},...action.regions]
            return {
                ...state, regions: action.regions.length > 1 ? regions : state.regions, towns: towns
            }
        case SET_PROBATION:
            return {
                ...state, probationInfo: action.data.data.vacancy
            }
        case SET_VACANCY:
            return {
                ...state, vacancyInfo: action.data.data.vacancy
            }
        case SET_OPEN_RESUME_INFO:
            return {
                ...state, resumeInfo: action.data.data
            }
        case SET_RECOVERY_PASS_STATUS:
            if (action.status === "error") {
                return {
                    ...state, errors: {...state.errors, recoveryPasswordError: [action.message, action.code]}
                }

            } else if (action.status === "ok") {
                return {
                    ...state, errors: {...state.errors, recoveryPasswordError: [undefined, undefined]}
                }
            }
            break;
        case TOGGLE_IS_LOAD:
            return {
                ...state, isLoad: action.isLoad
            }
        default:
            return state
    }
}

export const setAuthorizedAC = (data) => {
    let userInfo = data.data?.user_info || undefined
    return {
        type: SET_AUTHORIZED,
        status: data.status,
        message: data.message || undefined,
        code: data.code || undefined,
        userInfo
    }
}

export const toggleIsLoadAC = (isLoad) => {
    return {
        type: TOGGLE_IS_LOAD,
        isLoad
    }
}

export const setProfileInfo = (data) => {
    
    let userInfo = data.data?.user_info || undefined
    let userDirections = data.data?.my_directions
    let userResumes = data.data?.my_resumes
    let userTests = data.data?.my_tests
    let userVacanciesResponses = data.data?.my_responses_v
    let userProbationsResponses = data.data?.my_responses_p
    let userFeaturedVacancies = data.data?.my_featured_v
   
    return {
        type: SET_PROFILE_INFO,
        status: data.status,
        message: data.message || undefined,
        code: data.code || undefined,
        userInfo,
        userDirections,
        userResumes,
        userVacanciesResponses,
        userProbationsResponses,
        userFeaturedVacancies,
        userTests
    }
}

export const setRecoveryPasswordStatusAC = (data) => {
    return {
        type: SET_RECOVERY_PASS_STATUS,
        status: data.status,
        message: data.message || undefined,
        code: data.code
    }
}
const setRegistrationStatusAC = (data) => {
    return {
        type: SET_REGISTRATION_STATUS,
        status: data.status,
        message: data.message || undefined,
        code: data.code
    }
}

export const signOutAC = () => {
    return {
        type: SIGN_OUT,
    }
}
export const setDirectionsAC = (data) => {
    return {
        type: SET_DIRECTIONS,
        directions: data.data
    }
}
export const setSpecializationsAC = (data) => {
    return {
        type: SET_SPECIALIZATIONS,
        specializations: data.data
    }
}

const setVacancies = (data, isGetMore) => {
    let vacancies = data?.data?.items
    let totalCount = data?.data?.total_count
    let foundCount = data?.data?.count
    return {
        type: !isGetMore ? SET_VACANCIES : SET_MORE_VACANCIES,
        vacancies,
        totalCount,
        foundCount
    }
}

export const setConfirmEmailStatus = (data) => {
    return {
        type: SET_CONFIRM_EMAIL_STATUS,
        status: data.status,
        message: data.message,
        code: data.code,
    }
}

export const setConfirmEmailInProgressAC = (bool) => {
    return {
        type: SET_CONFIRM_EMAIL_IN_PROGRESS,
        bool
    }
}

export const setProfileEditStatus = (data) => {
    return {
        type: SET_EDIT_PROFILE_STATUS,
        status: data.status,
        message: data.message,
        code: data.code
    }
}

const setEditPassStatusAC = (data) => {
    return {
        type: SET_EDIT_PASS_STATUS,
        status: data.status,
        message: data.message,
        code: data.code
    }
}
export const setSendResumeStatusAC = (data) => {
    return {
        type: SET_SEND_RESUME_STATUS,
        status: data.status,
        message: data.message,
        code: data.code

    }
}
export const setSendApplicationStatusAC = (data) => {
    return {
        type: SET_SEND_APPLICATION_STATUS,
        status: data.status,
        message: data.message,
        code: data.code

    }
}

const setRegionsAndSitiesAC = (data) => {
    return {
        type: SET_REGIONS_AND_SITIES,
        regions: data.data
    }
}

const setVacancyAC = (data) => {
    return {
        type: SET_VACANCY,
        data
    }
}

const setProbationAC = (data) => {
    return {
        type: SET_PROBATION,
        data
    }
}

export const setOpenResumeInfoAC = (data) => {
    return {
        type: SET_OPEN_RESUME_INFO,
        data
    }
}

export const sendResumeTH = (userName, userCity, resume, directions) => async (dispatch) => {
    const showToast = () => {
        ToastsStore.success("Резюме создано!")
    }
    dispatch(toggleIsLoadAC(true))
    let data = await sendResume(userName, userCity, resume, directions)
    dispatch(setSendResumeStatusAC(data))
    let profData = await getProfileInfo()
    dispatch(toggleIsLoadAC(false))
    dispatch(setProfileInfo(profData))
    if (data.status === "ok") {
        showToast()
    }
    return data.status
}

export const sendFullResumeTH = (formData) => async (dispatch) => {
    const showToast = () => {
        ToastsStore.success("Резюме создано!")
    }
    dispatch(toggleIsLoadAC(true))
    let data = await sendFullResume(formData)
    dispatch(setSendResumeStatusAC(data))
    let profData = await getProfileInfo()
    dispatch(toggleIsLoadAC(false))
    dispatch(setProfileInfo(profData))
    if (data.status === "ok") {
        showToast()
    }
    return data.status
}

export const editResumeTH = (userName, userCity, resume, directions, resumeId) => async (dispatch) => {
    const showToast = () => {
        ToastsStore.success("Изменения сохранены!")
    }
    dispatch(toggleIsLoadAC(true))
    let data = await editResume(userName, userCity, resume, directions, resumeId)
    dispatch(toggleIsLoadAC(false))
    dispatch(setSendResumeStatusAC(data))
    if (data.status === "ok") {
        showToast()
    }
    return data.status
}
export const editFullResumeTH = (formData, resumeId) => async (dispatch) => {
    const showToast = () => {
        ToastsStore.success("Изменения сохранены!")
    }
    dispatch(toggleIsLoadAC(true))
    let data = await editFullResume(formData, resumeId)
    dispatch(toggleIsLoadAC(false))
    dispatch(setSendResumeStatusAC(data))
    if (data.status === "ok") {
        showToast()
    }
    return data.status
}

export const clearResumeInfoTH = () => async (dispatch) => {
    dispatch(setOpenResumeInfoAC({data:{}}))
}


export const sendApplicationTH = (email, phone, name, lastName, patronymic, resume, startDate, vacancyId) => async (dispatch) => {
    dispatch(toggleIsLoadAC(true))
    let data = await sendApplication(email, phone, name, lastName, patronymic, resume, startDate, vacancyId)
    dispatch(toggleIsLoadAC(false))
    dispatch(setSendApplicationStatusAC(data))
    const showToast = () => {
        ToastsStore.success("Заявка отправлена")
    }
    if (data.status === "ok") {
        showToast()
    }
    return data.status
}

export const editPasswordTH = (newPassword, newPassRepeat) => async (dispatch) => {
    const showToast = () => {
        ToastsStore.success("Пароль изменен!")
    }
    dispatch(toggleIsLoadAC(true))
    const data = await editPassword(newPassword, newPassRepeat)
    dispatch(toggleIsLoadAC(false))
    dispatch(setEditPassStatusAC(data))
    if (data.status === "ok") {
        showToast()
    }
    return data.status
}

export const authorizationTH = (login, password) => async (dispatch) => {
    dispatch(toggleIsLoadAC(true))
    let data = await authorization(login, password)
    dispatch(setAuthorizedAC(data))
    dispatch(toggleIsLoadAC(false))
    return data.status
}

export const setProfileInfoTH = () => async (dispatch) => {
    let data = await getProfileInfo()
    dispatch(setProfileInfo(data))
    return data.status
}

export const registrationTH = (name, firstName, patronymic, email, phone, genderId, birthDate, password, subcription, consent) => async (dispatch) => {
    dispatch(toggleIsLoadAC(true))
    let data = await registration(name, firstName, patronymic, email, phone, genderId, birthDate, password, subcription, consent)
    dispatch(toggleIsLoadAC(false))
    dispatch(setRegistrationStatusAC(data))
}


export const editProfileTH = (name, photo, email, phone, gender, birthDate, directions) => async (dispatch) => {
    dispatch(toggleIsLoadAC(true))
    let data = await editProfile(name, photo, email, phone, gender, birthDate, directions)
    dispatch(setProfileEditStatus(data))
    let profData = await getProfileInfo()
    dispatch(toggleIsLoadAC(false))
    dispatch(setProfileInfo(profData))
    const showToast = () => {
        ToastsStore.success("Данные изменены")
    }
    if (data.status === "ok") {
        showToast()
    }
}

export const getVacanciesTH = (page, count, region, city, directions, specializations, searchText, flag, isGetMore,isArhive) => async (dispatch) => {
    let data = await getVacancies(page, count, region, city, directions, specializations, searchText, flag, isArhive)
    dispatch(setVacancies(data, isGetMore))
}

export const setOpenResumeInfoTH = (resumeId) => async (dispatch) => {
    dispatch(toggleIsLoadAC(true))
    let data = await getResumeInfo(resumeId)
    dispatch(toggleIsLoadAC(false))
    dispatch(setOpenResumeInfoAC(data))
}

export const confirmEmailTH = (code) => async (dispatch) => {
    dispatch(toggleIsLoadAC(true))
    let data = await confirmEmail(code)
    dispatch(toggleIsLoadAC(false))
    dispatch(setConfirmEmailStatus(data))
    return data.status
}

export const resendCodeTH = (email) => async (dispatch) => {
    let data = await resendCode(email)
}

export const setDirectionsTH = (usingPlaceFlag) => async (dispatch) => {
    let data = await getDirections(usingPlaceFlag)
    dispatch(setDirectionsAC(data))
}
export const setSpecializationsTH = (usingPlaceFlag) => async (dispatch) => {
    let data = await getSpecializations(usingPlaceFlag)
    dispatch(setSpecializationsAC(data))
}

export const subscribeSenderTH = (email, setSubscribeFlag) => async (dispatch) => {
    dispatch(toggleIsLoadAC(true))
    let data = await subscribeSender(email, setSubscribeFlag)
    let profData = await getProfileInfo()
    dispatch(toggleIsLoadAC(false))
    dispatch(setProfileInfo(profData))
    const showToast = () => {
        ToastsStore.success(setSubscribeFlag === false ? "Вы отписались от рассылки" : "Вы подписались на рассылку")
    }
    if (data.status === "ok") {
        showToast()
    }
    return data.status
}

export const setRegionsAndSitiesTH = (regId) => async (dispatch) => {
    let data = await getRegions(regId)
    dispatch(setRegionsAndSitiesAC(data))
}

export const setVacancyProbationTH = (id, flag) => async (dispatch) => {
    let data = await getVacancyProbation(id, flag)
    if (flag === 0) {
        dispatch(setVacancyAC(data))
    } else if (flag === 1) {
        dispatch(setProbationAC(data))
    }
}

export const addToFeaturedTH = (idVacancy) => async  (dispatch) => {
    dispatch(toggleIsLoadAC(true))
    let data = await addToFeatured(idVacancy)
    let vacData = await getVacancyProbation(idVacancy, 0)
    dispatch(toggleIsLoadAC(false))
    const showToast = () => {
        ToastsStore.success(data.data?.message)
    }
    if (data.status === "ok") {
        showToast()
    }
    dispatch(setVacancyAC(vacData))
}

export const recoveryPasswordTH = (email, code) => async (dispatch) => {
    dispatch(toggleIsLoadAC(true))
    let data = await recoveryPassword(email, code)
    dispatch(toggleIsLoadAC(false))
    dispatch(setRecoveryPasswordStatusAC(data))
    return data.status
}

export const exitTH = () => async (dispatch) => {
    let data = await exit()
    dispatch(signOutAC())
    return data.status
}

export default AppReducer