import React, {useEffect, useState} from "react";
import s from "./AuthBar.module.css";
import close from "../../Assets/Icons/close-outline.svg";
import {Input} from "../Input/Input";
import {Button} from "../Button/Button";
import logo from "../../Assets/Logo/logo.png"
import ConfirmationEmailBarContainer from "../СonfirmationEmailBar/СonfirmationEmailBarContainer";
import {useNavigate} from "react-router";
import {NavLink} from "react-router-dom";
import CustomDatePicker from "../DatePicker/DatePicker";


export const AuthBar = ({onClose, goAuth, errorMessage, onClearErrMessage, confirmEmailInProgress, toggleShowConfirmEmailBar, onOpenRecovery}) => {
    const [login, setLogin] = useState("")
    const [password, setPassword] = useState("")

    const [emailIsValid, setEmailIsValid] = useState(undefined)
    const [passIsValid, setPassIsValid] = useState(undefined)
    const [isMobile, toggleIsMobile] = useState(false)

    const [showRequired, setShowRequired] = useState(false)

    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/`;
        navigate(path);
    }


    const goAuthorize = () => {
        setShowRequired(true)
        if (login && password) {
            goAuth(login, password)
                .then((result) => {
                    if (result === "ok") {
                        routeChange()
                    }
                })
        }
    }


    useEffect(() => {
        toggleIsMobile(window.innerWidth <= 900)
        window.addEventListener("resize", () => {
            toggleIsMobile(window.innerWidth <= 900)
        })
    }, [])

    return (
        <div className={s.authWrapper} id="authorize">
            <div className={s.clickableZone}
                 onClick = {() => {
                    onClose()
                    onClearErrMessage()
                }}></div>
            <div style={isMobile ? {width: "70%"} : null} className={s.subWrapper}>
                <img className={s.closeIcon} onClick={() => {
                    onClose()
                    onClearErrMessage()
                }} src={close}/>
                <div className={s.authForm}>
                    <div className={s.logo}><img src={logo} alt=""/></div>
                    <p>Вход</p>
                    <Input margin="0 0 20px 0" callBackChangeText={setLogin} tone="light" inputType="email"
                           placeholderText="Email" required={true} onSetValid={setEmailIsValid} showRequired={showRequired} startText=""/>
                    <Input margin="0 0 20px 0" callBackChangeText={setPassword} tone="light" inputType="password"
                           placeholderText="Пароль" required={true} maxLength={20} minLength={6} startText=""
                           onSetValid={setPassIsValid} showRequired={showRequired}/>
                    <div className={s.btnWrapp} >
                        <Button btnId="auth" formWrapId="authorize" disabled={!emailIsValid || !passIsValid} onClick={goAuthorize}
                                variant={4} btnText="Войти"/>
                        {errorMessage[1] === 27 ? <div className={s.confirmEmailBar}>
                            <Button
                                onClick={() => toggleShowConfirmEmailBar(true)}
                                variant={3} btnText="Подтвердить почту"/>
                        </div> : null}

                    </div>
                    <p className={s.errorText}>{errorMessage[1] !== 26 ? errorMessage[0] : ""}</p>
                    <div className={s.linksBar}>
                        <p onClick={() => {
                            onOpenRecovery()
                            onClose()
                        }} className={s.recoveryPassText}>ЗАБЫЛИ ПАРОЛЬ?</p>
                        <NavLink onClick={() => onClose()} className={s.regText} to="/registracziya">ЗАРЕГИСТРИРОВАТЬ
                            АККАУНТ</NavLink>
                    </div>
                </div>
            </div>
            {confirmEmailInProgress ?
                <ConfirmationEmailBarContainer email={login || "почту"}
                                               onClose={() => toggleShowConfirmEmailBar(false)}/> : null}
        </div>
    )
}