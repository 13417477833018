import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, {Component} from "react";
import next from "../../../../Assets/Icons/showNextSlide.svg";
import previous from "../../../../Assets/Icons/showPreviousSlide.svg";
import {Slide} from "../../../Slide/Slide";
import img1 from "../../../../Assets/SlidersImages/corp_1.png";
import img2 from "../../../../Assets/SlidersImages/image 1.png";
import img3 from "../../../../Assets/SlidersImages/image 2.png"
import s from "./MainSlider.module.css"

function SampleNextArrow(props) {
    const {className, style, onClick, subOnClick} = props;
    return (
        <div style={{...style}} onClick={() => {
            onClick()
            subOnClick()
        }} className={`${className} ${s.nextArrow}`}>
            <img src={next} alt=""/>
        </div>
    );
}

function SamplePrevArrow(props) {
    const {className, style, onClick, subOnClick} = props;
    return (
        <div style={{...style}} onClick={() => {
            onClick()
            subOnClick()
        }} className={`${className} ${s.prevArrow}`}>
            <img src={previous} alt=""/>
        </div>
    );
}

export default class MainSlider extends Component {

    componentDidMount() {

        window.addEventListener("resize", this.resizeMethod)
    }

    resizeMethod = () => {
        let width = window.innerWidth
        this.setState({screenWidth: width - 20})
        this.setState({isMobile: width <= 900})
    }


    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeMethod)
    }

    constructor(props) {
        super(props);
        this.state = {
            activeItem: 0,
            screenWidth: window.innerWidth - 20,
            isMobile: window.innerWidth <= 900,
        }
    }

    onGoPreviousSlide = (index) => {
        this.setState({activeItem: index})

    }

    onGoNextSlide = (index) => {
        this.setState({activeItem: index})
    }

    render() {
        const settings = {
            arrows: !this.state.isMobile,
            lazyLoad: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            nextArrow: <SampleNextArrow subOnClick={this.onGoNextSlide}/>,
            prevArrow: <SamplePrevArrow subOnClick={this.onGoPreviousSlide}/>,
            autoplay: false,
            autoplaySpeed: 4000,
            afterChange: this.onGoNextSlide,
            swipeToSlide: true

        };
        return (
            <div style={{maxWidth: `${this.state.screenWidth}px`, margin: "0 auto", position: "relative"}}>
                <Slider {...settings}>
                    <div>
                        <div>
                            <Slide
                                   isMobile={this.state.isMobile} id={1} slideVariantNum={1}
                                   slideImg={img1} slideHeadText="Работай в МегаФоне"
                                   slideText={!this.state.isMobile ? "Мы создаем цифровое будущее Таджикистана. Первыми в стране запускаем новые технологии. "  +
                                       "Присоединяйся – будем вместе делать людей счастливыми!" : "Мы создаем цифровое будущее Таджикистана"}
                                   btnVariantNum={1}
                                   btnText="Показать все вакансии" slideHeight="600px" btnIsLink={true}
                                   link={"/vakansii"}/>
                        </div>
                    </div>
                    <div>
                        <div>
                            <Slide
                                imgToCenter={true}
                                isMobile={this.state.isMobile} id={2}
                                   slideVariantNum={1} slideImg={img2}
                                   slideHeadText="МегаФон - симбиоз опыта и молодости"
                                   slideText={!this.state.isMobile ?"Мы дерзкие и яркие, мы готовы бросить вызов любы формальностям, мы любим мечтать и создавать новое." +
                                       " Но за нами всегда наша экспертиза, стратегический подход и опытыные “братья по цеху”.": undefined}
                                   btnVariantNum={1}
                                   btnText="Показать все вакансии" slideHeight="600px" btnIsLink={true}
                                   link={"/vakansii"}/>
                        </div>
                    </div>
                    <div>
                        <div>
                            <Slide
                                isMobile={this.state.isMobile}
                                id={3} slideVariantNum={1}
                                   slideImg={img3}
                                   slideHeadText="Наша гордость - история, миссия, команда"
                                   slideText={!this.state.isMobile ?"Мы свято верим, что все в этом мире начинается с тебя самого, поэтому мы гордимся нашими достижениями,ставим перед собой новые цели и покоряем все новые вершины.": undefined} btnVariantNum={1}
                                   btnText="Показать все вакансии" slideHeight="600px" btnIsLink={true}
                                   link={"/vakansii"}/>
                        </div>
                    </div>
                </Slider>
                <div style={{
                    position: "absolute",
                    bottom: "10px",
                    display: "flex",
                    justifyContent: "center",
                    width: "100%"
                }}>
                    <div className={this.state.activeItem === 0 ? s.activePage : s.pageElement}/>
                    <div className={this.state.activeItem === 1 ? s.activePage : s.pageElement}/>
                    <div className={this.state.activeItem === 2 ? s.activePage : s.pageElement}/>
                </div>
            </div>
        );
    }
}