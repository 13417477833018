import React, {useEffect, useRef, useState} from "react";
import s from "./Input.module.css"
import inputFile from "../../Assets/Icons/inputFile.svg"
import lock from "../../Assets/Icons/lock-outline.svg"
import unLock from "../../Assets/Icons/unlock-outline.svg"
import email from "../../Assets/Icons/email-outline.png"
import phone from "../../Assets/Icons/phone-outline.png"
import person from "../../Assets/Icons/person-outline.svg"
import search from "../../Assets/Icons/search.svg"
import {withMobileDialog} from "@material-ui/core";

export const Input = ({
                          inputType, placeholderText, startText, callBackChangeText,
                          callBackChangeFile, tone, textTone, margin, isHidden,
                          required, minLength, maxLength, onSetValid, showRequired,
                          mismatchPass, isUnchangeable, flexValue, isReloadedInput, minNum, maxNum
                      }) => {
    const [text, setNewText] = useState(startText || "")
    const [hiddenPass, toggleHiddenPass] = useState(isHidden === true || isHidden === false ? isHidden : true)
    const [fieldColor, setFieldColor] = useState("")

    const [passValidate, setPassValidate] = useState({isValid: true, errorText: ""})
    const [emailValidate, setEmailValidate] = useState({isValid: true, errorText: ""})
    const [textValidate, setTextValidate] = useState({isValid: true, errorText: ""})
    const [nameValidate, setNameValidate] = useState({isValid: true, errorText: ""})
    const [numberValidate, setNumberValidate] = useState({isValid: true, errorText: ""})

    const [phoneValidate, setPhoneValidate] = useState({isValid: true, errorText: ""})

    useEffect(() => {
        if (startText && startText !== text) {
            setNewText(startText)
        } else if (isReloadedInput && startText !== text) {
            setNewText(startText)
        }
    }, [startText])

    useEffect(() => {
        switch (tone) {
            case "light": {
                setFieldColor("white")
            }
        }
    }, [tone])

    useEffect(() => {
        if (isHidden === true || isHidden === false) {
            toggleHiddenPass(isHidden)
        }
    }, [isHidden])


    useEffect(() => {
        if (onSetValid) {
            if (inputType === "password") onSetValid(passValidate.isValid)
        }
    }, [passValidate])


    useEffect(() => {
        if (onSetValid) {
            if (inputType === "email") onSetValid(emailValidate.isValid)
        }
    }, [emailValidate])


    useEffect(() => {
        if (onSetValid) {
            if (inputType === "name") onSetValid(nameValidate.isValid)
        }
    }, [nameValidate])

    useEffect(() => {
        if (onSetValid) {
            if (inputType === "text") onSetValid(textValidate.isValid)
        }
    }, [textValidate])

    useEffect(() => {
        if (onSetValid) {
            if (inputType === "phone") onSetValid(phoneValidate.isValid)
        }
    }, [phoneValidate])

    useEffect(() => {
        if (onSetValid) {
            if (inputType === "number") onSetValid(numberValidate.isValid)
        }
    }, [numberValidate])

    useEffect(() => {
        if (required && text.length === 0 && showRequired) {
            switch (inputType) {
                case "password": {
                    setPassValidate({isValid: false, errorText: "Это обязательное поле"})
                }
                    break
                case "email": {
                    setEmailValidate({isValid: false, errorText: "Это обязательное поле"})
                }
                    break
                case "text": {
                    setTextValidate({isValid: false, errorText: "Это обязательное поле"})
                }
                    break
                case "name": {
                    setNameValidate({isValid: false, errorText: "Это обязательное поле"})
                }
                    break
                case "phone": {
                    setPhoneValidate({isValid: false, errorText: "Это обязательное поле"})
                }
                    break

                case "number": {
                    setNumberValidate({isValid: false, errorText: "Это обязательное поле"})
                }
            }
        }
    }, [showRequired])


    const onChangeText = (e) => {
        setNewText(e.target.value)
        callBackChangeText(e.target.value)
        switch (inputType) {
            case "password": {
                if (required && e.target.value.length === 0) {
                    setPassValidate({isValid: false, errorText: "Это обязательное поле"})
                } else if (e.target.value.length < minLength) {
                    setPassValidate({isValid: false, errorText: `Длинна пароля не менее ${minLength} символов`})
                } else if (e.target.value.length > maxLength) {
                    setPassValidate({isValid: false, errorText: `Длинна пароля не более ${maxLength} символов`})
                } else {
                    setPassValidate({isValid: true, errorText: ""})
                }

            }
                break
            case "email": {
                if (required && e.target.value.length === 0) {
                    setEmailValidate({isValid: false, errorText: "Это обязательное поле"})
                }
                if (e.target.value.length > 0 && e.target.value.indexOf("@") === -1) {
                    setEmailValidate({isValid: false, errorText: "Неверный формат Email"})
                } else if (e.target.value.length > 0 && e.target.value.split("@")[0].length < 1 ||
                    e.target.value.split("@")[1].length < 1 ||
                    e.target.value.split("@")[1].split(".")[0].length < 1 ||
                    e.target.value.split("@")[1].split(".")[1].length < 1) {
                    setEmailValidate({isValid: false, errorText: "Неверный формат Email"})
                } else {
                    setEmailValidate({isValid: true, errorText: ""})
                }
            }
                break
            case "name": {
                if (required && e.target.value.length === 0) {
                    setNameValidate({isValid: false, errorText: "Это обязательное поле"})
                }
                if (e.target.value.length > 0 && e.target.value.length < minLength) {
                    setNameValidate({isValid: false, errorText: `Минимальное кол-во символов - ${minLength}`})
                }
                if (e.target.value.length > maxLength) {
                    setNameValidate({isValid: false, errorText: `Максимальное кол-во символов - ${maxLength}`})
                }
                if (e.target.value.length <= maxLength && e.target.value.length >= minLength) {
                    setNameValidate({isValid: true, errorText: ""})
                }
            }
                break
            case "text" || "searchText" || "number": {
                if (required && e.target.value.length === 0) {
                    setTextValidate({isValid: false, errorText: "Это обязательное поле"})
                }
                if (e.target.value.length > 0 && e.target.value.length < minLength) {
                    setTextValidate({isValid: false, errorText: `Минимальное кол-во символов - ${minLength}`})
                }
                if (e.target.value.length > maxLength) {
                    setTextValidate({isValid: false, errorText: `Максимальное кол-во символов - ${maxLength}`})
                }
                if (e.target.value.length <= maxLength && e.target.value.length >= minLength) {
                    setTextValidate({isValid: true, errorText: ""})
                }
            }
                break
            case "number": {
                if (required && `${e.target.value}`.length === 0) {
                    setNumberValidate({isValid: false, errorText: "Это обязательное поле"})
                }
                if (`${e.target.value}`.length > 0 && +e.target.value < minNum) {
                    setNumberValidate({isValid: false, errorText: `Минимальное значение - ${minNum}`})
                }

                if (+e.target.value > maxNum) {
                    setNumberValidate({isValid: false, errorText: `Максимальное значение - ${maxNum}`})
                }

                if (`${e.target.value}`.length <= maxLength && `${e.target.value}`.length >= minLength && +e.target.value <= maxNum && +e.target.value >= minNum) {
                    setNumberValidate({isValid: true, errorText: ""})
                }
            }
                break
            case "phone": {
                if (required && e.target.value.length === 0) {
                    setPhoneValidate({isValid: false, errorText: "Это обязательное поле"})
                }
                if (e.target.value.length > 0 && e.target.value.length < minLength) {
                    setPhoneValidate({isValid: false, errorText: `Минимальное кол-во символов - ${minLength}`})
                }
                if (e.target.value.length > maxLength) {
                    setPhoneValidate({isValid: false, errorText: `Максимальное кол-во символов - ${maxLength}`})
                }
                if (e.target.value.length <= maxLength && e.target.value.length >= minLength) {
                    setPhoneValidate({isValid: true, errorText: ""})
                }
            }
        }
        if (callBackChangeFile) {
            callBackChangeFile(undefined)
        }
    }

    const onChangeFile = (e) => {
        setNewText(e.target.files[0].name)
        callBackChangeFile(e.target.files[0])
    }

    switch (inputType) {
        case "password": {
            return (
                <div style={{margin: margin ? `${margin}` : null, flex: flexValue}}>
                    <div style={{backgroundColor: `${fieldColor}`,}}
                         className={s.wrapper}>
                        <img onClick={() => toggleHiddenPass(!hiddenPass)} className={s.inputIcon}
                             style={{cursor: "pointer"}}
                             src={hiddenPass ? lock : unLock}/>
                        {hiddenPass ? <input
                                className={`${s.input} ${textTone === "light" ? s.lightText : null}`}
                                onChange={onChangeText} value={text}
                                type="password"
                                placeholder={`${placeholderText}`}/> :
                            <input
                                className={`${s.input} ${textTone === "light" ? s.lightText : null}`}
                                onChange={onChangeText} value={text}
                                type="text"
                                placeholder={`${placeholderText}`}/>}
                    </div>
                    {tone === "light" ? <hr color="#EDEDED" size={1}/> : null}
                    {!passValidate.isValid || mismatchPass ?
                        <p className={s.errorText}>{passValidate.errorText || "Пароли не совпадают"}</p> : null}
                </div>
            )
        }
        case "email": {
            return (
                <div style={{margin: margin ? `${margin}` : null, flex: flexValue}}>
                    <div style={{backgroundColor: `${fieldColor}`,}}
                         className={s.wrapper}>
                        <img className={s.inputIcon}
                             src={email}/>
                        <input
                            className={`${s.input} ${textTone === "light" ? s.lightText : null}`}
                            onChange={onChangeText} value={isUnchangeable ? startText : text}
                            type="text"
                            readOnly={isUnchangeable ? true : undefined}
                            placeholder={`${placeholderText}`}/>
                    </div>
                    {tone === "light" ? <hr color="#EDEDED" size={1}/> : null}
                    {!emailValidate.isValid ? <p className={s.errorText}>{emailValidate.errorText}</p> : null}
                </div>
            )
        }
        case "phone": {
            return (
                <div style={{margin: margin ? `${margin}` : null, flex: flexValue}}>
                    <div style={{backgroundColor: `${fieldColor}`,}}
                         className={s.wrapper}>
                        <img className={s.inputIcon}
                             src={phone}/>
                        <input
                            className={`${s.input} ${textTone === "light" ? s.lightText : null}`}
                            onChange={onChangeText} value={text} type="text"
                            placeholder={`${placeholderText}`}/>
                    </div>
                    {tone === "light" ? <hr color="#EDEDED" size={1}/> : null}
                    {!phoneValidate.isValid ? <p className={s.errorText}>{phoneValidate.errorText}</p> : null}
                </div>
            )
        }
        case "text": {
            return (
                <div style={{margin: margin ? `${margin}` : null, flex: flexValue}}>
                    <div style={{backgroundColor: `${fieldColor}`,}}
                         className={s.wrapper}>
                        <input
                            className={`${s.input} ${textTone === "light" ? s.lightText : null}`}
                            onChange={onChangeText}
                            value={text} type="text"
                            placeholder={`${placeholderText}`}/>
                    </div>
                    {tone === "light" ? <hr color="#EDEDED" size={1}/> : null}
                    {!textValidate.isValid ? <p className={s.errorText}>{textValidate.errorText}</p> : null}
                </div>
            )
        }
        case "number": {
            return (
                <div style={{margin: margin ? `${margin}` : null, flex: flexValue}}>
                    <div style={{backgroundColor: `${fieldColor}`,}}
                         className={s.wrapper}>
                        <input
                            className={`${s.input} ${textTone === "light" ? s.lightText : null}`}
                            onChange={onChangeText}
                            value={text} type="number"
                            placeholder={`${placeholderText}`}
                        />
                    </div>
                    {tone === "light" ? <hr color="#EDEDED" size={1}/> : null}
                    {!numberValidate.isValid ? <p className={s.errorText}>{numberValidate.errorText}</p> : null}
                </div>
            )
        }
        case "searchText": {
            return (
                <div style={{margin: margin ? `${margin}` : null, flex: flexValue}}>
                    <div style={{backgroundColor: `${fieldColor}`,}}
                         className={s.wrapper}>
                        <img className={s.inputIcon}
                             src={search}/>
                        <input
                            className={`${s.input} ${textTone === "light" ? s.lightText : null}`}
                            onChange={onChangeText}
                            value={text} type="text"
                            placeholder={`${placeholderText}`}/>
                    </div>
                    {tone === "light" ? <hr color="#EDEDED" size={2}/> : null}
                    {!textValidate.isValid ? <p className={s.errorText}>{textValidate.errorText}</p> : null}
                </div>
            )
        }
        case "fileOrText": {
            return (
                <div style={tone === "light" ? {backgroundColor: "white"} : null} className={s.inputFileOrText}>
                    <input hidden={true} onChange={onChangeFile} name="fileOrText" id="fileOrText"
                           type="file"/>
                    <div style={{display: "flex", alignItems: "center"}}><input className={`${s.input}`}
                                                                                onChange={onChangeText} value={text}
                                                                                placeholder={`${placeholderText}`}
                                                                                type="text"/>
                        <label className={s.addFileBtn} htmlFor="fileOrText"><img src={inputFile}/></label>
                    </div>

                </div>
            )
        }
        case "file": {
            return (
                <div>
                    <input hidden={true} onChange={onChangeFile} name="file" id="file"
                           type="file"/>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <label className={s.inputFileLabel} htmlFor="file">
                            <div><p>Выберите файл</p></div>
                        </label>
                    </div>
                </div>
            )
        }
        case "name": {
            return (
                <div style={{margin: margin ? `${margin}` : null, flex: flexValue}}>
                    <div style={{backgroundColor: `${fieldColor}`,}}
                         className={s.wrapper}>
                        <img className={s.inputIcon}
                             src={person}/>
                        <input
                            className={`${s.input} ${textTone === "light" ? s.lightText : null}`}
                            onChange={onChangeText} value={text} type="text"
                            placeholder={`${placeholderText}`}/>
                    </div>
                    {tone === "light" ? <hr color="#EDEDED" size={1}/> : null}
                    {!nameValidate.isValid ? <p className={s.errorText}>{nameValidate.errorText}</p> : null}
                </div>
            )
        }

    }
}
