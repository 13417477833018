import React, {useEffect, useState} from "react";
import s from "./SendApplicationBar.module.css";
import {Input} from "../Input/Input";
import {Button} from "../Button/Button";
import {DropList} from "../DropList/DropList";
import close from "../../Assets/Icons/close-outline.svg";
import {ToastsStore} from "react-toasts";


export const SendApplicationBar = ({userEmail, userPhone, userName, startDates, userResumes, onClose, onSend, vacancyId, isMobile, respErrorText, onDropErrorText, authorized}) => {

    const [email, setEmail] = useState(userEmail || "")
    const [phone, setPhone] = useState(userPhone || "")
    const [selectedStartDate, setSelectedStartDate] = useState(undefined)
    const [selectedUserResume, setSelectedUserResume] = useState(undefined)

    const [name, setName] = useState(userName.split(" ")[1] || "")
    const [lastName, setLastName] = useState(userName.split(" ")[0] || "")
    const [patronymic, setPatronymic] = useState(userName.split(" ")[2] || "")

    const [lastNameIsValid, setLastNameIsValid] = useState(undefined)
    const [nameIsValid, setNameIsValid] = useState(undefined)
    const [patronymicIsValid, setPatronymicIsValid] = useState(undefined)
    const [emailIsValid, setEmailIsValid] = useState(undefined)
    const [phoneIsValid, setPhoneIsValid] = useState(undefined)

    const [showRequired, setShowRequired] = useState(false)
    const [resumeFileName, setResumeFileName] = useState("")

    const [resumesList, setResumesList] = useState([])

    const [resumeIsFile, setResumeIsFile] = useState(undefined)

    const [errorText, setErrorText] = useState("")

    useEffect(() => {
        if (userResumes) {
            let resumesArr = []
            for (let i = 0; i < userResumes?.length; i++) {
                let resume = userResumes[i]
                resume.name = `Резюме ${i + 1}`
                resumesArr.push(resume)
            }
            setResumesList(resumesArr)
        }

    }, [userResumes])

    useEffect(() => {
        if (respErrorText) {
            setErrorText(respErrorText?.[0])
        }
    }, [respErrorText])

    const [activeDroppedList, setActiveDroppedList] = useState(0)

    useEffect(() => {
        setActiveDroppedList(0)
    }, [selectedUserResume, selectedStartDate])

    const setDroppedListId = (id) => {
        if (id === activeDroppedList) {
            setActiveDroppedList(0)
        } else {
            setActiveDroppedList(id)
        }
    }

    const onSetSelectedUserResume = (resume) => {
        setSelectedUserResume(resume)
        setErrorText("")
        if (typeof resume === "number") {
            setResumeIsFile(false)
            setResumeFileName(userResumes[resume].name)
        } else {
            setResumeFileName(resume.name)
            setResumeIsFile(true)
        }
    }

    const selectDateStart = (date) => {
        setErrorText("")
        setSelectedStartDate(date)
    }

    const onSendApplication = () => {
        setShowRequired(true)
        if (!selectedUserResume) {
            setErrorText("Выберите ваше резюме")
        }
        if (!selectedStartDate && selectedStartDate !== 0) {
            setErrorText("Укажите, когда будете готовы приступить")
        }
        if (email && selectedUserResume && selectedStartDate && lastNameIsValid, nameIsValid, patronymicIsValid, emailIsValid, phoneIsValid) {
            onSend(email || userEmail, phone || userPhone, name || userName.split(" ")[1], lastName || userName.split(" ")[0], patronymic || userName.split(" ")[2], selectedUserResume, startDates[selectedStartDate].name, vacancyId)
                .then((result) => {
                    if (result === "ok") {
                        onDropErrorText()
                        onClose()
                    }
                })
        }
    }

    return (
        <div onClick={() => setActiveDroppedList(0)} className={s.wrapper}>
            <div className={s.clickableZone}
                 onClick={() => {
                    onDropErrorText()
                    onClose()
                }}></div>
            <div className={s.subWrapper}>
                <img className={s.closeIcon} onClick={() => {
                    onDropErrorText()
                    onClose()
                }} src={close}/>
                <div className={s.sendAppBox}>
                    <p className={s.head}>Оставьте заявку</p>
                    <Input margin="0 0 15px 0" callBackChangeText={setName} inputType="name"
                           placeholderText="Имя"
                           required={true} minLength={2} maxLength={30} onSetValid={setNameIsValid}
                           startText={userName.split(" ")[1] || ""}
                           showRequired={showRequired}/>
                    <Input margin="0 0 15px 0" callBackChangeText={setLastName}
                           inputType="name"
                           placeholderText="Фамилия" required={true} minLength={2} maxLength={35}
                           onSetValid={setLastNameIsValid} showRequired={showRequired}
                           startText={userName.split(" ")[0] || ""}/>
                    <Input margin="0 0 15px 0" callBackChangeText={setPatronymic}
                           inputType="name"
                           placeholderText="Отчество" onSetValid={setPatronymicIsValid} minLength={2} maxLength={30}
                           startText={userName.split(" ")[2] || ""}/>
                    <Input margin="0 0 15px 0" startText={email} callBackChangeText={setEmail} inputType="email"
                           placeholderText="Email" required={true} onSetValid={setEmailIsValid}
                           showRequired={showRequired}/>
                    <Input margin="0 0 20px 0" startText={phone || ""} callBackChangeText={setPhone} inputType="phone"
                           placeholderText="Телефон" onSetValid={setPhoneIsValid} required={true} minLength={9}
                           maxLength={13} showRequired={showRequired}/>

                    <p className={s.subHead}>Прикрепите резюме:</p>
                    <div className={s.selectResumeBox}>
                        <Input callBackChangeFile={onSetSelectedUserResume} inputType="file"/>
                        <p className={`${resumeFileName ? s.selected : s.empty}`}>{typeof resumeIsFile === "boolean" && resumeIsFile === true ?
                            resumeFileName : "Файл не выбран"}</p>
                    </div>
                    {authorized ? <DropList id={1}
                                            activeId={activeDroppedList}
                                            setActiveId={setDroppedListId} list={resumesList}
                                            valueDropped={typeof resumeIsFile === "boolean" && resumeIsFile === true}
                                            onSelect={onSetSelectedUserResume}
                                            listName="Выбрать из профиля"
                                            margin="0 0 10px 0"/> : null}
                    <DropList id={2}
                              activeId={activeDroppedList}
                              setActiveId={setDroppedListId} list={startDates} onSelect={selectDateStart}
                              listName="Когда готов приступить"
                              margin="0 0 20px 0"/>

                    <div className={s.btnWrapp}><Button
                        onClick={onSendApplication}
                        btnText="Записаться"
                        variant={4}/></div>
                    <p className={s.errorText}>{errorText}</p>
                    {/* <div className={s.linksBar}>
                        <a className={s.agreementLink} href="#">Нажимая
                            кнопку, Вы даете согласие <br/>
                            на обработку ваших персональных данных.</a>
                    </div> */}
                </div>
            </div>
        </div>
    )
}
