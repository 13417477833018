import s from './ProbationStepsBlock.module.css'

import ProbationStep from "./ProbationStep"


const ProbationStepsBlock = () => {

    return (
        <div className={s.wrapper}>
            <p className={s.titleProbation}>Этапы отбора на стажировку</p>

            <div className={`${s.row} ${s.rowColumn}`}>
                <div className={`${s.row} ${s.firstRow}`}>
                    <ProbationStep title="Выбери программу"
                                   content="Найди направление, в котором хочешь работать."
                                   isLeft stage={1}/>
                    <ProbationStep title="Пройди собеседование"
                                   content="Расскажи о своих интересах и стремлениях – в жизни, в профессии, в компании."
                                   className={s.mb1} stage={2}/>
                    <ProbationStep title="Выбери наставника"
                                   content="Познакомься с коллегами, которые помогут тебе вырасти в компании. Выбери того, с кем захочешь добиться больших успехов."
                                   isGreen
                                   className={s.mb2}
                                   isLeft stage={3}/>
                </div>
                <div className={`${s.row} ${s.secondRow}`}>
                    <ProbationStep title="Пройди стажировку"
                                   stage = {4}
                                   content="Получай опыт и знания. Участвуй в реальных проектах. Стремись делать больше, чем от тебя ожидают. Покажи, что достоин стать частью команды лидеров!"/>
                    <ProbationStep title="Получи характеристику и рекомендации"
                                   content="Прислушайся к советам наставников и коллег. Пойми, как они помогут тебе развиваться."
                                   className={s.mb1}
                                   isLeft stage = {5}/>
                    <ProbationStep title="Приходи работать в МегаФон"
                                   content="Начинай путь к успеху и становись нашим коллегой!"
                                   isGreen
                                   stage = {6}
                                   className={[s.mb2]}
                    />
                </div>
            </div>
        </div>
    )

}

export default ProbationStepsBlock