import s from "./TestSingleAnswer.module.css";

import { useEffect, useState } from "react";

const TestMultipleAnswer = ({ answers, onChange }) => {
  const [selectedAnswers, setSelectedAnswers] = useState([]);

  useEffect(() => {
    onChange(selectedAnswers)
  }, [selectedAnswers]);
  return (
    <div className="">
      {answers.map((a, i) => (
        <div
          className={s.row}
          key={`multiple-${i}`}
          onClick={() => {
            setSelectedAnswers((ans) => {
                var eventAnswers = [...ans]
              let index = eventAnswers.indexOf(a.id);
           
              console.log(index);
              if (index == -1) {
                eventAnswers.push(a.id);
              } else {
                eventAnswers.splice(index, 1);
              }
              return [...eventAnswers];
            });
          }}
        >
          <p
            className={`${s.box} ${s.multiple} ${
              selectedAnswers.includes(a.id) ? s.selected : ""
            }`}
          ></p>
          <p>{a.text}</p>
        </div>
      ))}
    </div>
  );
};

export default TestMultipleAnswer;
