import {Probations} from "./Probations";
import React from "react";
import {connect} from "react-redux";


const mapStateToProps = (state) => {
    return {
        userSubscribe: state.app.userSubscribe,
        authorized: state.app.authorized
    }
}

class ProbationsContainer extends React.Component {
    componentDidMount() {
    }

    render() {
        return <Probations authorized = {this.props.authorized} userSubscribe = {this.props.userSubscribe} isMobile = {this.props.isMobile}/>
    }
}

export default connect(mapStateToProps, {})(ProbationsContainer)