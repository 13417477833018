import * as React from "react";
import {connect} from "react-redux";
import App from "./App";
import {setProfileInfoTH} from "./Redux/Reducers/AppReducer";


const mapStateToProps = (state) => {
    return {
        authorized: state.app.authorized,
        isLoad: state.app.isLoad
    }
}

class AppContainer extends React.Component {
    componentDidMount() {
        this.props.setProfileInfoTH()
    }

    render() {
        return <App isLoad = {this.props.isLoad}  authorized = {this.props.authorized}/>
    }
}

export default connect(mapStateToProps, {setProfileInfoTH})(AppContainer)