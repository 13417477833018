import { useState } from "react"
import { useEffect } from "react"
import s from "./Requestes.module.css"

import { getRequests, getAdminVP, serverLink, readRequest,getResumes,getDirections,getFullResume } from "../../../DAL/ApiRequests"
import { Button } from "../../../Components/Button/Button"
import AdminTable from "../../../Components/AdminTable/AdminTable"
import { useNavigate } from "react-router-dom"

import { DataRow, DataManyText, NETWORKS, EDUCATIONS, CURENCYS } from "../Resumes/Resumes"

import TextModal from "../../../Components/TextModal/TextModal"

const Row = ({title,content, isLink}) =>{
    return (
        <div className={s.row}>
            {!isLink ? (<>
                <p className={s.title}>{title}</p>
                <p>{content}</p>
                </>
            ):(
                <a href={content}>{title}</a>
            )}
            
        </div>
    )
}



const RequestesPage = () =>{

    const [requests,setRequests] = useState([])
    const [onluUnreaded,setOnluUnreaded] = useState(true)
    const [idV,setIdV] = useState(-1)

    const [page,setPage] = useState(1)
    const [count,setCount] = useState(10)
    const [totalCount, setTotalCount] = useState(0);

    const [directions, setDirections] = useState([]);

    const [fullResume,setFullResume] = useState({})

    const [openModal,setOpenModal] = useState(false)

    const navigate = useNavigate()


    useEffect(() => {
        getDirections(1).then(r=>{
            setDirections(r.data)
        })
    }, []);

    const getDirectionsString = (directionsArray) => {
        var directionsDict = {}
        for(var d of directions){
            directionsDict[d.id] = d.name
        }
        var result = ""
        directionsArray.forEach(d=>{
            result += `${directionsDict[d]}; `
        })

        return result
    }

    const requestsTemplate = [
        {
            title:"Телефон",
            key:"phone"
        },
        {
            title:"Почта",
            key:"email"
        },
        {
            title:"Когда готов приступить",
            key:"to_start"
        },
        {
            title:"Документ",
            renderCell:(item)=>{
                if(!item.id_document)
                    return (<p>Нет документа</p>)
                return (<a href={`${serverLink}/get_resume?id_file=${item.id_document}`} >Скачать</a>)
            }
        },
        {
            title:"Полное резюме",
            renderCell:(item)=>{
                return !item.short ? (
                <Button variant={1}
                        backColor={"white"}
                        textColor="black"
                        btnText={"Перейти"}
                        onClick={()=>{
                            getFullResume(item.id_resume).then(r=>{
                                if(r.status == "ok")
                                    setFullResume(r.data)
                            })
                           setOpenModal(true)
                        }}/>) : "Нет"
            }
        },

        {
            title:"Объект заявки",
            renderCell:(item)=>(
                // <a href={`/admin/probation/${item.id_vacancy_probation}`} >Перейти</a>
                <Button variant={1}
                        backColor={"white"}
                        textColor="black"
                        btnText={"Перейти"}
                        onClick={()=>{
                            getAdminVP(item.id_vacancy_probation).then(r=>{
                                if(r.status == "ok"){
                                    if(r.data.program_for != undefined)
                                        navigate(`/admin/probation?id=${item.id_vacancy_probation}`)
                                    else
                                        navigate(`/admin/vacancy?id=${item.id_vacancy_probation}`)
                                }else{
                                    alert(r.message)
                                }
                            })
                        }}/>
            )
        },
        {
            title:"Имя вакансии / стажировки",
            key:"vp_name"
        },
        {
            title:"Прочитано",
            renderCell:(item)=>{
                return  (
                    <input type="checkbox"
                    className={s.checkBox}
                    checked={item.is_read}
                    onChange ={(ev)=>{
                     readRequest(item.id,!item.is_read).then(m=>{
                        getRequests(idV,page,count,onluUnreaded).then(onGetRequests) 
                     })

                    }}/>
                    )
        }}
    ]



    const onGetRequests = (r) => {
        if(r.status == "ok"){
            setRequests(r.data.requests)
            setTotalCount(r.data.total_count)
        }
    }

    useEffect(()=>{
        const url = new URL(window.location.href)
        var idVp = url.searchParams.get("id")
        if (!idVp)
            idVp = 0
        setIdV(idVp)

    },[])

    



    useEffect(()=>{
        getRequests(idV,page,count,onluUnreaded).then(onGetRequests) 
    },[onluUnreaded,page,count,idV])

    return (
        <div className={s.container}>
            <div className={s.df}>
                <input type="checkbox"
                       className={s.checkBox}
                       checked={onluUnreaded}
                       onChange ={(ev)=>{
                        setOnluUnreaded(ev.target.checked)
                       }}/>
                <p className={s.title}>
                    Только не прочитанные
                </p>
            </div>
            
            <div className={s.scroll}>
                <AdminTable rows={requests}
                            template={requestsTemplate}
                            page={page}
                            setPage={setPage}
                            count={count}
                            setCount={setCount}
                            totalCount={totalCount}/>
            </div>
            <TextModal open={openModal}
                       onClose={
                        ()=>{
                            setOpenModal(false)
                        }}
                        full>
                <div className={s.col}>
                    <DataRow title={"Фамилия"} data={fullResume.last_name}/>
                    <DataRow title={"Имя"} data={fullResume.first_name}/>
                    <DataRow title={"Отчество"} data={fullResume.patronymic}/>
                    <DataRow title={"Семейное положение"} data={fullResume.family_status ? "В браке" : "Нет брака"}/>
                    <DataRow title={"Должность"} data={fullResume.position}/>
                    <DataRow title={"Гражданство"} data={fullResume.citizenship}/>
                    <DataRow title={"Второе гражданство"} data={fullResume.second_citizenship}/>
                    <DataRow title={"Город"} data={fullResume.city}/>
                    <DataRow title={"Готовность к перезду"} data={fullResume.ready_to_move ? "Да" : "Нет"}/>
                    <DataRow title={"Страна/Город для перезда"} data={fullResume.target_of_move }/>
                    <DataRow title={"Готовность к командировкам"} data={fullResume.ready_to_trips ? "Да" : "Нет"}/>
                    <DataRow title={"Желаемая зарплата"} data={fullResume.desired_salary}/>
                    <DataRow title={"Валюта"} data={fullResume.currency? CURENCYS[fullResume.currency] : null}/>
                    <DataRow title={"Телефон"} data={fullResume.phone}/>
                    <DataRow title={"Привязанные к телефону сети"} data={fullResume.social_network_on_phone ? fullResume.social_network_on_phone.map(s=>(NETWORKS[s])).join("; ") : null}/>
                    <DataRow title={"Емейл"} data={fullResume.email}/>
                    <DataRow title={"Направления"} data={getDirectionsString(fullResume.directions || [])}/>
                    <DataManyText title={"Образование"} data={fullResume.educations ? "" : "Нет"}/>
                    {fullResume.educations ? fullResume.educations.map(e=>(
                        <div className={s.block}>
                            <DataRow title={"Название учереждения"} data={e.name}/>
                            <DataRow title={"Год окончания обучения"} data={e.date_end}/>
                            <DataRow title={"Институт/ Факультет"} data={e.name_faculty}/>
                            <DataRow title={"Направления обучения"} data={e.direction}/>
                            <DataRow title={"Форма обучения"} data={e.form ? EDUCATIONS[e.form] : null}/>
                        </div>    
                    )): null}
                    <DataRow title={"Опыт работы"} data={fullResume.experience ? "" : "Нет"}/>
                    {fullResume.experience ? fullResume.experience.map(e=>(
                        <div className={s.block}>
                            <DataRow title={"Название организации"} data={e.name}/>
                            <DataRow title={"Должность"} data={e.position}/>
                            <DataRow title={"Старт работы - дата"} data={e.start_date}/>
                            <DataRow title={"Конец работы - дата"} data={e.end_date}/>
                            <DataManyText title={"Описание работы"} data={e.description}/>
                        </div>    
                    )): null}
                    <DataRow title={"Языки"} data={fullResume.languages}/>
                    <DataManyText title={"О себе"} data={fullResume.text_resume}/>



                </div>

            </TextModal>
        </div>
    )
}


export default RequestesPage