import * as React from "react";
import {ConfirmationEmailBar} from "./ConfirmationEmailBar";
import {connect} from "react-redux";
import {confirmEmailTH, resendCodeTH, setConfirmEmailStatus} from "../../Redux/Reducers/AppReducer";

const mapStateToProps = (state) => {
    return {
        confirmEmailStatus: state.app.confirmEmailStatus,
        confEmailError: state.app.errors.confEmailError
    }
}

class ConfirmationEmailBarContainer extends React.Component {
    onSendCode = (code) => {
        return this.props.confirmEmailTH(code)
    }

    onResendCode = (email) => {
        this.props.resendCodeTH(email)
    }

    render() {
        return (
            <ConfirmationEmailBar confEmailError = {this.props.confEmailError} email={this.props.email} confirmStatus={this.props.confirmEmailStatus}
                                  onSendCode={this.onSendCode} onClose={this.props.onClose} onResendCode = {this.onResendCode}/>
        )
    }
}

export default connect(mapStateToProps, {confirmEmailTH, resendCodeTH})(ConfirmationEmailBarContainer)