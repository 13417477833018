import s from "../Home.module.css";
import {Heading} from "../../../Components/Heading/Heading";
import stayPeople from "../../../Assets/pageImages/HomePageImages/stayPeople.png";
import runPeople from "../../../Assets/pageImages/HomePageImages/runPeople.png";
import circlePeople from "../../../Assets/pageImages/HomePageImages/circlePeople.png";
import React from "react";
import "./SimpleScroll.css"


export class SimpleScrollPart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMobile: false
        }
    }

    ScrollContoller= null

   resizeMethod = () => {
        this.setState({isMobile: window.innerWidth - 17})
        this.setState({isMobile: window.innerWidth <= 900})
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeMethod)
        window.onscroll = null
    }

    
    componentDidMount() {
        this.setState({isMobile: window.innerWidth <= 900})

        window.addEventListener("resize", this.resizeMethod)

        class MagicColorsScroll {
            containers = null
            masterContainer = null

            childContainerClass = null
            textScrollerClass = null

            previosY = 0

            selectedIndex = 0

            colors = [
                "#00B856",
                "#FFFFFF",
                "#00B856"]

            constructor(masterContainerClass = ".master_container",
                        mainContainerClass = ".main_container",
                        childContainerClass = ".container",
                        textScrollerClass = ".textScroll",
                        topBreakSelector = "#topBreak",
                        botBreakSelector = "#botBreak",
                        colors = ["#00B856",
                            "#FFFFFF",
                            "#00B856"],) {

                this.containers = document.querySelectorAll(mainContainerClass)
                this.masterContainer = document.querySelector(masterContainerClass)


                this.colors = colors

                // document.body.innerHTML += `
                //     <p id="DATA" style="position:fixed;right:0;bottom:0">FDFSSDFSDF</p>
                // `

                this.childContainerClass = childContainerClass
                this.textScrollerClass = textScrollerClass
                this.masterContainer.style.backgroundColor = colors[0]
                this.initColors()
                this.windowScroller(this)
                const _this = this

                

                // document.body.innerHTML += `<p id="DATA-TEXT" style="position:fixed;right:0;bottom:0">sdfsdf</p>`
                


            }


            getFadeMiddleColor(index, percent) {

                var finishPercent = percent < 1 ? percent < 0 ? 0 : percent : 1;

                var startPercent = 1 - finishPercent;

                var R, G, B;

                var aRGBStart = this.colors[index + 1].replace("#", "").match(/.{2}/g);
                var aRGBFinish = this.colors[index].replace("#", "").match(/.{2}/g);

                R = Math.floor(('0x' + aRGBStart[0]) * startPercent + ('0x' + aRGBFinish[0]) * finishPercent);
                G = Math.floor(('0x' + aRGBStart[1]) * startPercent + ('0x' + aRGBFinish[1]) * finishPercent);
                B = Math.floor(('0x' + aRGBStart[2]) * startPercent + ('0x' + aRGBFinish[2]) * finishPercent);
           
                return 'rgb(' + R+ ',' + G + ',' + B + ')';
            }

            initColors() {
                var i = 0
                this.containers.forEach(c => {
                    if (c.getBoundingClientRect().top <= 0) {
                        this.masterContainer.style.backgroundColor = this.colors[i]
                    }
                    i++
                })
            }

            windowScroller(me) {
                var editable = false
                var animatedContainer = null
                var i = 0

                me.containers.forEach(c => {
                    const client = c.getBoundingClientRect()
                    const currentContainer = c.querySelector(me.childContainerClass)

                    const top = document.querySelector("#topBreak").getBoundingClientRect().top
                    const bottom = document.querySelector("#botBreak").getBoundingClientRect().bottom

                    // window.innerHeight returns height of the visible area. 
                    // We multiply it by zoom and get out real height.

                    if (client.top < top) {
                        if (client.bottom > bottom + (window.innerWidth > 900 ? 200 : 0)) {
                            // alert(`CLIENT ${client.bottom} SCROLL ${window.scrollY}`)
                            currentContainer.style.position = "fixed"
                            currentContainer.style.top = `0px`
                            

                            this.masterContainer.style.backgroundColor = me.colors[i]

                            const textScroller = c.querySelector(me.textScrollerClass)
                        
                            const scroll = (Math.abs(client.top) * textScroller.scrollHeight / client.height) * (window.innerWidth > 900 ? 1.25 : 1)
                            textScroller.scrollTo(0, scroll)

                        } else {
                            currentContainer.style.position = null
                            currentContainer.style.bottom = "0"
                            currentContainer.style.top = null
                            if (client.bottom > 0) {
                                me.selectedIndex = i
                                editable = true
                                animatedContainer = client
                            }

                        }
                    } else {
                        currentContainer.style.position = null
                        currentContainer.style.bottom = null
                        currentContainer.style.top = null
                    }
                    i++
                })
                if (editable) {
                    if (me.selectedIndex < me.colors.length - 1)
                        me.masterContainer.style.backgroundColor = me.getFadeMiddleColor(me.selectedIndex,
                            (animatedContainer.bottom) / (window.innerHeight))
                }

            }


        }

        this.ScrollContoller = new MagicColorsScroll()
        window.onscroll = () => {
            try{
                this.ScrollContoller.windowScroller(this.ScrollContoller)
            }catch(e){

            }
        }
    }

    render() {
        return (
            <div id="Scroll" className="master_container">
                <p className={`${s.breakPlate} ${s.top}`}    id="topBreak"></p>
                <p className={`${s.breakPlate} ${s.bottom}`} id="botBreak"></p>
                <div className="main_container">
                    <div className="container">
                        <div className={s.peopleInfBar}>
                            <div className={s.infText}>
                                <p className={s.subText}>Счастливый <br/> клиент</p>
                                <div><Heading headFontSize={this.state.isMobile ? 50 : null} padding="0" width="80%" headText="начинается с тебя!"
                                              headWidth="90%"/></div>
                                <div className="textScroll">
                                    <div style={{height: "120px", marginBottom: "90px"}}>
                                        <Heading textFontSize={20}  width="80%"
                                                 text="Каждый день мы видим глаза счастливых абонентов.
                                    Это вдохновляет и придает уверенности.
                                     Мы точно знаем, что будущее компании зависит от каждого из нас."
                                     textWidth={this.state.isMobile ? "100%" : "70%"}/>
                                    </div>
                                    <div className="row"><Heading textFontSize={20} width="80%"
                                                                  text="Стань частью команды, которая создает лучшие электронные сервисы."
                                                                  textWidth={this.state.isMobile ? "100%" : "70%"}/>
                                    </div>
                                    <div className="row"><Heading textFontSize={20} width="80%"
                                                                  text="Мы вместе делаем связь для миллионов счастливых абонентов"
                                                                  textWidth={this.state.isMobile ? "100%" : "70%"}/>
                                    </div>
                                    <div className="row"><Heading textFontSize={20} width="80%"
                                                                  text="Наше богатство и наш главный ресурс - наши клиенты"
                                                                  textWidth={this.state.isMobile ? "100%" : "70%"}/>
                                    </div>
                                </div>
                            </div>
                            <img className={s.scrollImg} src={stayPeople} alt="people"/>
                        </div>
                    </div>
                </div>
                <div className="main_container">
                    <div className="container">
                        <div className={s.runPeopleBar}>
                            {!this.state.isMobile ? <img className={s.scrollImg} src={runPeople} alt="runPeople"/>: null}
                            <div style={{margin: "0 0 0 20%"}} className={s.infText}>
                                <p style={{color: "#00B856"}} className={s.subText}>Лидерство</p>
                                <Heading headFontSize={this.state.isMobile ? 50 : null} padding="0" color="black" width="100%" headText="начинается с тебя!"
                                         headWidth="65%"/>
                                <div className="textScroll">
                                    <div className="row"><Heading textFontSize={20} color="black"
                                                                  width="80%"
                                                                  text="Принеси в нашу команду свою энергию и свежие идеи. А мы поможем их реализовать."
                                                                  headWidth="90%"
                                                                  textWidth={this.state.isMobile ? "100%" : "70%"}/>
                                    </div>
                                    <div className="row"><Heading textFontSize={20} color="black"
                                                                  width="80%"
                                                                  text="Присоединяйся к коллективу настоящих профессионалов и лидеров своего дела"
                                                                  headWidth="90%"
                                                                  textWidth={this.state.isMobile ? "100%" : "70%"}/>
                                                                  
                                    </div>
                                    <div className="row"><Heading textFontSize={20} color="black"
                                                                  width="80%"
                                                                  text="Мы постоянно &quot;прокачиваем&quot; наших сотрудников и они делают компанию сильнее с каждым днём"
                                                                  headWidth="90%"
                                                                  textWidth={this.state.isMobile ? "100%" : "70%"}/>
                                    </div>
                                    <div className="row"><Heading textFontSize={20} color="black"
                                                                  width="80%"
                                                                  text="В МегаФоне мы постоянно начинаем и доводим до конца новые проекты, которыми управляем мы сами"
                                                                  headWidth="90%"
                                                                  textWidth={this.state.isMobile ? "100%" : "70%"}/>
                                    </div>
                                </div>
                            </div>
                            {this.state.isMobile ? <img className={s.scrollImg} src={runPeople} alt="runPeople"/>: null}
                        </div>
                    </div>
                </div>
                <div style={{height: "300vh"}} className="main_container">
                    <div className="container ">
                        <div className={s.circlePeopleBar}>
                            <div style={{margin: "0"}} className={s.infText}>
                                <p style={{color: "#731982"}} className={s.subText}>Команда</p>
                                <Heading headFontSize={this.state.isMobile ? 50 : null} padding="0" width="90%" headText="начинается с тебя!"
                                         headWidth="70%" textWidth="90%"/>
                                <div className="textScroll">
                                    <div className="row" style={{height: "120px", marginBottom: "70px"}}><Heading
                                        textFontSize={20}
                                        width="80%" text="В работе мы используем самые современные
                                                                                                        и эффективные методики организации работы наших команд!"
                                        headWidth="70%" textWidth={this.state.isMobile ? "100%" : "60%"}/>
                                    </div>
                                    <div className="row" style={{height: "120px", marginBottom: "70px"}}><Heading
                                        textFontSize={20}
                                        width="80%"
                                        text="В своей работе мы используем не только свои индивидуальные компетенции, а все сильные стороны каждого члена команды"
                                        headWidth="70%" textWidth={this.state.isMobile ? "100%" : "60%"}/>
                                    </div>
                                    <div className="row" style={{height: "120px", marginBottom: "70px"}}><Heading
                                        textFontSize={20}
                                        width="80%"
                                        text="Мы умеем не только усердно и эффективно работать, но и крепко дружить и круто отдыхать"
                                        headWidth="70%" textWidth={this.state.isMobile ? "100%" : "60%"}/>
                                    </div>
                                </div>
                            </div>
                            <img className={s.scrollImg} src={circlePeople} alt="people"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}