import ProbationInfoItem from "../ProbationInfoItem/ProbationInfoItem"
import brain from '../../Assets/pageImages/ProbationPageImages/brain.png'
import note from '../../Assets/pageImages/ProbationPageImages/note.png'
import run from '../../Assets/pageImages/ProbationPageImages/run.png'
import specilisation from '../../Assets/pageImages/ProbationPageImages/specilisation.svg'

import s from './ProbationInfoItems.module.css'


const ProbationInfoItems = ({whatFor,whatTasks,processWalk,canBecome}) =>{
    return (
        <div className={s.container}>
            <ProbationInfoItem title = {"Для кого эта программа"}
                               image = {brain}
                               content={whatFor}/>
            <ProbationInfoItem title = {"Какие задачи необходимо решать"}
                               image = {note}
                               content={whatTasks}/>
            <ProbationInfoItem title = {"В процессе прохождения программы вы научитесь"}
                               image = {run}
                               content={processWalk}/>
            <ProbationInfoItem title = {"Кем вы сможете стать по итогам стажировки"}
                               image = {specilisation}
                               content={canBecome}/>
        </div>
    )
}

export default ProbationInfoItems