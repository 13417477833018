import s from "./Modal.module.css"
import React from "react";


const Modal = ({open,onClose, children}) =>{
    return (
        <div className={s.modalBack}
             style = {{display: open ? "flex" : "none"}}>
            <div className={s.clickableZone}
                 onClick = {() =>{
                     if(onClose)
                        onClose()
                 }}></div>
            {children}
        </div>
    )
}

export default Modal