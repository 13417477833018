import s from "./SpecializationDirection.module.css"

import VerticalArrayChanger from "../../../Components/VerticalArrayChanger/VerticalArrayChanger"
import { useState } from "react"
import { useEffect } from "react"
import { getDirections, getSpecializations, editSpecializations, editDirections } from "../../../DAL/ApiRequests" 


const SpecializationDirection = () =>{
    const [directions,setDirections] = useState([

      ])

      const [specialization,setSpecialization] = useState( [
        
      ])

      const editSpec = (id,text,add = 0) => {
        editSpecializations(id,text,add).then(r=>{
          if(r.status == "ok"){
            getSpec()
          }else{
            alert(r.message)
          }
        })
      }

      const editDirect = (id,text,add = 0) => {
        editDirections(id,text,add).then(r=>{
          if(r.status == "ok"){
            getDirect()
          }else{
            alert(r.message)
          }
        })
      }


      

      const getDirect = () =>{
        getDirections().then(r =>{
          if(r.status == "ok")
            setDirections([...r.data])
          else
            alert(r.message)
        })
      }

      const getSpec = () =>{
        getSpecializations().then(r =>{
          if(r.status == "ok")
            setSpecialization([...r.data])
          else
            alert(r.message)
        })
      }


      useEffect(()=>{
        getDirect()
        getSpec()
        
      },[])



    return (
        <div className={s.container}>
            <div>
                <p className={s.title}>Специализации</p>
                <VerticalArrayChanger data = {specialization} edit={editSpec} create={(text)=>{
                  editSpec(0,text,1)
                }}/>
            </div>
            <div>
                <p className={s.title}>Направления</p>
                <VerticalArrayChanger data = {directions} edit={editDirect} create={(text)=>{
                  editDirect(0,text,1)
                }}/>
            </div>
        
        </div>
    )
}

export default SpecializationDirection