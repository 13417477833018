import {Button} from "../../Components/Button/Button"
import InfoBanner from "../../Components/InfoBanner/InfoBanner"
import ProbationInfoItems from "../../Components/ProbationInfoItems/ProbationInfoItems"
import ProbationStepsBlock from "../../Components/ProbationSteps/ProbationStepsBlock"

import s from './ProbationInfo.module.css'
import React, {useEffect, useState} from "react";
import EmailSubscribeBlockContainer from "../../Components/EmailSubscribeBlock/EmailSubscribeBlockContainer";
import SendApplicationBarContainer from "../../Components/SendApplicationBar/SendApplicationBarContainer";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";

export const ProbationInfo = ({isMobile, probationInfo, userSubscribe, vacancyId, authorized}) => {

    const [showSendApplicationModal, toggleShowSendApplicationModal] = useState(false)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className={s.wrapper}>
            <InfoBanner toggleShowSendApplicationModal={toggleShowSendApplicationModal}
                        probationName={probationInfo?.name_vacancy} description={probationInfo.description}/>
            <div className={s.breadCrumbsBar}><BreadCrumbs
                steps={[["Главная", "/"], ["Стажировка", "/stazhirovki"], [`${probationInfo.name_vacancy}`]]}/></div>
            <ProbationInfoItems whatFor={probationInfo.program_for}
                                whatTasks={probationInfo.what_tasks_need_to_be_solved}
                                processWalk={probationInfo.learn_to}
                                canBecome={probationInfo.result_of_your_internship}/>
            <div className={s.btnWrapp}>
                <Button onClick={() => toggleShowSendApplicationModal(true)} variant={1}
                        backColor={"#00B856"}
                        textColor={"white"}
                        btnText={"Записаться на поток по программе"}/>
            </div>

            <div style={{marginBottom: "150px"}}><ProbationStepsBlock/></div>
            {!userSubscribe && authorized ? <EmailSubscribeBlockContainer isMobile={isMobile}/> : null}
            {showSendApplicationModal ?
                <SendApplicationBarContainer vacancyId={vacancyId} onClose={() => toggleShowSendApplicationModal(false)}
                                             isMobile={isMobile}/> : null}
        </div>)
}
