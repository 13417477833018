import React, { useEffect, useState } from "react";
import s from "./CreateResumeBar.module.css";
import deleteIcon from "../../Assets/Icons/deleteIcon.svg";
import { Input } from "../Input/Input";
import { DropList } from "../DropList/DropList";
import { CheckBox } from "../Checkbox/CheckBox";
import { Button } from "../Button/Button";
import CustomDatePicker from "../DatePicker/DatePicker";
import { ChoiceBar } from "../ChoiceBar/ChoiceBar";
import { ToastsStore } from "react-toasts";
import close from "../../Assets/Icons/close-outline.svg";
import { withMobileDialog } from "@material-ui/core";

export const CreateResumeBar = ({
  isMobile,
  directions,
  selectedDirections,
  currenciesList,
  maritalStatusList,
  educationFormsList,
  sendFunk,
  vacancyId,
  isEditMode,
  sendResumeError,
  authorized,
  onClose,
  tone,
  userName,
  userPhone,
  userEmail,
  userCity,
  enteredMartStatus,
  enteredPosition,
  enteredCitizenship,
  enteredSecondCitizenship,
  enteredWillingMission,
  enteredWillRelocate,
  enteredRelocatePlace,
  enteredSalary,
  enteredSalaryCurrency,
  enteredLanguages,
  enteredDriveLicense,
  enteredAboutMe,
  enteredEducationList,
  enteredExperienceList,
  resumeLink,
  whatsSelected,
  telegramSelected,
  viberSelected,
}) => {
  const [fio, setFio] = useState("");
  const [martStatus, setMartStatus] = useState(0);
  const [position, setPosition] = useState("");
  const [citizenship, setCitizenship] = useState("");
  const [secondCitizenship, setSecondCitizenship] = useState("");
  const [city, setCity] = useState("");
  const [relocatePlace, setRelocatePlace] = useState("");
  const [salary, setSalary] = useState("");
  const [salaryCurrency, setSalaryCurrency] = useState(1);
  const [phone, setPhone] = useState("");
  const [haveWats, setHaveWats] = useState(false);
  const [haveTelegram, setHaveTelegram] = useState(false);
  const [haveViber, setHaveViber] = useState(false);
  const [email, setEmail] = useState("");
  const [languages, setLanguages] = useState("");
  const [driveLicense, setDriveLicense] = useState("");
  const [aboutMe, setAboutMe] = useState(enteredAboutMe || "");
  const [selectDirections, setSelectDirections] = useState(
    selectedDirections || []
  );
  const [link, setLink] = useState("");
  const [file, setFile] = useState(undefined);
  const [isWillingMission, toggleIsWillingMission] = useState(false);
  const [isHaveTwoCitizenship, toggleIsHaveTwoCitizenship] = useState(false);
  const [isWillingRelocate, toggleIsWillingRelocate] = useState(false);
  const [addEducationIsOpen, toggleEducationIsOpen] = useState(false);
  const [addExpIsOpen, toggleExpIsOpen] = useState(false);
  const [activeDropList, setActiveDropList] = useState(0);

  const [educationsList, setEducationsList] = useState([]);
  const [showedEducationId, setShowedEducationId] = useState(-1);
  const [educPlaceName, setEducPlaceName] = useState("");
  const [educEndYear, setEducEndYear] = useState("");
  const [educInstName, setEducInstName] = useState("");
  const [educDirection, setEducDirection] = useState("");
  const [educFormId, setEducFormId] = useState(0);

  const [experienceList, setExperienceList] = useState([]);
  const [showedExperienceId, setShowedExperienceId] = useState(-1);
  const [expPlaceName, setExpPlaceName] = useState("");
  const [expPostName, setExpPostName] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [workDescription, setWorkDescription] = useState("");
  const [requiredFieldsIsShowed, toggleRequiredFieldsIsShowed] =
    useState(false);

  const [fioIsValid, setFioIsValid] = useState(undefined);
  const [positionIsValid, setPositionIsValid] = useState(undefined);
  const [citizenshipIsValid, setCitizenshipIsValid] = useState(undefined);
  const [secondCitizenshipIsValid, setSecondCitizenshipIsValid] =
    useState(undefined);
  const [cityIsValid, setCityIsValid] = useState(undefined);
  const [relocatePlaceIsValid, setRelocatePlaceIsValid] = useState(undefined);
  const [salaryIsValid, setSalaryIsValid] = useState(undefined);
  const [phoneIsValid, setPhoneIsValid] = useState(undefined);
  const [emailIsValid, setEmailIsValid] = useState(undefined);

  const [errorText, setErrorText] = useState("");

  const [selectedResumeDirections, setSelectedResumeDirections] =
    useState(selectedDirections);

  const startEducItemName = "Образование";
  const startExpItemName = "Организация";

  const onSend = () => {
    toggleRequiredFieldsIsShowed(true);
    const showToast = () => {
      ToastsStore.error("Корректно заполните все необходимые поля");
    };
    if (
      fioIsValid &&
      fio &&
      positionIsValid &&
      position &&
      citizenshipIsValid &&
      citizenship &&
      cityIsValid &&
      city &&
      salaryIsValid &&
      salary &&
      phoneIsValid &&
      phone &&
      emailIsValid &&
      email &&
      martStatus > 0 &&
      selectDirections.length > 0
    ) {
      let secCitIsValid = isHaveTwoCitizenship
        ? secondCitizenshipIsValid
        : true;
      let relocateIsValid = isWillingRelocate ? relocatePlaceIsValid : true;
      let isWillMission = typeof isWillingMission === "number";
      let isWillRelocate = typeof isWillingRelocate === "number";
      if (secCitIsValid && relocateIsValid) {
        let socialsArr = [];
        if (haveWats) socialsArr.push(1);
        if (haveTelegram) socialsArr.push(2);
        if (haveViber) socialsArr.push(3);
        let resumeFileOrLink = file || link;
        let experiences = [...experienceList];
        for (let i = 0; i <= experiences.length - 1; ++i) {
          experiences[i].exp_start_date = experiences[i].exp_start_date
            .toLocaleString()
            .split(",")[0]
            .split(".")
            .join("/");
          experiences[i].exp_end_date = experiences[i].exp_end_date
            .toLocaleString()
            .split(",")[0]
            .split(".")
            .join("/");
        }
        let first_name = fio.split(" ")[1] || "";
        let patronymic = fio.split(" ")[2] || "";
        let last_name = fio.split(" ")[0] || "";
        let family_status = martStatus !== 1;

        sendFunk(
          typeof resumeFileOrLink === "string"
            ? {
                mainData: {
                  first_name,
                  last_name,
                  patronymic,
                  family_status,
                  position,
                  citizenship,
                  second_citizenship: secondCitizenship,
                  city,
                  ready_to_move: isEditMode
                    ? isWillingRelocate
                    : isWillRelocate,
                  ready_for_trips: isEditMode
                    ? isWillingMission
                    : isWillMission,
                  target_of_move: relocatePlace,
                  desired_salary: salary,
                  currency: salaryCurrency,
                  phone,
                  social_network_on_phone: socialsArr,
                  email,
                  educations: educationsList,
                  experience: experiences,
                  languages,
                  text_resume: aboutMe,
                  directions: selectDirections,
                  id_vacancy: vacancyId,
                  link_or_text: resumeFileOrLink,
                  drive_license: driveLicense,
                },
              }
            : {
                mainData: {
                  first_name,
                  last_name,
                  patronymic,
                  family_status,
                  position,
                  citizenship,
                  second_citizenship: secondCitizenship,
                  city,
                  ready_to_move: isEditMode
                    ? isWillingRelocate
                    : isWillRelocate,
                  ready_for_trips: isEditMode
                    ? isWillingMission
                    : isWillMission,
                  target_of_move: relocatePlace,
                  desired_salary: salary,
                  currency: salaryCurrency,
                  phone,
                  social_network_on_phone: socialsArr,
                  email,
                  educations: educationsList,
                  experience: experiences,
                  languages,
                  text_resume: aboutMe,
                  directions: selectDirections,
                  id_vacancy: vacancyId,
                  drive_license: driveLicense,
                },
                file: resumeFileOrLink,
              }
        ).then((status) => {
          if (status === "ok") {
            onClose();
          }
        });
      } else {
        showToast();
      }
    } else {
      showToast();
    }
    if (!fio) {
      setErrorText("ФИО не может быть пустым");
    } else if (martStatus <= 0) {
      setErrorText("Укажите ваше семейное положение");
    } else if (!position) {
      setErrorText("Укажите желаемую должность");
    } else if (!citizenship) {
      setErrorText("Укажите ваше гражданство");
    } else if (!city) {
      setErrorText("Город не может быть пустым");
    } else if (!salary) {
      setErrorText("Укажите желаемую зарплату");
    } else if (+salary < 10 || +salary > 999999) {
      setErrorText("Некорректная заработная плата");
    } else if (!phone) {
      setErrorText("Укажите ваш номер телефона");
    } else if (!email) {
      setErrorText("Укажите ваш email");
    } else if (selectDirections.length === 0) {
      setErrorText("Выберите интересующие вас направления");
    } else {
      setErrorText("");
    }
  };

  useEffect(() => {
    setFio(userName);
    setPhone(userPhone);
    setEmail(userEmail);
    setCity(userCity);

    if (isEditMode) {
      setPosition(enteredPosition);
      setCitizenship(enteredCitizenship);
      setSalary(enteredSalary);
      setLanguages(enteredLanguages);
      setDriveLicense(enteredDriveLicense);
      setAboutMe(enteredAboutMe || "");
      setSelectDirections(selectedDirections);
      setSelectedResumeDirections(selectedDirections);
      setHaveWats(whatsSelected);
      setHaveTelegram(telegramSelected);
      setHaveViber(viberSelected);
      toggleIsHaveTwoCitizenship(enteredSecondCitizenship?.length > 0);
      setSecondCitizenship(enteredSecondCitizenship);
      toggleIsWillingRelocate(enteredWillRelocate);
      setRelocatePlace(enteredRelocatePlace);
      toggleIsWillingMission(enteredWillingMission);
      setMartStatus(enteredMartStatus);
      setSalaryCurrency(enteredSalaryCurrency);
      if (enteredEducationList?.length > 0) {
        setEducationsList(enteredEducationList);
        toggleEducationIsOpen(true);
        setShowedEducationId(
          enteredEducationList[enteredEducationList.length - 1].id
        );
      }
      if (enteredExperienceList?.length > 0) {
        let newList = enteredExperienceList.map((exp) => {
          exp.exp_start_date = new Date(exp.exp_start_date);
          exp.exp_end_date = new Date(exp.exp_end_date);
          return exp;
        });
        setExperienceList(newList);
        toggleExpIsOpen(true);
        setShowedExperienceId(newList[newList.length - 1].id);
      }
    }
  }, [
    userName,
    enteredPosition,
    enteredCitizenship,
    userCity,
    enteredSalary,
    userPhone,
    userEmail,
    enteredLanguages,
    enteredDriveLicense,
    enteredAboutMe,
    selectedDirections,
    whatsSelected,
    telegramSelected,
    viberSelected,
    enteredWillRelocate,
    enteredSecondCitizenship,
    enteredRelocatePlace,
    enteredWillingMission,
    enteredMartStatus,
    enteredSalaryCurrency,
    enteredEducationList,
    enteredExperienceList,
  ]);

  useEffect(() => {
    if (showedEducationId !== -1) {
      const currentEduc = educationsList.find(
        (educ) => educ.id === showedEducationId
      );
      if (currentEduc.name && currentEduc.name !== startEducItemName) {
        setEducPlaceName(currentEduc.name);
      } else {
        setEducPlaceName("");
      }
      setEducEndYear(currentEduc.edu_date_end);
      setEducInstName(currentEduc.edu_name_faculty);
      setEducDirection(currentEduc.edu_direction);
      setEducFormId(currentEduc.edu_form);
    }
  }, [showedEducationId]);

  useEffect(() => {
    if (showedExperienceId !== -1) {
      const currentExp = experienceList.find(
        (exp) => exp.id === showedExperienceId
      );
      if (currentExp.name && currentExp.name !== startExpItemName) {
        setExpPlaceName(currentExp.name);
      } else {
        setExpPlaceName("");
      }
      setExpPostName(currentExp.exp_position);
      setStartDate(currentExp.exp_start_date);
      setEndDate(currentExp.exp_end_date);
      setWorkDescription(currentExp.exp_description);
    }
  }, [showedExperienceId]);

  useEffect(() => {
    if (showedEducationId !== -1) {
      let currentEducation = educationsList.find(
        (educ) => educ.id === showedEducationId
      );
      currentEducation.name = educPlaceName;
      currentEducation.edu_date_end = educEndYear;
      currentEducation.edu_name_faculty = educInstName;
      currentEducation.edu_direction = educDirection;
      currentEducation.edu_form = educFormId;
      let newList = [...educationsList];
      newList[newList.findIndex((educ) => educ.id === showedEducationId)] =
        currentEducation;
      setEducationsList(newList);
    }
  }, [educPlaceName, educEndYear, educInstName, educDirection, educFormId]);

  useEffect(() => {
    if (showedExperienceId !== -1) {
      let currentExperience = experienceList.find(
        (exp) => exp.id === showedExperienceId
      );
      currentExperience.name = expPlaceName;
      currentExperience.exp_position = expPostName;
      currentExperience.exp_start_date = startDate;
      currentExperience.exp_end_date = endDate;
      currentExperience.exp_description = workDescription;
      let newList = [...experienceList];
      newList[newList.findIndex((exp) => exp.id === showedExperienceId)] =
        currentExperience;
      setExperienceList(newList);
    }
  }, [expPlaceName, startDate, endDate, expPostName, workDescription]);

  useEffect(() => {}, [isWillingRelocate]);

  const dropEducationData = () => {
    setEducFormId(0);
    setEducPlaceName("");
    setEducDirection("");
    setEducInstName("");
    setEducEndYear("");
  };

  const dropExperienceData = () => {
    setExpPlaceName("");
    setExpPostName("");
    setStartDate(new Date());
    setEndDate(new Date());
    setWorkDescription("");
  };

  const onAddEducation = () => {
    dropEducationData();
    toggleEducationIsOpen(true);
    let newItemId =
      educationsList.length > 0
        ? educationsList[educationsList?.length - 1].id + 1
        : 0;
    let newEducation = {
      id: newItemId,
      name: startEducItemName,
      edu_date_end: "",
      edu_name_faculty: "",
      edu_direction: "",
      edu_form: 0,
    };
    const newEducationList = [...educationsList, newEducation];
    setShowedEducationId(newItemId);
    setEducationsList(newEducationList);
  };

  const onAddExperience = () => {
    dropExperienceData();
    toggleExpIsOpen(true);
    let newItemId =
      experienceList.length > 0
        ? experienceList[experienceList?.length - 1].id + 1
        : 0;
    let newExperience = {
      id: newItemId,
      name: startExpItemName,
      exp_position: "",
      exp_start_date: new Date(),
      exp_end_date: new Date(),
      exp_description: "",
    };
    const newExperienceList = [...experienceList, newExperience];
    setShowedExperienceId(newItemId);
    setExperienceList(newExperienceList);
  };

  const onDeleteEducation = () => {
    let newList =
      educationsList.filter((educ) => educ.id !== showedEducationId) || [];
    if (educationsList.length > 1) {
      setShowedEducationId(newList[newList.length - 1].id);
    } else {
      dropEducationData();
      setShowedEducationId(-1);
      toggleEducationIsOpen(false);
    }
    setEducationsList(newList);
  };

  const onDeleteExperience = () => {
    let newList =
      experienceList.filter((exp) => exp.id !== showedExperienceId) || [];
    if (experienceList.length > 1) {
      setShowedExperienceId(newList[newList.length - 1].id);
    } else {
      dropExperienceData();
      setShowedExperienceId(-1);
      toggleExpIsOpen(false);
    }
    setExperienceList(newList);
  };

  const onSetWorkDescription = (e) => {
    setWorkDescription(e.target.value);
  };
  const onSetAboutMe = (e) => {
    setAboutMe(e.target.value);
  };

  const onSetActiveList = (id) => {
    if (id === activeDropList) {
      setActiveDropList(0);
    } else {
      setActiveDropList(id);
    }
  };

  const onSelectDirections = (id) => {
    let newSelDirs = [...selectDirections];
    if (
      newSelDirs.find((i) => i === id) ||
      newSelDirs.find((i) => i === id) === 0
    ) {
      setSelectDirections(newSelDirs.filter((i) => i !== id));
    } else {
      newSelDirs.push(id);
      setSelectDirections(newSelDirs);
    }
  };

  return (
    <div
      onClick={() => {
        setActiveDropList(0);
        onClose();
      }}
      className={s.wrapper}
    >
      <div
        onClick={(e) => {
          setActiveDropList(0);
          e.stopPropagation();
        }}
        className={s.subWrapper}
      >
        <img
          className={s.closeIcon}
          alt="close"
          onClick={() => {
            onClose();
          }}
          src={close}
        />
        <p className={s.head}>
          {!isEditMode ? "Создайте свое резюме" : "Редактирование резюме"}
        </p>
        <div className={s.fioFamilyBox}>
          <Input
            callBackChangeText={setFio}
            margin={!isMobile ? "0 30px 0 0" : null}
            flexValue={2}
            inputType="text"
            placeholderText="ФИО"
            required={true}
            showRequired={requiredFieldsIsShowed}
            maxLength={60}
            minLength={2}
            onSetValid={setFioIsValid}
            startText={userName}
          />
          {!isMobile ? (
            <div className={s.requiredListBox}>
              <DropList
                margin="10px 0 0 0"
                onSelect={setMartStatus}
                activeId={activeDropList}
                setActiveId={onSetActiveList}
                id={1}
                listName="Семейное положение"
                list={maritalStatusList}
                startValue={
                  maritalStatusList?.find((stat) => stat.id === martStatus)
                    ?.name || undefined
                }
                startIsPriority={true}
              />{" "}
              <p
                style={
                  !requiredFieldsIsShowed || martStatus > 0
                    ? { opacity: "0" }
                    : null
                }
                className={s.reqStar}
              >
                *
              </p>
            </div>
          ) : null}
        </div>
        <Input
          callBackChangeText={setPosition}
          margin="0 0 24px 0"
          inputType="text"
          placeholderText="Должность"
          required={true}
          showRequired={requiredFieldsIsShowed}
          maxLength={60}
          minLength={2}
          onSetValid={setPositionIsValid}
          startText={enteredPosition}
        />
        {isMobile ? (
          <div className={s.requiredListBox}>
            <DropList
              margin="10px 0 0 0"
              onSelect={setMartStatus}
              activeId={activeDropList}
              setActiveId={onSetActiveList}
              id={1}
              listName="Семейное положение"
              list={maritalStatusList}
            />{" "}
            <p
              style={!requiredFieldsIsShowed ? { opacity: "0" } : null}
              className={s.reqStar}
            >
              *
            </p>
          </div>
        ) : null}

        <div
          style={isMobile ? { flexDirection: "column" } : null}
          className={s.citizenshipBox}
        >
          <Input
            callBackChangeText={setCitizenship}
            margin={!isMobile ? "0 20px 0 0" : "0 0 20px 0"}
            flexValue={1}
            inputType="text"
            placeholderText="Гражданство"
            required={true}
            showRequired={requiredFieldsIsShowed}
            maxLength={50}
            minLength={2}
            onSetValid={setCitizenshipIsValid}
            startText={enteredCitizenship}
          />
          <CheckBox
            onSetChecked={toggleIsHaveTwoCitizenship}
            marginToBox={!isMobile ? "6px 0 0 0" : ""}
            margin={!isMobile ? "0 7px 3px 0" : "0 7px 3px 20px"}
            id={1}
            text="У меня 2 гражданства"
            bool={isEditMode ? isHaveTwoCitizenship : undefined}
          />
          {isHaveTwoCitizenship ? (
            <Input
              callBackChangeText={setSecondCitizenship}
              margin={!isMobile ? "0 0 0 20px" : "20px 0 0 0"}
              flexValue={1}
              inputType="text"
              placeholderText="Второе гражданство"
              required={false}
              maxLength={60}
              minLength={2}
              onSetValid={setSecondCitizenshipIsValid}
              startText={enteredSecondCitizenship || ""}
            />
          ) : null}
        </div>
        <Input
          callBackChangeText={setCity}
          margin="0 0 24px 0"
          inputType="text"
          placeholderText="Город"
          required={true}
          showRequired={requiredFieldsIsShowed}
          maxLength={35}
          minLength={2}
          onSetValid={setCityIsValid}
          startText={userCity}
        />
        <div className={s.mobilityBox}>
          <div className={s.movingBox}>
            <CheckBox
              marginToBox={!isMobile ? "6px 0 0 0" : ""}
              onSetChecked={toggleIsWillingRelocate}
              flexValue={1}
              margin={!isMobile ? "0 7px 3px 0" : "0 7px 3px 20px"}
              id={2}
              text="Готовность к переезду"
              bool={isEditMode ? isWillingRelocate : undefined}
            />
            {isWillingRelocate ? (
              <Input
                callBackChangeText={setRelocatePlace}
                margin={!isMobile ? "0 0 0 20px" : "20px 0 0 0"}
                flexValue={3}
                inputType="text"
                placeholderText="Страна / город, куда Вы готовы переехать"
                required={false}
                maxLength={60}
                onSetValid={setRelocatePlaceIsValid}
                minLength={2}
                startText={enteredRelocatePlace}
              />
            ) : null}
          </div>
          <div className={s.missionBox}>
            <CheckBox
              onSetChecked={toggleIsWillingMission}
              margin={!isMobile ? "0 7px 3px 0" : "0 7px 3px 20px"}
              id={2}
              text="Готовность к командировкам"
              bool={isEditMode ? isWillingMission : undefined}
            />
          </div>
        </div>
        <div className={s.salaryBox}>
          <Input
            callBackChangeText={setSalary}
            margin="0 20px 0 0"
            flexValue={4}
            inputType="number"
            placeholderText="Желаемая зарплата (сомони в месяц)"
            required={true}
            showRequired={requiredFieldsIsShowed}
            maxLength={6}
            minLength={2}
            onSetValid={setSalaryIsValid}
            startText={enteredSalary}
            minNum={10}
            maxNum={999999}
          />
          {/* <DropList margin={!isMobile ? "10px 0 0 0" : "10px 0 0 20px"} onSelect={setSalaryCurrency} id={2}
                          activeId={activeDropList} setActiveId={onSetActiveList}
                          listName="Валюта" list={currenciesList}
                          startValue={currenciesList?.find((cur) => cur.id === salaryCurrency)?.name || "Сомони"}
                          startIsPriority={true}/> */}
        </div>
        <div className={s.phoneBox}>
          <Input
            callBackChangeText={setPhone}
            margin={!isMobile ? "0 20px 0 0" : "0 0 20px 0"}
            flexValue={3}
            inputType="phone"
            placeholderText="Телефон"
            required={true}
            showRequired={requiredFieldsIsShowed}
            maxLength={13}
            minLength={9}
            onSetValid={setPhoneIsValid}
            startText={userPhone}
          />
          <CheckBox
            marginToBox={!isMobile ? "6px 0 0 0" : ""}
            onSetChecked={setHaveWats}
            flexValue={1}
            margin={!isMobile ? "0 7px 3px 0" : "0 7px 3px 10px"}
            id={3}
            text="WhatsApp"
            bool={isEditMode ? haveWats : undefined}
          />
          <CheckBox
            marginToBox={!isMobile ? "6px 0 0 0" : ""}
            onSetChecked={setHaveTelegram}
            flexValue={1}
            margin="0 7px 3px 10px"
            id={4}
            text="Telegram"
            bool={isEditMode ? haveTelegram : undefined}
          />
          <CheckBox
            marginToBox={!isMobile ? "6px 0 0 0" : ""}
            onSetChecked={setHaveViber}
            flexValue={1}
            margin="0 7px 3px 10px"
            id={5}
            text="Viber"
            bool={isEditMode ? haveViber : undefined}
          />
        </div>
        <Input
          required={true}
          showRequired={requiredFieldsIsShowed}
          callBackChangeText={setEmail}
          inputType="email"
          placeholderText="Email"
          onSetValid={setEmailIsValid}
          startText={userEmail}
        />
        <p className={s.subHead}>Образование</p>
        {educationsList.length > 0 ? (
          <ChoiceBar
            startName={startEducItemName}
            activeItem={showedEducationId}
            onChange={setShowedEducationId}
            isManyChoice={false}
            items={educationsList}
          />
        ) : null}
        <div className={s.educationBox}>
          {addEducationIsOpen ? (
            <div className={s.addEducationBox}>
              <div className={s.placeNameRow}>
                <Input
                  isReloadedInput={true}
                  startText={educPlaceName}
                  callBackChangeText={setEducPlaceName}
                  margin={!isMobile ? "0 20px 0 0" : "0 0 24px 0"}
                  flexValue={3}
                  inputType="text"
                  placeholderText="Название учебного заведения"
                />
                <Input
                  isReloadedInput={true}
                  startText={educEndYear}
                  callBackChangeText={setEducEndYear}
                  flexValue={1}
                  inputType="number"
                  placeholderText="Год окончания"
                />
              </div>
              <div className={s.detailsRow}>
                <Input
                  isReloadedInput={true}
                  startText={educInstName}
                  callBackChangeText={setEducInstName}
                  margin={!isMobile ? "0 20px 0 0" : "0 0 24px 0"}
                  flexValue={4}
                  inputType="text"
                  placeholderText="Институт / факультет"
                />
                <Input
                  isReloadedInput={true}
                  startText={educDirection}
                  callBackChangeText={setEducDirection}
                  margin={!isMobile ? "0 20px 0 0" : "0 0 24px 0"}
                  flexValue={4}
                  inputType="text"
                  placeholderText="Направление / специальность"
                />
                <div className={s.subBox}>
                  <div className={s.educateFormBox}>
                    <DropList
                      onSelect={setEducFormId}
                      margin={!isMobile ? "0 20px 0 0" : "0 20px"}
                      id={3}
                      activeId={activeDropList}
                      setActiveId={onSetActiveList}
                      listName="Форма обучения"
                      list={educationFormsList}
                      startIsPriority={true}
                      startValue={
                        educationFormsList.find(
                          (form) => form.id === educFormId
                        ).name
                      }
                    />
                  </div>
                  <div className={s.deleteBox}>
                    <p
                      onClick={() => {
                        onDeleteEducation();
                      }}
                    >
                      Удалить <img src={deleteIcon} alt="delete" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <Button
            onClick={onAddEducation}
            width={isMobile ? "100%" : ""}
            variant={6}
            btnText="+ Добавить учебное заведение"
          />
        </div>
        <p className={s.subHead}>Опыт работы</p>
        {experienceList.length > 0 ? (
          <ChoiceBar
            startName={startExpItemName}
            activeItem={showedExperienceId}
            onChange={setShowedExperienceId}
            isManyChoice={false}
            items={experienceList}
          />
        ) : null}
        {addExpIsOpen ? (
          <div className={s.experienceBox}>
            <div className={s.placeNameRow}>
              <Input
                isReloadedInput={true}
                startText={expPlaceName}
                callBackChangeText={setExpPlaceName}
                margin={!isMobile ? "0 20px 0 0" : "0 0 24px 0"}
                flexValue={3}
                inputType="text"
                placeholderText="Название организации"
              />
              <Input
                isReloadedInput={true}
                startText={expPostName}
                callBackChangeText={setExpPostName}
                flexValue={1}
                inputType="text"
                placeholderText="Должность"
              />
            </div>
            <div className={s.periodRow}>
              <p>Период</p>
              <CustomDatePicker
                value={startDate}
                onChange={(date) => setStartDate(date)}
              />
              <p>-</p>
              <CustomDatePicker
                value={endDate}
                onChange={(date) => setEndDate(date)}
              />
            </div>
            <div className={s.withDeleteRow}>
              <div className={s.textBox}>
                <textarea
                  value={workDescription}
                  onChange={onSetWorkDescription}
                  placeholder="Должностные обязанности, достижения"
                />
              </div>
              <div className={s.deleteBox}>
                <p onClick={() => onDeleteExperience()}>
                  Удалить <img src={deleteIcon} alt="delete" />
                </p>
              </div>
            </div>
          </div>
        ) : null}
        <Button
          onClick={onAddExperience}
          width={isMobile ? "100%" : ""}
          margin={addExpIsOpen ? "24px 0 15px 0" : "0 0 15px 0"}
          variant={6}
          btnText="+ Добавить место работы"
        />
        <p className={s.subHead}>Дополнительно</p>
        <div className={s.additionallyBox}>
          <Input
            callBackChangeText={setLanguages}
            inputType="text"
            placeholderText="Языки"
            startText={enteredLanguages}
          />
          <Input
            callBackChangeText={setDriveLicense}
            inputType="text"
            placeholderText="Водительскте права: категории"
            startText={enteredDriveLicense}
          />
          <div className={s.textBox}>
            <textarea
              value={aboutMe}
              onChange={onSetAboutMe}
              placeholder="Расскажите о себе:"
            />
          </div>
        </div>
        <div className={s.choiceDirectionsBox}>
          <p className={s.subHead}>
            {!isEditMode
              ? "Выберите интересное для Вас направление работы"
              : "Выбранные направления:"}
          </p>
          <ChoiceBar
            isManyChoice={true}
            selectedItemsIds={selectedResumeDirections}
            onChange={onSelectDirections}
            items={directions}
          />
        </div>
        <div className={s.choiceFileBox}>
          <p className={s.subHead}>
            {!isEditMode
              ? "Прикрепите файл резюме, либо вставьте ссылку на него"
              : "Прикрепите новый файл резюме, либо вставьте ссылку на него"}
          </p>
          <Input
            tone="dark"
            margin="0 0 0 0"
            callBackChangeFile={setFile}
            callBackChangeText={setLink}
            placeholderText="Ваш файл или ссылка"
            inputType="fileOrText"
          />
          {isEditMode && resumeLink ? (
            <div className={s.getLinkBar}>
              <a className={s.loadFile} target="_blank" href={resumeLink}>
                Скачать текущий файл
              </a>
            </div>
          ) : null}
        </div>
        <div className={s.sendBtnBox}>
          <Button
            onClick={() => {
              onSend();
            }}
            margin="40px 0 0 0"
            variant={4}
            btnText={!isEditMode ? "Отправить" : "Сохранить"}
          />
        </div>
        <p className={s.errorText}>{sendResumeError?.[0] || errorText}</p>
      </div>
    </div>
  );
};
