import React, {useEffect, useState} from "react";
import arrowDown from "../../Assets/Icons/downArrow.svg"
import hoveredArrow from "../../Assets/Icons/hoveredArrowDown.svg"
import arrowLeft from "../../Assets/Icons/arrowLeft.svg"
import planet2 from "../../Assets/pageImages/HomePageImages/planet.svg"
import astronaut from "../../Assets/pageImages/HomePageImages/astronaut.png"
import icon1 from "../../Assets/pageImages/HomePageImages/icon1.png"
import icon2 from "../../Assets/pageImages/HomePageImages/icon2.png"
import icon3 from "../../Assets/pageImages/HomePageImages/icon3.png"
import icon4 from "../../Assets/pageImages/HomePageImages/icon4.png"
import icon5 from "../../Assets/pageImages/HomePageImages/icon5.png"
import icon6 from "../../Assets/pageImages/HomePageImages/icon6.png"
import icon7 from "../../Assets/pageImages/HomePageImages/icon7.png"
import icon8 from "../../Assets/pageImages/HomePageImages/icon8.png"
import dialogIcon from "../../Assets/pageImages/HomePageImages/dialogIcon.svg"
import threePeople from "../../Assets/pageImages/HomePageImages/threePeopleIcon.svg"
import threePeopleLid from "../../Assets/pageImages/HomePageImages/threePeopleLidIcon.svg"
import heart from "../../Assets/pageImages/HomePageImages/heartIcon.svg"

import s from "./Home.module.css"
import ChoiceDirectionBarContainer from "../../Components/ChoiceDirectionBar/ChoiceDirectionBarContainer";
import {Heading} from "../../Components/Heading/Heading";
import {Button} from "../../Components/Button/Button";

import {SimpleScrollPart} from "./SimpleScrollPart/SimpleScrollPart";
import WorkWithUsSlider from "../../Components/slickSlider/HomeSliders/WorkWithUsSlider/WorkWithUsSlider";
import MainSlider from "../../Components/slickSlider/HomeSliders/MainSlider/MainSlider";
import CardSlider from "../../Components/slickSlider/HomeSliders/CardsSlider/CardSlider";

import EmailSubscribeBlockContainer from "../../Components/EmailSubscribeBlock/EmailSubscribeBlockContainer"
import {NavLink} from "react-router-dom";
import close from "../../Assets/Icons/close-outline.svg";

let circlesList = [{
    backgroundColor: "#00B856",
    width: "146px",
    height: "146px",
    top: "1450px",
    left: "281px",
    borderRadius: "50%"
}, {
    backgroundColor: "#00B856",
    width: "104px",
    height: "104px",
    top: "1650px",
    left: "707px",
    borderRadius: "50%"
}, {
    backgroundColor: "#00B856",
    width: "172px",
    height: "172px",
    top: "1390px",
    left: "1027px",
    borderRadius: "50%"
}, {
    backgroundColor: "#00B856",
    width: "260px",
    height: "260px",
    top: "1410px",
    left: "1414px",
    borderRadius: "50%"
}, {
    backgroundColor: "#731982",
    width: "54px",
    height: "54px",
    top: "calc(2236px - 400px)",
    left: "967px",
    borderRadius: "50%"
}, {
    backgroundColor: "white",
    width: "26px",
    height: "26px",
    top: "calc(2286px - 400px)",
    left: "911px",
    borderRadius: "50%"
}, {
    backgroundColor: "white",
    width: "24px",
    height: "24px",
    top: "calc(2436px - 350px)",
    left: "1731px",
    borderRadius: "50%"
}, {
    backgroundColor: "white",
    width: "46px",
    height: "46px",
    top: "calc(2697px - 350px)",
    left: "calc(1505px + 70px)",
    borderRadius: "50%"
}, {
    backgroundColor: "#731982",
    width: "62px",
    height: "62px",
    top: "calc(2730px - 350px)",
    left: "calc(1569px + 70px)",
    borderRadius: "50%"
}, {
    backgroundColor: "#731982",
    width: "294px",
    height: "294px",
    top: "calc(2946px - 400px)",
    left: "765px",
    borderRadius: "50%"
}, {
    backgroundColor: "#731982",
    width: "300px",
    height: "300px",
    top: "calc(3053px - 420px)",
    left: "-134px",
    borderRadius: "50%"
}, {
    backgroundColor: "white",
    width: "106px",
    height: "106px",
    top: "calc(3864px - 600px)",
    left: "1769px",
    borderRadius: "50%"
}, {
    backgroundColor: "white",
    width: "262px",
    height: "262px",
    top: "calc(4117px - 400px)",
    left: "-100px",
    borderRadius: "50%"
}, {
    backgroundColor: "#731982",
    width: "106px",
    height: "106px",
    top: "calc(4437px - 400px)",
    left: "702px",
    borderRadius: "50%"
}, {
    backgroundColor: "#731982",
    width: "498px",
    height: "498px",
    top: "calc(4049px - 400px)",
    left: "1532px",
    borderRadius: "50%"
}, {
    backgroundColor: "#731982",
    width: "284px",
    height: "284px",
    top: "calc(5362px - 350px)",
    left: "1428px",
    borderRadius: "50%"
},
    {
        backgroundColor: "#00B856",
        width: "223px",
        height: "223px",
        top: "calc(5500px)",
        left: "800px",
        borderRadius: "50%"
    },
    {
        backgroundColor: "#731982",
        width: "268px",
        height: "268px",
        top: "calc(6557px - 230px)",
        left: "23px",
        borderRadius: "50%"
    }, {
        backgroundColor: "#00B856",
        width: "440px",
        height: "440px",
        top: "calc(6433px - 250px)",
        left: "1600px",
        borderRadius: "50%"
    }
    , {
        backgroundColor: "#731982",
        width: "121px",
        height: "121px",
        top: "calc(7000px)",
        left: "1000px",
        borderRadius: "50%"
    }
    , {
        backgroundColor: "#731982",
        width: "215px",
        height: "215px",
        top: "calc(7800px)",
        left: "400px",
        borderRadius: "50%"
    }
    , {
        backgroundColor: "#00B856",
        width: "440px",
        height: "440px",
        top: "calc(9200px)",
        left: "700px",
        borderRadius: "50%"
    }
    , {
        backgroundColor: "white",
        width: "175px",
        height: "175px",
        top: "calc(9800px)",
        left: "0px",
        borderRadius: "50%"
    }
    , {
        backgroundColor: "#00B856",
        width: "90px",
        height: "90px",
        top: "calc(9990px)",
        left: "1700px",
        borderRadius: "50%"
    }
    , {
        backgroundColor: "#00B856",
        width: "240px",
        height: "240px",
        top: "calc(10200px)",
        left: "1000px",
        borderRadius: "50%"
    }
]


export const Home = ({isMobile, userSubscribe, authorized, onSubscribe, userEmail}) => {


    const [descriptionModal, setDescriptionModal] = useState(-1)
    const [agitCardNum, setAgitCardNum] = useState(1)
    const [agitIsOpen, setAgitIsOpen] = useState(false)

    const descriptionData = [{
        sourceName: "MegaMusic",
        description: "Лучший портал для меломанов - миллионы треков отечественных и зарубежных исполнителей",
        link: "https://megamusic.tj/"
    }, {
        sourceName: "Mega Quest",
        description: "Собери как можно больше МегаКоинов в AR! Лучших участников #МегаКвеста ждут награды",
        link: "https://megaquest.megafon.tj/"
    }, {
        sourceName: "Академия МегаФон",
        description: "Онлайн-университет востребованных профессий",
        link: "http://academy.megafon.tj/"
    }, {
        sourceName: "MegaMovie",
        description: "Лучшие премьеры, дерзские сиквелы и бессмертная класика ждет вас на нашем портале кино и сериалов!",
        link: "https://megamovie.tj/"
    }, {
        sourceName: "МегаФон Life",
        description: "Кошелек, личный кабинет и многое другое - максимум возможностей с одним приложением.",
        link: "https://life.tj/"
    }, {
        sourceName: "Футбол+",
        description: "Приложение для настоящих футбольных фанатов - зрителей телеканала Футбол+!",
        link: "https://www.megafon.tj/podderzhka/novosti/megafon_tadzhikistan_sozdal_prilozhenie_dlya_zriteley_kanala_futbol_/"
    }, {
        sourceName: "Арбуз ТВ",
        description: "Смотри любимые каналы, спорт, познавательные передачи или мультфильмы на мобильных устройствах Android и iOS!",
        link: "https://www.megafon.tj/podderzhka/novosti/arbuz_tv_televizor_v_tvoem_smartfone/"
    }]


    const setModalNum = (num) => {
        if (num === descriptionModal) {
            setDescriptionModal(-1)
        } else {
            setDescriptionModal(num)
        }
    }
    const setAgitModalNum = (num) => {
        if (num === agitCardNum) {
            setAgitCardNum(-1)
        } else {
            setAgitCardNum(num)
        }
    }


    // const circles = circlesList.map((c) => <div key={c.top}
    //     style={{
    //         position: "absolute",
    //         top: `${c.top}`,
    //         left: `${c.left}`,
    //         width: `${c.width}`,
    //         height: `${c.height}`,
    //         borderRadius: "50%",
    //         backgroundColor: `${c.backgroundColor}`,
    //         zIndex: "0"
    //     }}/>
    // )

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const scrollTarget = document.querySelector(".scrollTarget")
        const arrow = document.querySelector("#arrow")
        arrow.addEventListener(("mouseenter"), () => {
            arrow.src = hoveredArrow
        })
        arrow.addEventListener(("mouseleave"), () => {
            arrow.src = arrowDown
        })
        arrow.addEventListener("click", () => {
            scrollTarget.scrollIntoView({block: "start", behavior: "smooth"})
        })
    }, [])

    return (
        <div style={isMobile ? {paddingTop: "84px"} : null} onClick={() => {
            setModalNum(-1)
            // setAgitModalNum(-1)

        }} className={s.wrapper}>
            <div style={isMobile ? {width: "100%"} : null} className={s.sliderBar}>
                <MainSlider/>
            </div>
            <div className={s.arrBar}>
                <p>Начни работать с нами!</p>
                <img id="arrow" className={s.arrowBottomSvg} src={arrowDown} alt="arrow"/>
            </div>

            <div className={s.choiceDirBar}><ChoiceDirectionBarContainer isMobile={isMobile} color="#731982"/></div>
            <div className={`scrollTarget ${s.scrollWrapp}`}><SimpleScrollPart/></div>
            <div className={s.ecosystemBox}>
                <div className={s.planetBox}>
                    <div className={s.planetSubWrapp}>
                        <div className={s.ecoHead}><Heading headFontSize={isMobile ? 30 : 65}
                                                            textFontSize={isMobile ? 18 : 25}
                                                            headText="Экосистема МегаФон"
                                                            text="Стань частью глобальной системы!" color="black"
                                                            headWidth={isMobile ? "60%" : "70%"}
                                                            margin={isMobile ? "0" : "0 0 0 125px"}/></div>
                        <div className={s.planetContainer}>
                            <div className={s.iconsContainer}>
                                <div className={s.iconContainer}><img onClick={(e) => {
                                    setModalNum(0)
                                    e.stopPropagation()
                                }} className={`${s.planIcon}`} src={icon1} alt=""/></div>
                                <div style={{left: "-8px"}} className={s.iconContainer}>
                                    <img onClick={(e) => {
                                        setModalNum(1)
                                        e.stopPropagation()
                                    }} className={`${s.planIcon}`} src={icon2} alt=""/>
                                </div>

                                <div className={s.iconContainer}>
                                    <img onClick={(e) => {
                                        setModalNum(2)
                                        e.stopPropagation()
                                    }} className={`${s.planIcon}`} src={icon3} alt=""/>
                                </div>

                                <div className={s.iconContainer}>
                                    <img onClick={(e) => {
                                        setModalNum(3)
                                        e.stopPropagation()
                                    }} className={`${s.planIcon}`} src={icon4} alt=""/>
                                </div>

                                <div className={s.iconContainer}>
                                    <img onClick={(e) => {
                                        setModalNum(4)
                                        e.stopPropagation()
                                    }} className={`${s.planIcon}`} src={icon5} alt=""/>
                                </div>

                                <div className={s.iconContainer}>
                                    <img onClick={(e) => {
                                        setModalNum(5)
                                        e.stopPropagation()
                                    }} className={`${s.planIcon}`} src={icon6} alt=""/>
                                </div>

                                <div className={s.iconContainer}>
                                    <img onClick={(e) => {
                                        setModalNum(6)
                                        e.stopPropagation()
                                    }} className={`${s.planIcon}`} src={icon7} alt=""/>
                                </div>
                            </div>
                            <div className={s.planet}>
                                <img src={planet2} alt="planet"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={s.activeImgBox}>
                    <div className={s.subWrapp}>
                        <img className={s.astronautImg} src={astronaut} alt="astronaut"/>
                        <div className={s.astroDialog}>
                            <div className={s.dialogSubWrapp}>
                                <img src={dialogIcon} alt="dialog"/>
                                <div className={s.dialogIcon}>{isMobile ? <img src={arrowLeft} alt=""/> : null}
                                    <p>Нажми на сервис экосистемы и узнай подробнее!</p>
                                    {!isMobile ? <img src={arrowLeft} alt=""/> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={s.subImg}>
                    <img src={icon8} alt=""/>
                </div>
            </div>

            <div className={`${s.sliderBar} ${s.cardSliderBar}`}>
                <p className={s.cardSliderHead}>Вакансии</p>
                <CardSlider/>
            </div>
            <div className={s.checkAllWrapp}><NavLink
                to="/vakansii"><Button margin="0 0 100px 0" variant={4} btnText="Посмотреть все вакансии"/></NavLink>
            </div>
            <div className={s.workWithUsBar}>
                <div className={s.workWithUsSubBar}>
                    <div className={s.workWithUsHead}>
                        <div className={s.part}><Heading
                            headFontSize={isMobile ? 30 : 65} centerMode={isMobile}
                            headWidth={!isMobile ? "70%" : "100%"} headText="Начни работать с нами!"/></div>
                        {!isMobile ?
                            <div className={s.part}><img
                                src={threePeople} alt=""/><img src={threePeopleLid} alt=""/><img src={heart} alt=""/>
                            </div> : null}
                    </div>
                    <div className={s.workWithUsContent}>
                        <div className={s.sliderWrapp}>
                            <WorkWithUsSlider
                                agitIsOpen = {agitIsOpen}
                                setAgitIsOpen = {setAgitIsOpen}
                                onSetAgitModalNum={setAgitModalNum}
                            />
                            <AgitCardModal userSubscribe={userSubscribe} authorized={authorized} userEmail={userEmail}
                                            onSubscribe={onSubscribe}
                                            id={agitCardNum} isVisible={agitIsOpen}/>

                        </div>
                    </div>
                </div>
            </div>


            {!userSubscribe && authorized ? <div style={{marginTop:!isMobile ?  "55px" : "20px",width:"100%"}}></div> : null}
            {!userSubscribe && authorized ? <EmailSubscribeBlockContainer isMobile={isMobile}/> : null}
            {descriptionModal !== -1 ? <div className={s.sourceDescriptionWrapper}>
                <div onClick={(e) => e.stopPropagation()}
                     className={s.sourceDescription}>
                    <img className={s.closeIcon} onClick={() => {
                        setDescriptionModal(-1)
                    }} src={close}/>
                    <div className={s.descriptionSubWrap}>
                        <div className={s.sourceName}>
                            <div className={s.pointCircle}/>
                            <p>{descriptionData[descriptionModal]?.sourceName}</p></div>
                        <p>{descriptionData[descriptionModal]?.description}</p>
                        <div className={s.linkBar}><a target="_blank"
                                                      href={descriptionData[descriptionModal]?.link}><Button variant={4}
                                                                                                             width="100%"
                                                                                                             btnText="Подробнее"/></a>
                        </div>
                    </div>
                </div>
            </div> : null}

            {/* {!isMobile ? circles : null} */}
        </div>
    )
}

const AgitCardModal = ({id, onSubscribe, userEmail, authorized, userSubscribe, isVisible}) => {
    const agitsInfo = [
        {
            title: "Отслеживай и откликайся на наши вакансии!",
            instructions: [
                <span>Пройди <a href="/registracziya">регистрацию</a></span>,
                <span>Найди вакансию, которая подходит тебе <a href="/vakansii">здесь</a></span>,
                <span>Загрузи свое резюме в  <a href="/profil">личном кабинете</a></span>,
                "Нажми «Откликнуться» на понравившихся вакансиях",
                <span>На странице <a href="/profil">профиля</a> ты можешь отслеживать статус своих откликов</span>,
                "Не забудь подписаться на нашу рассылку с новыми предложениями",
                "Со всеми кандидатами свяжутся наши HR-специалисты",
                "Пройди конкурсный отбор, докажи, что ты достойный кандидат и добро пожаловать в команду МегаФон!"
            ]
        },
        {
            title: "Если на данный момент ты не нашел подходящую вакансию:",
            instructions: [
                <span><a href="/registracziya">Зарегистрируйся</a> на нашем сайте</span>,
                <span>В разделе <a href="/stazhirovki">«стажировки»</a> выбери подходящую для тебя программу и «волну» стажировки и оставь заявку</span>,
                "С тобой свяжутся наши специалисты и руководители и пригласят к участию в конкурсе",
                "В рамаках стажировки ты познакомишься с внутренними процессами компании, получишь ценный опыт и навыки работы взаимодействуя с разными отделами и руководителями разного уровня",
                "По итогам стажировки мы трудоустраиваем всех выпускников, которые проявили себя!",
                <span>В разделе <a href="/stazhirovki">«стажировки»</a> выбери подходящую для тебя программу и «волну» стажировки и оставь заявку</span>,
                "С тобой свяжутся наши специалисты и руководители и пригласят к участию в конкурсе",
                "В рамаках стажировки ты познакомишься с внутренними процессами компании, получишь ценный опыт и навыки работы взаимодействуя с разными отделами и руководителями разного уровня",
                "По итогам стажировки мы трудоустраиваем всех выпускников, которые проявили себя!",
                ]
        },
        {
            title: "Если на данный момент ты не нашел подходящую вакансию:",
            instructions: [
                (<span>Пройди <a href="/registracziya">регистрацию</a></span>),
                <span>Загрузи свое резюме в <a href="/profil">личном кабинете</a></span>,
                (<span>Выбери файл резюме в <a href="/profil">ЛК</a> и нажми «Отправить»</span>),
                "Наши специалисты из HR-департамента обязательно свяжутся с тобой, когда появится вакансия, которая соответствует твоему профилю и пригласят к дальнейшему прохождению конкурса",
                "Подпишись на нашу рассылку и получай самые свежие обновления!",
                ]
        }
    ]
    let instructionsList = agitsInfo[Math.abs(id)].instructions.map((inst, i) => {
        return <div key={i} className={s.isntructionRow}>
            <div className={s.instCircle}/>
            <p>{inst}</p>
        </div>
    })
    return <div style={{
                        maxHeight: isVisible ? "3000px" : "0px",
                        visibility: isVisible ? "visible" : "hidden"}} className={s.agitModalWrapp}>
        <div className={s.instBar}>
            
            {instructionsList}
            </div>
        {authorized && !userSubscribe ? <Button onClick={() => onSubscribe(userEmail)}
                                                variant={4} btnText="Подписаться!" margin="5px 30px 20px 0"/> : null}
    </div>
}