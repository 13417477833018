import * as React from "react";
import {connect} from "react-redux";
import {SendResumeBar} from "./SendResumeBar";
import {editResumeTH, sendResumeTH, setOpenResumeInfoTH, setSendResumeStatusAC} from "../../Redux/Reducers/AppReducer";


const mapStateToProps = (state) => {
    return {
        userName: state.app.userName,
        userCity: state.app.userCity,
        directions: state.app.directions,
        sendResumeError: state.app.errors.sendResumeError,
        resumeInfo: state.app.resumeInfo,
        authorized: state.app.authorized
    }
}

class SendResumeBarContainer extends React.Component {

    componentDidMount() {
        if (this.props.isEditMode) {
            this.props.setOpenResumeInfoTH(this.props.resumeId)
        }
    }

    componentWillUnmount() {
        this.props.setSendResumeStatusAC({status: "ok", message: ""})
    }

    onSendResume = (userName, userCity, resume, directions) => {
        if (!this.props.isEditMode) {
            return this.props.sendResumeTH(userName, userCity, resume, directions)
        } else {
            return this.props.editResumeTH(userName, userCity, resume, directions, this.props.resumeId)
        }
    }

    render() {
        let enteredUserName;
        let enteredUserCity;
        let selectedDirections;
        let resumeLink;
        if (this.props.isEditMode) {
            enteredUserName = `${this.props.resumeInfo.last_name} ${this.props.resumeInfo.first_name} ${this.props.resumeInfo.patronymic}`
            enteredUserCity = this.props.resumeInfo.city
            selectedDirections = this.props.resumeInfo.resume_directions
            resumeLink = this.props.resumeInfo.link_doc || this.props.resumeInfo.link_or_text
        }
        return <SendResumeBar
            authorized={this.props.authorized}
            isEditMode={this.props.isEditMode} sendResumeError={this.props.sendResumeError}
            onClose={this.props.onClose} isModal={this.props.isModal} tone={this.props.tone}
            isMobile={this.props.isMobile} onSendResume={this.onSendResume}
            userName={this.props.isEditMode ? enteredUserName : this.props.userName}
            userCity={this.props.isEditMode ? enteredUserCity : this.props.userCity}
            directions={this.props.directions}
            selectedDirections={this.props.isEditMode ? selectedDirections : undefined}
            resumeLink={resumeLink}/>

    }
}


export default connect(mapStateToProps, {
    sendResumeTH,
    setOpenResumeInfoTH,
    editResumeTH,
    setSendResumeStatusAC
})(SendResumeBarContainer)