import { useEffect } from "react"
import { useState } from "react"
import s from "./Vacancies.module.css"


import { Button } from "../../../Components/Button/Button"

import { getDirections, getRegions, getSpecializations, createVacancyProbation, getAdminVP,
  editVacancyProbation,deleteVP } from "../../../DAL/ApiRequests"

import { Row, Select, TextArea, Input, CheckBox } from "../../../Components/EditInputKit/EditInputKit"
import { useNavigate } from "react-router-dom"




const templates = {
  "vacancy": {
    "name_vacancy": "",
    "description": "",
    "salary_start": 0,
    "salary_end": 0,
    "id_direction":0,
    "id_city": 0,
    "id_specialization": 0,
    "requirements": "",
    "responsibility": "",
    "conditions": "",
    "remote" : false
  },
  "probation": {
    "name_vacancy": "",
    "description": "",
    "program_for": "",
    "what_tasks_need_to_be_solved": "",
    "id_direction": 0,
    "id_city": 0,
    "id_specialization": 0,
    "learn_to": "",
    "result_of_your_internship": "",
    "remote": false
  }
}




const VacanciesAdmin = ({ vac = true }) => {
  const [data, setData] = useState({})

  const [cities, setCities] = useState([])
  const [directions, setDirections] = useState([])
  const [specializations, setSpecializations] = useState([])

  const [idVP,setIdVP] = useState(0)

  const [edit, setEdit] = useState(false)

  const navigate = useNavigate()


  const getCitiesLocal = async () => {
    var eventCities = []
    getRegions(0).then(regions => {
      for (var r of regions.data)
        for (var c of r["cities"])
          eventCities.push(c)

      setCities(eventCities)
    })
  }

  const getDirectionsLocal = async () => {
    getDirections().then(dirs => {
      setDirections(dirs.data)
    })
  }

  const getSpecializationsLocal = async () => {
    getSpecializations().then(spec => {
      setSpecializations(spec.data)
    })
  }

  const getVP = (id) => {
    getAdminVP(id).then(e => {
      if (e.status == "ok") {
        setData({...e.data.item})
        setEdit(true)
      }
    })
    
  }

  const addVacancyProbation = () => {
    var container = {}
    container[getType()] = data
    createVacancyProbation(container).then(r => {
      if (r.status == "ok") {
      
        alert("Успешно создано")
        setData(templates[getType()])
      }
      else {
        alert(r.message)
      }
    })
  }

  const saveChangesVacancyProbation = (id) => {
    var requestData = {}
    requestData[getType()] = {id:parseInt(id), ...data}
    editVacancyProbation(requestData).then(e =>{
      if(e.status == "ok"){
        alert("Успешно измененно")
      }else{
        alert(e.message)
      }
    })
  }

  const deleteVacancyProbation = () =>{
    deleteVP(data.id).then(r => {
      if(r.status == "ok"){
        
        var evData = data
        evData.is_delete = !evData.is_delete
        setData({...evData})
      }else{
        alert(r.message)
      }
    })
  }


  const getType = () => (vac ? "vacancy" : "probation")



  useEffect(() => {

    setData(templates[getType()])

    const url = new URL(window.location.href)
    const idVp = url.searchParams.get("id")
    if (idVp) {
      setIdVP(idVp)
      getVP(idVp)
    }


    getCitiesLocal()
    getDirectionsLocal()
    getSpecializationsLocal()




  }, [])


  return (
    <div className={s.column}>

      <Row title={'Имя'}>
        <Input setter={setData} val={data} k='name_vacancy' />
      </Row>

      <Row title={'Описание'}>
        <Input setter={setData} val={data} k='description' />
      </Row>

      <Row title={'Направление'}>
        <Select setter={setData} val={data} values={directions} k='id_direction' />
      </Row>

      <Row title={'Город'}>
        <Select setter={setData} val={data} values={cities} k='id_city' />
      </Row>

      <Row title={'Специализация'}>
        <Select setter={setData} val={data} values={specializations} k='id_specialization' />
      </Row>

      <Row title={'Удаленная'}>
        <CheckBox setter={setData} val={data} k='remote' />
      </Row>

      {vac ? (
        <>
          <Row title={'Начальная зарплата'}>
            <Input setter={setData} val={data} type="number" k='salary_start' />
          </Row>

          <Row title={'Конечная зарплата'}>
            <Input setter={setData} val={data} type="number" k='salary_end' />
          </Row>



          <Row title={'Требования к кандидату'}>
            <TextArea setter={setData} val={data} k='requirements' />
          </Row>

          <Row title={'Обязаности'}>
            <TextArea setter={setData} val={data} k='responsibility' />
          </Row>

          <Row title={'Условия'}>
            <TextArea setter={setData} val={data} k='condition' />
          </Row>
        </>
      ) : (
        <>

          <Row title={'Для кого эта программа'}>
            <TextArea setter={setData} val={data} k='program_for' />
          </Row>


          <Row title={'Какие задачи необходимо решать'}>
            <TextArea setter={setData} val={data} k='what_tasks_need_to_be_solved' />
          </Row>

          <Row title={'В процессе прохождения вы научитесь'}>
            <TextArea setter={setData} val={data} k='learn_to' />
          </Row>

          <Row title={'Кем вы сможете стать по итогам стажировки'}>
            <TextArea setter={setData} val={data} k='result_of_your_internship' />
          </Row>
        </>
      )}



      <div className={s.actionMenu}>
        {!edit ? (
          <Button variant={1}
            backColor={"var(--dimGreen)"}
            textColor={"white"}
            onClick={addVacancyProbation}
            btnText="Создать" />
        ) : (
          <>
            <Button variant={1}
              backColor={"var(--dimGreen)"}
              textColor={"white"}
              onClick= {()=>{
                saveChangesVacancyProbation(idVP)
              }}
              btnText="Сохранить" />
            {data.is_delete ? (
              <Button variant={1}
              backColor={"var(--violet)"}
              textColor={"white"}
              onClick =  {() => {
                deleteVacancyProbation()
              }}
              btnText="Разархивировать" />
            ): (
              <Button variant={1}
              backColor={"var(--violet)"}
              textColor={"white"}
              onClick =  {() => {
                deleteVacancyProbation()
              }}
              btnText="Архивировать" />
            )}
            
            <Button variant={1}
              backColor={"var(--smoke)"}
              textColor={"black"}
              onClick = {()=>{
                navigate(`/admin/requests?id=${idVP}`)
              }}
              btnText="Отклики" />
          </>
        )}



      </div>

    </div>
  )
}



export default VacanciesAdmin
