import {CreateResumeBar} from "./CreateResumeBar";
import React from "react";
import {connect} from "react-redux";
import {
    clearResumeInfoTH,
    editFullResumeTH,
    sendFullResumeTH,
    setOpenResumeInfoTH,
    setSendResumeStatusAC
} from "../../Redux/Reducers/AppReducer";
import {SendResumeBar} from "../SendResumeBar/SendResumeBar";

const mapStateToProps = (state) => {
    return {
        directions: state.app.directions,
        currenciesList: state.app.currenciesList,
        maritalStatusList: state.app.maritalStatusList,
        educationFormsList: state.app.educationFormsList,
        sendResumeError: state.app.errors.sendResumeError,

        userName: state.app.userName,
        userCity: state.app.userCity,
        userPhone: state.app.userPhone,
        resumeInfo: state.app.resumeInfo,
        authorized: state.app.authorized,
        userEmail: state.app.userEmail,
    }
}

class CreateResumeBarContainer extends React.Component {

    componentDidMount() {
        if (this.props.isEditMode) {
            this.props.setOpenResumeInfoTH(this.props.resumeId)
        }
    }

    componentWillUnmount() {
        this.props.setSendResumeStatusAC({status: "ok", message: ""})
    }


    onSend = (formData) => {
        if (!this.props.isEditMode) {
            return this.props.sendFullResumeTH(formData)
        } else {
            return this.props.editFullResumeTH(formData, this.props.resumeId)
        }
    }

    onClose = () => {
        this.props.clearResumeInfoTH()
        this.props.onClose()
    }


    render() {
        let enteredUserName;
        let enteredUserCity;
        let selectedDirections;
        let resumeLink;
        let enteredMartStatus;
        let enteredPosition;
        let enteredCitizenship;
        let enteredSecondCitizenship;
        let enteredWillingMission;
        let enteredWillRelocate;
        let enteredRelocatePlace;
        let enteredSalary;
        let enteredSalaryCurrency;
        let enteredPhone;

        let whatsSelected;
        let telegramSelected;
        let viberSelected;

        let enteredEmail;
        let enteredLanguages;
        let enteredDriveLicense;
        let enteredAboutMe;
        let enteredEducationList;
        let enteredExperienceList;


        if (this.props.isEditMode) {
            enteredUserName = `${this.props.resumeInfo.last_name} ${this.props.resumeInfo.first_name} ${this.props.resumeInfo.patronymic}`
            enteredUserCity = this.props.resumeInfo.city
            selectedDirections = this.props.resumeInfo.resume_directions
            resumeLink = this.props.resumeInfo.link_doc || this.props.resumeInfo.link_or_text
            enteredMartStatus = this.props.resumeInfo.family_status ? 2 : 1
            enteredPosition = this.props.resumeInfo.position
            enteredCitizenship = this.props.resumeInfo.citizenship
            enteredSecondCitizenship = this.props.resumeInfo.second_citizenship
            enteredWillingMission = this.props.resumeInfo.ready_for_trips
            enteredWillRelocate = this.props.resumeInfo.ready_to_move
            enteredRelocatePlace = this.props.resumeInfo.target_of_move
            enteredSalary = this.props.resumeInfo.desired_salary
            enteredSalaryCurrency = this.props.resumeInfo.currency
            enteredPhone = this.props.resumeInfo.phone
            enteredEmail = this.props.resumeInfo.email
            enteredLanguages = this.props.resumeInfo.languages
            enteredDriveLicense = this.props.resumeInfo.drive_license || undefined
            enteredAboutMe = this.props.resumeInfo.text_resume
            whatsSelected = this.props.resumeInfo?.social_network_on_phone?.find((i) => i === 1) > 0
            telegramSelected = this.props.resumeInfo?.social_network_on_phone?.find((i) => i === 2) > 0
            viberSelected = this.props.resumeInfo?.social_network_on_phone?.find((i) => i === 3) > 0
            enteredEducationList = this.props.resumeInfo.educations
            enteredExperienceList = this.props.resumeInfo.experience
        }
        return <CreateResumeBar

            currenciesList={this.props.currenciesList}
            maritalStatusList={this.props.maritalStatusList}
            educationFormsList={this.props.educationFormsList}
            isMobile={this.props.isMobile}
            directions={this.props.directions}
            sendFunk={this.onSend}
            sendResumeError={this.props.sendResumeError}
            authorized={this.props.authorized}
            isEditMode={this.props.isEditMode}
            onClose={this.onClose}
            tone={this.props.tone}
            userName={this.props.isEditMode ? enteredUserName : this.props.userName}
            userEmail={this.props.isEditMode ? enteredEmail : this.props.userEmail}
            userPhone={this.props.isEditMode ? enteredPhone : this.props.userPhone}
            userCity={this.props.isEditMode ? enteredUserCity : this.props.userCity}
            selectedDirections={this.props.isEditMode ? selectedDirections : undefined}
            resumeLink={resumeLink}
            enteredMartStatus={enteredMartStatus}
            enteredPosition={enteredPosition}
            enteredCitizenship={enteredCitizenship}
            enteredSecondCitizenship={enteredSecondCitizenship}
            enteredWillingMission={enteredWillingMission}
            enteredWillRelocate={enteredWillRelocate}
            enteredRelocatePlace={enteredRelocatePlace}
            enteredSalary={enteredSalary}
            enteredSalaryCurrency={enteredSalaryCurrency}
            enteredLanguages={enteredLanguages}
            enteredDriveLicense={enteredDriveLicense}
            enteredAboutMe={enteredAboutMe}
            whatsSelected={whatsSelected}
            telegramSelected={telegramSelected}
            viberSelected={viberSelected}
            enteredEducationList={enteredEducationList}
            enteredExperienceList={enteredExperienceList}


        />
    }
}

export default connect(mapStateToProps, {
    sendFullResumeTH,
    editFullResumeTH,
    setSendResumeStatusAC, setOpenResumeInfoTH, clearResumeInfoTH
})(CreateResumeBarContainer)
