import React from "react";
import s from "./Slide.module.css"

import {Heading} from "../Heading/Heading";
import {Button} from "../Button/Button";
import {NavLink} from "react-router-dom";

export const Slide = ({id, slideImg, slideHeadText, slideText, btnVariantNum, btnText,
                          buttonOnClick, slideVariantNum, slideHeight, isMobile, btnIsLink,
                          link, textWidth, headWidth, imgToCenter}) => {
    switch (slideVariantNum) {
        case 1: {
            return (
                <div id={id} style={{height: `${slideHeight}`}} className={s.slide}>
                    <div
                         className={s.slideContent}>
                        <Heading headWidth={headWidth} textWidth={textWidth} centerMode={isMobile}
                                 headText={slideHeadText} text={slideText}/>
                        {btnVariantNum && btnText ?
                            <div>{!btnIsLink ?
                                <Button onClick={buttonOnClick} variant={btnVariantNum} btnText={btnText}/> :
                                <NavLink to={link}><Button onClick={buttonOnClick} variant={btnVariantNum}
                                                           btnText={btnText}/></NavLink>}</div> : null}
                    </div>
                    <div
                        style={{
                            alignItems:imgToCenter ? "center": "end"
                        }}
                         className={s.slideImage}>
                        <img src={slideImg} alt=""/>
                    </div>
                </div>
            )
        }
    }
}