import { Button } from "../Button/Button"
import s from "./AdminTable.module.css"

import { useEffect } from "react"

const ITEMS_COUNTS = [10,20,50,100]


const AdminTable = ({rows,template,page,setPage,totalCount,count,setCount, isPagination=true}) =>{
    useEffect(() => {
    }, [rows])
    
    

    return (
        <div className={s.container}>
            <table>
                <div className={s.outerBody}>
                <thead>
                    <tr>
                        {template.map(t =>(
                            <td className={s.mb}>{t.title}</td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {
                        rows.map((r,i)=>{ return (
                            <tr style={{backgroundColor: i % 2 ? "white" : "gray"}}>
                                {template.map(t=>(
                                    <td>{t.renderCell ? (
                                        t.renderCell(r)
                                    ):(
                                        r[t.key]
                                    )}</td>
                                ))}
                            </tr>
                        )})
                    }
                <tr>
                 </tr>
                </tbody>
                </div>
                {isPagination ? (
                <div className={s.subTools}>
                        <select value={count}
                                onChange={(ev)=>{
                            setCount(parseInt(ev.target.value))
                        }}>
                            {ITEMS_COUNTS.map(i =>(
                                <option value={i}>{i}</option>
                            ))}
                        </select>
                        <p className={s.countText}>{page * count}/{totalCount}</p>
                        <Button variant={1}
                                backColor={"var(--dimGreen)"}
                                textColor={"white"}
                                disabled={page <= 1 }
                                btnText={"‹"}
                                onClick={()=>{
                                    setPage(page-1)
                                }}
                                />
                        <p className={s.countText}>{page}</p>
                        <Button variant={1}
                                backColor={"var(--dimGreen)"}
                                textColor={"white"}
                                disabled={((page+1) * count) > totalCount + count}
                                btnText={"›"}
                                onClick={()=>{
                                        setPage(page+1)
                                }}
                                />
                </div>
                ) : <></>}
                
            </table>
        </div>
    )
}

export default AdminTable