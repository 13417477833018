import s from "./AdminPageContainer.module.css"
import {Outlet} from "react-router";
import AdminNav from "../AdminNav/AdminNav";
import { useNavigate } from "react-router-dom";
import { getAdminInfo } from "../../DAL/ApiRequests";
import { useEffect, useState } from "react";
import Loader from "../Loader/Loader";





const AdminPageContainer = () =>{
    const navigate = useNavigate()
    const [isAuth,setIsAuth] = useState(false)
    useEffect(()=>{
            getAdminInfo().then(r =>{
                if(r.status == "error"){
                    if(r.code != 22)
                        navigate("/admin_log")
                }else{
                    setIsAuth(true)
                }  
            })
        
    },[])
    return(
        <div className={s.container}>
            {isAuth ? (
                <>
                    <AdminNav/>
                    <Outlet/>
                </>
            ): <Loader isLoad={true}/>}
            
        </div>
    )
}

export default AdminPageContainer