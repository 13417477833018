import ProbationInfoItem from "../ProbationInfoItem/ProbationInfoItem"

import mind from '../../Assets/pageImages/VaacancyPageImages/Mind.svg'
import paper from '../../Assets/pageImages/VaacancyPageImages/Paper.svg'
import stars from '../../Assets/pageImages/VaacancyPageImages/Stars.svg'
import desc from '../../Assets/pageImages/VaacancyPageImages/Description.png'


import s from './VacancyInfoItems.module.css'


const VacancyInfoItems = ({description,requariments,duties,conditions}) =>{
    return (
        <div className={s.container}>
            {description && description.trim() !== "" && (
            <ProbationInfoItem title = {"Описание"}
                                   image = {desc}
                                   content={description}/>
            )}
            <ProbationInfoItem title = {"Требования к кандидату"}
                               image = {stars}
                               content={requariments}/>
            <ProbationInfoItem title = {"Обязанности"}
                               image = {mind}
                               content={duties}/>
            <ProbationInfoItem title = {"Условия"}
                               image = {paper}
                               content={conditions}/>
        </div>
    )
}

export default VacancyInfoItems