
import s from "./Loader.module.css"


const Loader = ({isLoad}) =>{
    return (
        <div className={s.container} style={{
            display: isLoad ? null : "none",
        }}>
            <div className={s.circlesLoader}>
                <span></span>
                <span></span>
                <span></span>
        </div>
        </div>)
}


export default Loader