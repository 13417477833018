import * as React from "react";
import {connect} from "react-redux";
import {useLocation, useParams} from "react-router-dom";
import {useEffect} from "react";
import {VacancyInfo} from "./VacancyInfo";
import {addToFeaturedTH, setDirectionsTH, setVacancyProbationTH} from "../../Redux/Reducers/AppReducer";


const mapStateToProps = (state) => {
    return {
        vacancyInfo: state.app.vacancyInfo,
        userSubscribe: state.app.userSubscribe,
        authorized: state.app.authorized

    }
}

const VacanciesInfoContainer = (props) => {
    let params = useParams();
    let shareLink = window.location.origin + useLocation().pathname
    useEffect(() => {
        props.setVacancyProbationTH(params.vacProbId, props.contentType)
        props.setDirectionsTH()
    }, [])
    return (
        <VacancyInfo authorized = {props.authorized} shareLink = {shareLink} vacancyId = {params.vacProbId} onAddToFeatured = {props.addToFeaturedTH} userSubscribe = {props.userSubscribe} vacancyInfo = {props.vacancyInfo} isMobile={props.isMobile}/>
    )
}

export default connect(mapStateToProps, {setVacancyProbationTH, addToFeaturedTH, setDirectionsTH})(VacanciesInfoContainer)


