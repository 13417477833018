import React, {useEffect} from "react";
import s from "./Button.module.css"

export const Button = ({btnText, onClick, variant, margin, disabled, backColor, textColor, width, padding, formWrapId, btnId, disFunk}) => {

    useEffect(() => {
        if (formWrapId && btnId) {
            let form = document.getElementById(formWrapId)
            let btn = document.getElementById(btnId)
            form.addEventListener("keyup", (e) => {
                if (e.key === "Enter") {
                    btn.click()
                }
            })
        }
    }, [])

    let btnClassVariant;

    switch (variant) {
        case 1:  btnClassVariant = `${s.variantOne}`
            break;
        case 2: btnClassVariant = `${s.variantTwo}`
            break;
        case 3: btnClassVariant = `${s.variantThree}`
            break;
        case 4: btnClassVariant = `${s.variantFour}`
            break;
        case 5: btnClassVariant = `${s.variantFive}`
            break;
        case 6: btnClassVariant = `${s.variantSix}`
            break;
        default: btnClassVariant = `${s.variantOne}`
    }


    return (
        <div style={{margin: `${margin}`}} className={s.wrapper}>
            <button id={btnId} style={{
                padding: `${padding}`,
                color: `${textColor}`,
                backgroundColor: `${backColor}`,
                width: `${width}`
            }} disabled={disabled} className={btnClassVariant} onClick={disFunk || onClick}>{btnText}</button>
        </div>
    )
}
