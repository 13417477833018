import { useEffect } from "react"
import { useState } from "react"
import VerticalArrayChanger from "../../../Components/VerticalArrayChanger/VerticalArrayChanger"

import s from "./CitiesPage.module.css"

import { getRegions, editCityRegion } from "../../../DAL/ApiRequests"

const CitiesPage = () =>{


    const [regions,setRegions] = useState([])

    const [cities,setCities] = useState([])

    const [realRegions,setRealRegions] = useState([])

    const [selectedRegionId,setSelectedRegionId] = useState(0)





    const getRegionsList = () =>{
      getRegions(0).then(r =>{
        setRegions([...r.data])

        if(selectedRegionId > 0){
            setCities([...r.data.find(el => (el.id == selectedRegionId)).cities])
       }else{
           if(r.data.length > 0){
                setSelectedRegionId(r.data[0].id)
           }   
       }
    })
    }

    const onEditRegion = (r) =>{
        if(r.status == "ok")
            getRegionsList()
        else
            alert(r.message)
    }


    const createRegion = (value) =>{
        editCityRegion({name_region:value,add:1}).then(onEditRegion)
    }

    const createCity = (value) =>{
        editCityRegion({name_city:value,id_region:selectedRegionId,add:1}).then(onEditRegion)
    }

    const editCity = (id,value) =>{
        editCityRegion({name_city:value,id_city:id,add:0}).then(onEditRegion)
    }

    const editRegion = (id,value) =>{
        editCityRegion({name_region:value,id_region:id,add:1}).then(onEditRegion)
    }


    useEffect(()=>{
        getRegionsList()
    },[])

    // useEffect(()=>{
    //     var eventRegions = []
    //     for(var r of regions){
    //         console.log(r)
    //         if(r)
    //             eventRegions.push({id:r.region_id,name:r.region_name})
    //     }
    //     setRealRegions([...eventRegions])
    // },[regions])

    useEffect(()=>{
    if(selectedRegionId > 0){
        setCities(regions.find(el => (el.id == selectedRegionId)).cities.map(el=>(
            el
        )))

    }
    },[selectedRegionId])

    return (
        <div className={s.container}>
            <VerticalArrayChanger data = {regions.map(r => ({id:r.id,name:r.name}))}
                                select = {(id)=>{setSelectedRegionId(id)}}
                                create = {createRegion}
                                edit = {editRegion}
                                
                                />
            <div>
                <p>Выбранный регион</p>
                {regions.find(el => (el.id == selectedRegionId)) ? regions.find(el => (el.id == selectedRegionId)).name : null}
                
            </div>
            <VerticalArrayChanger data = {cities}
                                  create = {createCity}
                                  edit = {editCity}/>
        </div>
        
    )
}

export default CitiesPage