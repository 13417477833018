import {createTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from "date-fns/locale/ru"

import {KeyboardDatePicker, MuiPickersUtilsProvider, DateTimePicker, DatePicker} from "@material-ui/pickers";
import React from "react";



const defaultMaterialTheme = createTheme({
    palette: {
        primary: {
            main: "#00B856",
            contrastText:"#ffffff"
        },
        secondary: {
            main: "#00B856",
        },
    },
    zIndex:{
        modal:99999999999
    }
});


function CustomDatePicker({value, onChange, label}) {
    return (
        <ThemeProvider theme={defaultMaterialTheme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                <DatePicker
                    autoOk
                    variant="inline"
                    label={label}
                    format="dd/MM/yyyy"
                    value={value}
                    inputadornmentprops={{ position: "start" }}
                    onChange={date => onChange(date)}
                    emptyLabel={"Заполните поле"}
                    invalidDateMessage={"Неверный формат даты"}
                    disableFuture
                    openTo="year"
                    views={["year", "month", "date"]}
                    // zIndex

                />
            </MuiPickersUtilsProvider>
        </ThemeProvider>);
}


export function CustomDateTimePicker({value, onChange, label}) {
    return (
        <ThemeProvider theme={defaultMaterialTheme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                <DateTimePicker
                    disableToolbar
                    variant="inline"
                    label={label}
                    emptyLabel={"Заполните поле"}
                    invalidDateMessage={"Неверный формат даты"}
                    maxDateMessage={"Слишком большое значение"}
                    minDate={"Слишком маленькое значение"}

                    format="dd.MM.yyyy HH:mm"
                    ampm={false}

                    value={value}
                    onChange={onChange}
                />
            </MuiPickersUtilsProvider>
        </ThemeProvider>);
}

export default CustomDatePicker;