import React, {useState} from "react";
import s from "./Footer.module.css"
import logo from "../../Assets/Logo/logo.png"
import instagramIcon from "../../Assets/Icons/instagramIcon.svg"
import facebookIcon from "../../Assets/Icons/facebookIcon.svg"
import vkIcon from "../../Assets/Icons/VK.svg"
import linkedInIcon from "../../Assets/Icons/LI.svg"
import tikIcon from "../../Assets/Icons/tikIcon.svg"
import telegramIcon from "../../Assets/Icons/telegramIcon.svg"
import okIcon from "../../Assets/Icons/okIcon.svg"
import {Button} from "../Button/Button";
import {NavLink} from "react-router-dom";
import SendResumeBarContainer from "../SendResumeBar/SendResumeBarContainer";
import CreateResumeBarContainer from "../CreateResumeBar/CreateResumeBarContainer";

export const Footer = ({isMobile, authorized}) => {
    const onCloseSendResumeModal = (status) => {
        setSendResumeModalIsOpen(false)
    }
    const [sendResumeModalIsOpen, setSendResumeModalIsOpen] = useState(false)
    return (
        <div className={s.footer}>
            <div className={s.topPart}>
                <div className={s.menu}>
                    <div className={s.leftPart}>
                        <div className={s.logo}><NavLink to="/"><img src={logo} alt=""/></NavLink></div>
                    </div>
                    {!isMobile ? <div className={s.rightPart}>
                        <div className={s.links}>
                            <NavLink className={({isActive}) => isActive ? s.activeLink : ""} to="/">Главная</NavLink>
                            <NavLink className={({isActive}) => isActive ? s.activeLink : ""}
                                     to="/vakansii">Вакансии</NavLink>
                            <NavLink className={({isActive}) => isActive ? s.activeLink : ""}
                                     to="/stazhirovki">Стажировка</NavLink>
                            <NavLink className={({isActive}) => isActive ? s.activeLink : ""} to="/o-kompanii">О
                                компании</NavLink>
                        </div>
                    </div> : null}
                </div>
                {isMobile && authorized ?
                    <div className={s.createResumeBtn}><Button onClick={() => setSendResumeModalIsOpen(true)}
                                                               variant={4} btnText="Создай свое резюме!"/></div> : null}
                <div className={s.content}>
                    <div className={s.info}>
                        <p><span style={isMobile ? {marginBottom: "20px"} : null}>ЗАО "ТТ мобайл" {isMobile ?
                            <br/> : null}</span><br/>
                            <span style={isMobile ? {marginBottom: "20px"} : null}> Главный офис: г. Душанбе ул. Хувайдуллоева 73/2 {isMobile ?
                                <br/> : null} <br/>Академия МегаФон: ул. Бухоро 32 {isMobile ? <br/> : null} 
                                <br/></span>

                            <span style={isMobile ? {marginBottom: "20px"} : null}>+992907700500 <br/></span>
                            info@megafon.tj
                        </p>
                    </div>
                    <div className={s.mediaLinks}>
                        {!isMobile && authorized ?
                            <div className={s.createResumeBtn}><Button onClick={() => setSendResumeModalIsOpen(true)}
                                                                       variant={4} btnText="Создай свое резюме!"/>
                            </div> : null}
                        <div className={s.socLinks}>
                            <a target="_blank" className={s.instIcon}
                               href="https://instagram.com/megafontj/"><img src={instagramIcon}
                                                                            alt=""/></a>
                            <a target="_blank" className={s.instIcon} href="https://www.facebook.com/megafontj"><img
                                src={facebookIcon} alt=""/></a>
                            <a target="_blank" className={s.instIcon} href="https://www.linkedin.com/company/10595528">
                                <img src={linkedInIcon} width="40px" height="40px" alt=""/>
                            </a>
                            <a target="_blank" className={s.instIcon}
                               href="https://www.tiktok.com/@megafontajikistan?_d=secCgwIARCbDRjEFSADKAESPgo8OySMV%2FMD8H1WwS9dg%2F075qN58WvatFj9tl1cIkbcSlpz3uojXPS%2FxxZNgQYYkywz5RDeMJJiIV5mZ9lqGgA%3D&checksum=0f4b2775b30bbd7fe275dbb1c04d8efe7dd87e892f4c90114e4e1326c0eae92e&language=ru&sec_uid=MS4wLjABAAAAZ1wlPv-Nx_OFFWx1KiCZcu8kNgeDyuKFykOhUoHVqukRHbjsurPD3fvc0eqtC_vV&sec_user_id=MS4wLjABAAAAsQvDgNvG1QYKabd6_PlTeugEPMIaxjSwU2gBYDgYTYAsXPwWwT0ypSEUBgMSL0gS&share_app_id=1233&share_author_id=7021777441774863362&share_link_id=64235A41-5AD9-43DE-8FA4-6BFBBC4771CC&tt_from=copy&u_code=dfgh1kf669a28e&user_id=6897144697845859334&utm_campaign=client_share&utm_medium=ios&utm_source=copy&source=h5_m&_r=1">
                                <img src={tikIcon} alt=""/></a>
                            <a target="_blank" className={s.instIcon} href="https://vk.com/megafontj">
                                <img src={vkIcon} width="50px" height="50px"
                                     style={{marginBottom: "-10px"}} alt=""/>
                            </a>
                            <a target="_blank" className={s.instIcon} href="https://t.me/megafon_taj">
                                <img src={telegramIcon} width="40px" height="40px" alt=""/>
                            </a>
                            <a target="_blank" className={s.instIcon} href="https://ok.ru/megafontj">
                                <img src={okIcon} width="40px" height="40px" alt=""/>
                            </a>
                        </div>

                    </div>
                </div>
            </div>
            <hr color="#AEAEAE" size={1}/>
            <div className={s.bottomPart}>
                <div><a className={s.confLink} href="/politika_konfidencialnosti">Политика конфиденциальности и положение о персональных данных</a></div>
                <div className={s.cooperate}>
                    <p>Developed by <a href="#">Cleverra</a></p>
                </div>
            </div>
            {sendResumeModalIsOpen ?
                <CreateResumeBarContainer isEditMode={false} isModal={true} isMobile={isMobile}
                onClose={onCloseSendResumeModal}/> : null}
        </div>
    )
}