import s from "./TestSingleAnswer.module.css";

import { useEffect, useState } from "react";

const TestTextAnswer = ({  onChange }) => {
  const [selectedAnswers, setSelectedAnswers] = useState([""]);

  useEffect(() => {
    onChange(selectedAnswers)
  }, [selectedAnswers]);
  return (
    <div className={s.textBox} >
      <textarea
        value={selectedAnswers[0]}
        onChange={(e)=>{
            setSelectedAnswers([e.target.value])
        }}
        style={{marginTop:"20px"}}
        placeholder="Ваш ответ"
      />
    </div>
  );
};

export default TestTextAnswer;
