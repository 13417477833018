import axios from "axios";
import {ToastsStore} from "react-toasts";

axios.defaults.withCredentials = true

export const serverLink = "https://api.jobmf.tj"

const endPoints = {
    profile: "/profile",
    auth: "/auth",
    register: "/register",
    resume: "/resume",
    editPassword: "/update_user_password",
    application: "/add_response_probation_vacancy",
    getVacancies: "/list_vacancy_probation",
    verifyEmail: "/verify_email",
    resendCode: "/resend_code",
    exit: "/exit",
    directions: "/directions",
    editDirections: "/admin/edit_directions",
    specializations: "/specialization",
    editSpecializations: "/admin/edit_specializations",
    subscribeSender: "/subscribe_sender",
    regions: "/region",
    createVacancyProbation: "/admin/add_vp",
    editVacancy: "/admin/edit_vp",
    vacancyProbation: "/get_vacancy_probation",
    adminLogin: "/admin/auth",
    adminInfo: "/admin/info",
    recoveryPassword: "/recovery_password",
    addToFeatured: "/add_featured_vacancy",
    getRequests:"/admin/get_all_request_on_vp",
    adminGetVP:"/admin/get_vp",
    deleteVP:"/admin/delete_vp",
    editCityRegion:"/admin/edit_cities_regions",
    subscribedEmails:"/admin/subscribe_emails",
    createSender:"/add_mail_schedule",
    getSenders:"/get_all_tasks",
    deleteSender:"/delete_one_task",
    getAdminVacancies:"/admin/list_vp",
    getUers:"/admin/all_users",
    getResumes:"/admin/get_resume_on_direction",
    changeRequestStatus:"/read_request",
    exitAdmin:"/admin/exit",
    fullResumeAdmin:"/admin/get_full_resume",

    getTest:"/user_get_test",
    answerTest:"/user_test_save"

};

const examinationResponse = (response) => {
    switch (response.data.status) {
        case "ok": {
            return true
        }
        case "error": {
            console.error(response.data.data.message)
            return false
        }
    }
}
const goRequest = async (method, endPoint, requestData, errorFunc) => {
    switch (method) {
        case "get": {
            let reqParams = "";
            let keys;
            if (requestData) {
                keys = Object.keys(requestData || undefined)
            }
            if (keys) {
                for (const key in requestData) {
                    reqParams += `${key}=`
                    if (key === keys[keys.length - 1]) {
                        reqParams += requestData[key]
                    } else {
                        reqParams += `${requestData[key]}&`
                    }
                }
            }
            return axios.get(`${serverLink}${endPoint}${!reqParams ? "" : `?${reqParams}`}`,)
                .then((response) => {
                    const isFine = examinationResponse(response)
                    if (isFine) return response.data
                    else {
                        if (endPoint !== endPoints.profile) {
                            ToastsStore.error(response.data.data.message)
                        }
                        if (errorFunc) {
                            errorFunc(response)
                        }
                        return {
                            status: response.data.status,
                            message: response.data.data.message,
                            code: response.data.data.code
                        }
                    }
                })
            break;
        }
        case "post": {
            return axios.post(`${serverLink}${endPoint}`, requestData, {
                withCredentials: true,
                headers: {
                    // "Access-Control-Allow-Origin": "http://localhost:3000"
                }
            })
                .then((response) => {
                    const isFine = examinationResponse(response)
                    if (isFine) {
                        return response.data
                    } else {
                        ToastsStore.error(response.data.data.message)
                        if (errorFunc) {
                            errorFunc(response)
                        }
                        return {
                            status: response.data.status,
                            message: response.data.data.message,
                            code: response.data.data.code
                        }
                    }
                })
            break;
        }
        case "put": {
            return axios.put(`${serverLink}${endPoint}`, requestData)
                .then((response) => {
                    const isFine = examinationResponse(response)
                    if (isFine) return response.data
                    else {
                        ToastsStore.error(response.data.data.message)
                        if (errorFunc) {
                            errorFunc(response)
                        }
                        return {
                            status: response.data.status,
                            message: response.data.data.message,
                            code: response.data.data.code
                        }
                    }
                })
            break;
        }
        case "delete": {

            return axios.delete(`${serverLink}${endPoint}`, {data: requestData})
                .then((response) => {
                    const isFine = examinationResponse(response)
                    if (isFine) return response.data
                    else {
                        ToastsStore.error(response.data.data.message)
                        if (errorFunc) {
                            errorFunc(response)
                        }
                        return {
                            status: response.data.status,
                            message: response.data.data.message,
                            code: response.data.data.code
                        }
                    }
                })
            break;
        }
        default:
            console.error("Неверный тип запроса")
            break;
    }
}

export const getProfileInfo = async () => {
    return await goRequest("get", endPoints.profile)

}

export const sendFullResume = async (formData) => {
    const data = new FormData()
    data.append("model", JSON.stringify(formData.mainData))
    if (formData.file) {
        data.append("file", formData.file)
    }
    return await goRequest("post", endPoints.resume, data)
}

export const sendResume = async (userName, userCity, resume, directions) => {
    const anData = typeof resume === "string" ? {
        first_name: userName.split(" ")[1],
        last_name: userName.split(" ")[0],
        patronymic: userName.split(" ")[2],
        city: userCity,
        directions: directions,
        link_or_text: resume
    } : {
        first_name: userName.split(" ")[1],
        last_name: userName.split(" ")[0],
        patronymic: userName.split(" ")[2],
        city: userCity,
        directions: directions,
    }

    const data = new FormData()
    if (typeof resume !== "string") {
        data.append("file", resume)
    }
    data.append("model", JSON.stringify(anData))
    return await goRequest("post", endPoints.resume, data)
}
export const editResume = async (userName, userCity, resume, directions, resumeId) => {
    const anData = typeof resume === "string" ? {
        first_name: userName.split(" ")[1],
        last_name: userName.split(" ")[0],
        patronymic: userName.split(" ")[2],
        city: userCity,
        directions: directions,
        link_or_text: resume,
        id_resume: resumeId

    } : {
        first_name: userName.split(" ")[1],
        last_name: userName.split(" ")[0],
        patronymic: userName.split(" ")[2],
        city: userCity,
        directions: directions,
        id_resume: resumeId
    }

    const data = new FormData()
    if (typeof resume !== "string") {
        data.append("file", resume)
    }
    data.append("model", JSON.stringify(anData))
    return await goRequest("put", endPoints.resume, data)
}

export const editFullResume = async (formData, resumeId) => {
    const data = new FormData()
    let withIdFormData = JSON.parse(JSON.stringify(formData));
    withIdFormData.mainData.id_resume = resumeId

    data.append("model", JSON.stringify(withIdFormData.mainData))

    if (formData.file) {
        data.append("file", formData.file)
    }
    return await goRequest("put", endPoints.resume, data)
}

export const editPassword = async (newPassword, newPassRepeat) => {
    return await goRequest("put", endPoints.editPassword, {password: newPassword, retry_password: newPassRepeat})

}

export const authorization = async (login, password) => {
    return await goRequest("post", endPoints.auth, {login: login, password: password})
}

export const registration = async (name, firstName, patronymic, email, phone, genderId, birthDate, password, subcription, consent) => {
    return await goRequest("post", endPoints.register, {
        last_name: name,
        first_name: firstName,
        patronymic: patronymic,
        email: email,
        phone: phone,
        gender: genderId,
        birthday: birthDate,
        password: password,
        subcription: subcription,
        is_confirmed: consent,
    })
}

export const sendApplication = async (email, phone, name, lastName, patronymic, resume, startDate, vacancyId) => {

    const anData = typeof (resume) === "number" ? {
        email: email,
        phone: phone,
        first_name: name,
        last_name: lastName,
        patronymic: patronymic || "",
        id_vacancy_probation: +vacancyId,
        id_resume: resume,
        when_ready_to_start: startDate
    } : {
        email: email,
        phone: phone,
        first_name: name,
        last_name: lastName,
        patronymic: patronymic || "",
        id_vacancy_probation: +vacancyId,
        when_ready_to_start: startDate
    }
    const data = new FormData()

    if (resume && typeof (resume) !== "number") {
        data.append("file", resume)
    }
    data.append("model", JSON.stringify(anData))

    return await goRequest("post", endPoints.application, data)
}

export const editProfile = async (name, photo, email, phone, gender, birthDate, directions) => {
    const anData = {
        first_name: name.split(" ")[1],
        last_name: name.split(" ")[0],
        patronymic: name.split(" ")[2],
        email: email,
        phone: phone,
        gender: gender,
        birthday: birthDate,
        directions: directions.length > 0 ? directions : [0]
    }
    const data = new FormData()
    if (photo && typeof photo !== "string") {
        data.append("photo", photo)
    }
    data.append("model", JSON.stringify(anData))

    return await goRequest("put", endPoints.profile, data)
}

export const getVacancies = async (page, count, region, city, directions, specializations, searchText, flag, isArhive) => {
    if (!isArhive)
        return await goRequest("post", endPoints.getVacancies, {
            page: page,
            limit: count,
            region: region,
            city: city,
            directions: directions.length === 0 ? [0] : directions,
            specializations: specializations.length === 0 ? [0] : specializations,
            flag: flag,
            name_or_description: searchText,
            desc: true,
        })
    else
        return await goRequest("post", endPoints.getAdminVacancies, {
            page: page,
            limit: count,
            region: region,
            city: city,
            directions: directions.length === 0 ? [0] : directions,
            specializations: specializations.length === 0 ? [0] : specializations,
            flag: flag,
            name_or_description: searchText,
            is_delete: isArhive
        })
}

export const confirmEmail = async (code) => {
    return await goRequest("post", endPoints.verifyEmail, {code: code})
}

export const resendCode = async (email) => {
    return await goRequest("post", endPoints.resendCode, {email: email})
}

export const getDirections = async (usingPlaceFlag) => {
    return await goRequest("get", endPoints.directions, usingPlaceFlag !== undefined ? {"flag": usingPlaceFlag} : null)
}
export const getSpecializations = async (usingPlaceFlag) => {
    return await goRequest("get", endPoints.specializations, usingPlaceFlag !== undefined ? {"flag": usingPlaceFlag} : null)
}

export const subscribeSender = async (email, subscribeFlag) => {
    let reqData = subscribeFlag === false ? {email: email, set_subscribe: false} : {email: email}
    return await goRequest("post", endPoints.subscribeSender, reqData)
}

export const getRegions = async (regionId) => {
    return await goRequest("get", endPoints.regions, {"id_region": regionId})
}

export const exit = async () => {
    return await goRequest("get", endPoints.exit)
}

export const createVacancyProbation = async (data) => {
    return await goRequest("post", endPoints.createVacancyProbation, data)
}

export const editVacancyProbation = async (data) => {
    return await goRequest("put", endPoints.editVacancy, data)
}

export const authAdmin = async (login, password) => {
    return await goRequest("post", endPoints.adminLogin, {login, password})
}

export const getAdminInfo = async () => {
    return await goRequest("get", endPoints.adminInfo)
}

export const getRequests = async (id, page, count, onluUnreaded) => {
    return await goRequest("get", endPoints.getRequests,
        {
            id_vp: id,
            page: page,
            limit: count,
            not_read: onluUnreaded
        })
}

export const getAdminVP = async (id) => {
    return await goRequest("get", endPoints.adminGetVP, {id_vp: id})
}

export const deleteVP = async (id) => {

    return await goRequest("delete", `${endPoints.deleteVP}?id_vp=${id}`)
}

export const editDirections = async (id, text, add) => {
    return await goRequest("put", `${endPoints.editDirections}?id_direction=${id}&name=${text}&add=${add}`)
}

export const editSpecializations = async (id, text, add) => {
    return await goRequest("put", `${endPoints.editSpecializations}?id_spec=${id}&name=${text}&add=${add}`)
}

export const editCityRegion = async (data) => {
    return await goRequest("put", endPoints.editCityRegion, data)
}

export const subscribesEmails = async () => {
    return await goRequest("get", endPoints.subscribedEmails)
}

export const createSender = async (data) => {
    return await goRequest("post", endPoints.createSender, data)
}

export const getSenders = async () => {
    return await goRequest("get", endPoints.getSenders)
}

export const deleteSender = async (id) => {
    return await goRequest("delete", `${endPoints.deleteSender}?id_job=${id}`)
}

export const getUsers = async (page, limit) => {
    return await goRequest("get", endPoints.getUers, {page, limit})
}

export const getResumes = async (id) => {
    return await goRequest("get", endPoints.getResumes, {id_direction: id})
}

export const readRequest = async (id, status) => {
    return await goRequest("post", endPoints.changeRequestStatus, {id_request: id, read: status})
}

export const exitAdmin = async () => {
    return await goRequest("get", endPoints.exitAdmin)
}

//====================================T+ma

export const getVacancyProbation = async (id, flag) => {
    return await goRequest("get", endPoints.vacancyProbation, {"id_vacancy_probation": id, "flag": flag})
}

export const addToFeatured = async (idVacancy) => {
    return await goRequest("post", endPoints.addToFeatured, {id_vacancy: idVacancy})
}

export const getResumeInfo = async (idResume) => {
    return await goRequest("get", endPoints.resume, {
        "id_resume": idResume
    })
}

export const recoveryPassword = async (email, code) => {
    return await goRequest("post", endPoints.recoveryPassword, {
        email: email || null,
        code: code || null
    })
}

export const getFullResume = async (id) => {
    return await goRequest("get",endPoints.fullResumeAdmin,{
        id_resume: id
    })
}


export const getTest = async (id) => {
    return await goRequest("post", endPoints.getTest, {
        id_test:id
    })
}

export const answerOnTest = async (id_test,answers) => {
    return await goRequest("post", endPoints.answerTest, {
        id_test:id_test,
        answers:answers
    })
}
