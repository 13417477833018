import React from "react";
import s from "./ChoiceItem.module.css"
import {NavLink} from "react-router-dom";

export const ChoiceItem = ({id, isLink, name, isActive, setActiveItem}) => {
    let linkPath = `/vakansii/${id}`
    return (
        <div>
            {isLink ? <NavLink className={`${s.path} ${s.link}`} to={linkPath}>
                    <div onClick={() => setActiveItem(id)} className={`${s.wrapper} ${isActive ? s.active : null}`}>
                        <p className={s.path}>{name}</p>
                    </div>
                </NavLink> :
                <div onClick={() => setActiveItem(id)} className={`${s.wrapper} ${isActive ? s.active : null}`}>
                    <p className={s.path}>{name}</p>
                </div>}
        </div>
    )
}