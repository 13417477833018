import * as React from "react";
import {connect} from "react-redux";
import {Filter} from "./Filter";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useLocation, useParams} from "react-router";

const mapStateToProps = (state) => {
    return {
        directions: state.app.directions,
        specializations: state.app.specializations,
        totalVacanciesCount: state.app.totalVacanciesCount

    }
}

class FilterContainer extends React.Component {
    render() {
        return (
            <GetDirectionContainer totalVacanciesCount={this.props.totalVacanciesCount} onSelect={this.props.onSelect}
                                   directions={this.props.directions} specializations={this.props.specializations}/>
        )
    }
}

export default connect(mapStateToProps, {})(FilterContainer)


const GetDirectionContainer = ({onSelect, totalVacanciesCount, directions, specializations}) => {
    const filteredDirections = directions.filter(direction => direction.count > 0);
    const filteredSpecializations = specializations.filter(specialization => specialization.count > 0);

    let filterParams = {
        totalCount: totalVacanciesCount,
        params: [
            {title: "Направления", path: filteredDirections},
            {title: "Специализация", path: filteredSpecializations}
        ]
    }
    let params = useParams();
    let directionId = +params.directionId

    return <Filter directionId={directionId} filterParams={filterParams} onSelect={onSelect}/>
}
