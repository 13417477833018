import s from "./TestSingleAnswer.module.css";

import { useEffect, useState } from "react";

const TestSingleAnswer = ({ answers, onChange }) => {
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  useEffect(() => {
    onChange(selectedAnswers);
  }, [selectedAnswers]);
  return (
    <div className="">
      {answers.map((a, i) => (
        <div
          className={s.row}
          key={`single-${i}`}
          onClick={() => {
            setSelectedAnswers([a.id]);
          }}
        >
          <p
            className={`${s.box} ${
              selectedAnswers.includes(a.id) ? s.selected : ""
            }`}
          ></p>
          <p>{a.text}</p>
        </div>
      ))}
    </div>
  );
};

export default TestSingleAnswer;
