import React, {useEffect, useState} from "react";
import s from "./CheckBox.module.css"
import checkedItem from "../../Assets/Icons/checkedItem.svg"
import dontCheckedItem from "../../Assets/Icons/dontCheckedItem.svg"

export const CheckBox = ({id, bool, onSetChecked, isManyChoice, selectItemId, iconHeight, part, selectedAll, margin, isStartChecked, text, flexValue, marginToBox}) => {
    const [checked, setChecked] = useState(bool || isStartChecked)

    useEffect(() => {
            setChecked(isStartChecked)
    }, [isStartChecked])

    const onChangeChecked = () => {
        setChecked(!checked)
        if (bool === true || bool === false) {
            onSetChecked(!bool)
        } else {
            if (!checked) {
                if (part) {
                    onSetChecked(id, part)
                } else {
                    onSetChecked(id)
                }
            } else {
                if (part) {
                    onSetChecked(id, part)
                } else {
                    onSetChecked(undefined)
                }
            }

        }
    }

    useEffect(() => {
        if (!isStartChecked) {
            setChecked(bool)
        }
    }, [bool])

    useEffect(() => {
        if (id !== selectItemId && !isManyChoice) {
            setChecked(false)
        }
    }, [selectItemId])

    useEffect(() => {
        if (selectedAll) {
            setChecked(false)
        }
    }, [selectedAll])
    return (
        <p style={{flex: flexValue, margin:marginToBox}} onClick={onChangeChecked} className={s.checkRow}><img style={{height: `${iconHeight}`, margin: `${margin}`}} className={s.checkImg}
                src={checked === true ? checkedItem : dontCheckedItem}
                alt="checkBox"/> <span className={s.text}>{text}</span></p>
    )
}
