import React, {useEffect, useState} from "react";
import s from "./UserProfileInfoCard.module.css"
import EM from "../../Assets/Icons/email-outline.png"
import PH from "../../Assets/Icons/phone-outline.png"
import PS from "../../Assets/Icons/person-outline.svg"
import CD from "../../Assets/Icons/calendar-outline.svg"
import EMG from "../../Assets/Icons/emailGreen.svg"
import ST from "../../Assets/Icons/star.svg"
import {ChoiceBar} from "../ChoiceBar/ChoiceBar";
import {Button} from "../Button/Button";
import {Input} from "../Input/Input";
import {DropList} from "../DropList/DropList";
import "react-datepicker/dist/react-datepicker.css";
import ChangePasswordBarContainer from "../ChangePasswordBar/ChangePasswordBarContainer";
import defaultImg from "../../Assets/DefaultImg/non_avatar.png"
import CustomDatePicker from "../DatePicker/DatePicker";
import {useNavigate} from "react-router-dom";


export const UserProfileInfoCard = ({
                                        userName, userPhoto, userEmail, userPhone, userBirthDate, userGender,
                                        userSubscribe, userPriorityDirections, directions, onEdit, isMobile,
                                        editProfileError, onSubscribe, setProfileInfo
                                    }) => {
    const [editMode, toggleEditMode] = useState(false)

    const [newName, setNewName] = useState(userName)
    const [newPhoto, setNewPhoto] = useState(userPhoto)
    const [newEmail, setNewEmail] = useState(userEmail)
    const [newPhone, setNewPhone] = useState(userPhone)
    const [newGender, setNewGender] = useState(userGender === "Мужской" ? 1 : 2)
    const [startDate, setStartDate] = useState(new Date(userBirthDate));
    const [newPriorityDirections, setNewPriorityDirections] = useState(userPriorityDirections)
    const [userIsSubscribe, setUserIsSubscribe] = useState(false)

    const [newPhotoPreview, setNewPhotoPreview] = useState(undefined)

    const [changePassModalIsOpen, setChangePassModalIsOpen] = useState(false)

    const [activeDroppedList, setActiveDroppedList] = useState(0)

    let navigate = useNavigate()

    useEffect(() => {
        setProfileInfo()
            .then((status) => {
                if (status === "error") {
                    navigate("/registracziya")
                }
            })
    },[])

    useEffect(() => {
        setActiveDroppedList(0)
    }, [newGender])

    useEffect(() => {
        setNewPriorityDirections(userPriorityDirections)
    }, [userPriorityDirections])

    useEffect(() => {
        setNewName(userName)
        setNewEmail(userEmail)
        setNewPhone(userPhone)
        setNewGender(userGender === "Мужской" ? 1 : 2)
        setStartDate(new Date(userBirthDate))
        setUserIsSubscribe(userSubscribe)
    }, [userName, userEmail, userPhone, userBirthDate, userGender, userSubscribe])

    const setDroppedListId = (id) => {
        if (id === activeDroppedList) {
            setActiveDroppedList(0)
        } else {
            setActiveDroppedList(id)
        }
    }

    const closePassModal = (status) => {
        setChangePassModalIsOpen(false)
    }

    const onChangePhoto = (e) => {
        toggleEditMode(true)
        let image_as_base64 = URL.createObjectURL(e.target.files[0])
        let image_as_file = e.target.files[0];
        setNewPhotoPreview(image_as_base64)
        setNewPhoto(image_as_file)
    }

    const goToggleSubscribe = () => {
        onSubscribe(userEmail)
    }


    const onSave = async () => {
        toggleEditMode(false)
        setNewPhotoPreview(undefined)
        let birthDate = startDate.toLocaleString().split(",")[0].split(".").join("/")
        onEdit(newName || userName,
            newPhoto || undefined,
            newEmail || userEmail,
            newPhone || userPhone,
            newGender,
            birthDate || userBirthDate.split("-").join("/"),
            newPriorityDirections
        )
        return true
    }

    const save = () => {
        onSave()
            .then(() => {
                setNewName(userName)
                setNewPhoto(userPhoto)
                setNewEmail(userEmail)
                setNewPhone(userPhone)
                setNewGender(userGender === "Мужской" ? 1 : 2)
                setStartDate(new Date(userBirthDate))
            })
    }

    const onSelectDirections = (id) => {
        toggleEditMode(true)
        let newSelDirs = [...newPriorityDirections]
        if (newSelDirs.find((i) => i === id) || newSelDirs.find((i) => i === id) === 0) {
            setNewPriorityDirections(newSelDirs.filter((i) => i !== id))
        } else {
            newSelDirs.push(id)
            setNewPriorityDirections(newSelDirs)
        }
    }

    const genderList = [{id: 2, name: "Женский"}, {id: 1, name: "Мужской"}]

    return (

        <div onClick={() => setActiveDroppedList(0)} className={s.wrapper}>
            <div className={s.infoBox}>
                <p className={s.errorText}>{editProfileError?.[0]}</p>
                <div className={s.nameAndImgBox}>
                    <div className={s.imgBox}>
                        {!newPhotoPreview ?
                            <img className={s.userImg} src={userPhoto ? userPhoto : defaultImg} alt="userPhoto"
                                 style={{}}/> : <img src={newPhotoPreview} className={s.userImg} style={{}} alt=""/>}
                        {!isMobile ? <div className={s.editImgBar} style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            height: "100%",
                            width: "100%",
                            zIndex: "10",
                        }}>
                            <input hidden={true} onChange={onChangePhoto} name="photo" id="photo"
                                   type="file"/>
                            <label htmlFor="photo">Изменить</label>
                            {/*{userPhoto ? <button onClick={() => onDeletePhoto()}>Удалить</button> : null}*/}
                        </div> : null}
                    </div>
                    {!editMode ? <p className={s.name}>{userName}</p> :
                        <div className={s.name}><Input startText={userName} callBackChangeText={setNewName}
                                                       inputType="name" placeholderText="ФИО" margin="0"/></div>}
                    {!isMobile ? <div className={s.editBar}>
                        {editMode ? <Button onClick={save} variant={1} btnText="Сохранить"/> :
                            <p onClick={() => toggleEditMode(true)} className={s.editBtn}>Редактировать</p>}
                    </div> : null}
                </div>
                {isMobile ? <div className={s.editBar}>
                    {editMode ?
                        <div className={s.editPhotoBar}>
                            <div className={s.editImgBar}>
                                <input hidden={true} onChange={onChangePhoto} name="photo" id="photo"
                                       type="file"/>
                                <label htmlFor="photo">Изменить фото</label>
                            </div>
                            <Button onClick={save} variant={1} btnText="Сохранить"/></div> :
                        <p onClick={() => toggleEditMode(true)} className={s.editBtn}>Редактировать</p>}
                </div> : null}
                <hr color="#EDEDED" size={1}/>
                <div className={s.userDataBox}>
                    {!editMode ? <div className={s.infoRow}><p className={s.rowName}><img src={EM} alt=""/> Email:</p><p
                            className={s.rowData}>{userEmail}</p></div> :
                        <Input startText={userEmail} callBackChangeText={setNewEmail} inputType="email"
                               placeholderText="Email" margin="0"/>}
                    <hr color="#EDEDED" size={1}/>
                    {!editMode ?
                        <div className={s.infoRow}><p className={s.rowName}><img src={PH} alt=""/> Телефон:</p><p
                            className={s.rowData}>{userPhone}</p></div> :
                        <Input startText={userPhone} callBackChangeText={setNewPhone} inputType="phone"
                               placeholderText="Телефон" margin="0"/>}
                    <hr color="#EDEDED" size={1}/>
                    {!editMode ? <div className={s.infoRow}><p className={s.rowName}><img src={PS} alt=""/> Пол:</p><p
                            className={s.rowData}>{userGender}</p></div> :
                        <div className={s.selectGenderBar}><img src={PS}
                                                                alt=""/><DropList list={genderList}
                                                                                  onSelect={setNewGender}
                                                                                  listName="Выберите пол:"
                                                                                  id={1}
                                                                                  activeId={activeDroppedList}
                                                                                  setActiveId={setDroppedListId}/>
                        </div>}
                    <hr color="#EDEDED" size={1}/>
                    {!editMode ?
                        <div className={s.infoRow}><p className={s.rowName}><img src={CD} alt=""/> Дата рождения:</p><p
                            className={s.rowData}>{userBirthDate}</p></div> :
                        <div className={s.infoRow}><img src={CD}
                                                                                  alt=""/>
                            <CustomDatePicker value={startDate} onChange={(date) => setStartDate(date)}/>
                        </div>}
                    <hr color="#EDEDED" size={1}/>
                    <div className={s.infoRow}><p className={s.rowName}><img src={EMG} alt=""/> Подписка:</p><p
                        className={s.rowData}>{userIsSubscribe ? "Активна" : "Не активна"}</p></div>
                    <hr color="#EDEDED" size={1}/>
                    <div><p style={{marginBottom: "24px"}} className={s.rowName}><img src={ST} alt=""/> Направления,
                        которые мне интересны:</p>
                        <ChoiceBar onChange={onSelectDirections} isManyChoice={true} items={directions}
                                   selectedItemsIds={userPriorityDirections}/>
                    </div>
                    <hr color="#EDEDED" size={1}/>
                </div>
                <div className={s.btnBox}>
                    <Button margin="0 20px 0 0" variant={4}
                            btnText={!userSubscribe ? "Подписаться на рассылку" : "Отказаться от рассылки"}
                            onClick={goToggleSubscribe}/> <Button
                    onClick={() => setChangePassModalIsOpen(true)} variant={6}
                    btnText="Сменить пароль"/>
                </div>
            </div>
            {changePassModalIsOpen ? <ChangePasswordBarContainer onClose={closePassModal}/> : null}
        </div>
    )
}