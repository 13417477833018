import React from "react";
import s from "./Heading.module.css"

export const Heading = ({headText, text, width, headWidth, textWidth, color, margin, centerMode, padding, headFontSize, textFontSize}) => {
    return (
        <div style={{width: `${width}`, color: `${color}`, margin: `${margin}`, textAlign:`${centerMode ? "center": "start"}`, padding: padding}} className={s.wrapper}>
            <h1 style={{width: `${headWidth}`, fontSize:`${headFontSize}px`, lineHeight:`${headFontSize? headFontSize + 4: null}px`}}>{headText}</h1>
            <p style={{width: `${textWidth}`, fontSize:`${textFontSize}px`, lineHeight:`${textFontSize + 4}px`}}>{text}</p>
        </div>
    )
}